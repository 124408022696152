import styled from "styled-components";
import { Link } from "react-router-dom";


export const RowDiv = styled.div`
      display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: left;
        margin-top: 8px;

        @media screen and (max-width: 768px) {
                flex-direction: column;
        }

`;

export const ColumnDiv1 = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 600px;

        h1{
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            color: #323B43;
            
        }
       
`;

export const ColumnDiv2 = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

       
`;

export const Message = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: left;
        margin-top: 2rem;

`;

export const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0rem;
        margin-left: 2rem;
        margin-right: 1rem;
        
`;
export const Wrapper2 = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;

`;

export const TitleWrapper = styled.div`
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        /* margin-right: 6rem; */



        p{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #323B43;
        margin-bottom: 20px;
        }

        img{
        width: 496.42px;
        height: 375px;
        border-radius: 4.02504px;
        }

        h3{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #323B43;
        text-align: left;
        width: 493px;
        margin-bottom: 1rem;
        

        }

        span{
        width: 493px;
        height: 54px;
        font-style: normal;
        font-weight: 900;
        font-size: 15px;
        text-align: center;
        color: #999999;
        text-align: left;

        }
`;

export const Form = styled.form`
        width: 384px;
        height: 870px;
        background: #FAFAFA;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        

        button{
        width: 324.53px;
        height: 47.45px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        margin-top: 2rem;
        font-style: normal;
        font-weight: 600;
        font-size: 15.2521px;
        color: #323B43;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;


        img{
            width: 40px;
          object-fit: contain;
          height: 25px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
        }

        }

        h2{
        font-style: normal;
        font-weight: 600;
        font-size: 16.9468px;
        color: #323B43;
        margin-right: 14.4rem;
        }

        input{
        width: 324.53px;
        height: 47.45px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        margin-top: 2rem;
        font-style: normal;
        color: #323B43;
        padding-left: 1rem;
        font-weight: 700;
        font-size: 13.5574px;
        }

        textarea{
        width: 324.53px;
        height: 110px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        margin-top: 2rem;
        padding-left: 1rem;
        font-style: normal;
        font-weight: 700;
        font-size: 13.5574px;
        color: #323B43;
        padding-top: 1rem;
        }
`;

export const LinkButtton = styled(Link)`
        background: #0c54f4;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        color: #FAFAFA;
        text-decoration: none;
        padding: 20px 20px;
        /* position: absolute;
        bottom: 130px;
        right: 80px; */
        
`;


export const IconDiv = styled.div`
        width: 314px;
        height: 52px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin-top: 2rem;

`;


export const Divs = styled.div`
        width: 324.53px;
        height: 50.84px;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        font-style: normal;
        font-weight: bold;
        font-size: 13.5574px;
        color: #323B43;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

`;

export const SpanLink = styled(Link)`
        color: #0c54f4;
        margin-left: 7px;
        text-decoration: none;
        font-style: normal;
        font-weight: bold;
        font-size: 13.5574px;

`;

export const CallToActionDiv = styled.div`
        width: 325px;
        height: 20px;
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p{

        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 17px;
        color: #323B43;
        }
`;

export const SwitchLabel = styled.label`
    position: relative;
  display: inline-block;
  width: 60px;
  height: 40px;

    input {
        opacity: 0;
  width: 0;
  height: 0;
    }

    .slider {
        position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
    }

    .slider:before {
        position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
`

export const ColorDiv = styled.div`
        width: 324.53px;
        height: 50.84px;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        font-style: normal;
        font-weight: bold;
        font-size: 13.5574px;
        color: #323B43;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        padding: 1rem;

        p{
        font-style: normal;
        font-weight: 700;
        font-size: 13.5574px;
        line-height: 16px;
        color: #323B43;
        }

        div{
                width: 32px;
            height: 32px;
            background: #323B43;
            border-radius: 4px;
            margin: 0;
        }
`;

export const Button = styled.button`
        width: 101px !important;
        height: 53px !important;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        color: #FAFAFA;
        text-decoration: none;
        padding: 20px 20px;
        border: 1px solid #323B43;
        margin-left: 13rem;
`;

export const Footerdiv = styled.div`
            width: 210px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            margin: 4rem auto;

            p{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #999999;
            }

            img{
                width: 14.76px;
                height: 20.54px;
            }

            span{
                width: 51px;
                height: 19px;
                font-style: normal;
                font-weight: 900;
                font-size: 12.4008px;
                color: #323B43;
            }

`;

export const ShareVideoDiv = styled.div`
        width: 100%;
        height: auto;
        border-radius: 5px;
        margin-top: 1rem;
        margin-left: 0rem;
        zoom: 0.85;
`;
export const ShareVideoDiv2 = styled.div`
        width: 100%;
        height: auto;
        border-radius: 5px;
        margin-top: 1rem;
        margin-left: 0rem;
`;

export const TopDiv = styled.div`
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        div{
                margin-left: 2rem;
              
                }

        button{
                width: 38px;
                height: 37.86px;
                background:red;
                margin-right: 2rem;
                border-radius: 5px;
                border: none;
                background: #f4f4f4;
                cursor: pointer;
        }

`;

export const ModalLink = styled(Link)`
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #323B43;
                margin-right: 1rem;
                cursor: pointer;
                text-decoration: none;

                :hover{
                        border-bottom: 2px solid #0c54f4;
                        padding-bottom: 10px;
                        transition: 0.5s;
                }
`;

export const ShareDiv = styled.div`
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-top: -1rem;

      
`;

export const DivLeft = styled.div`
        width: 557px;
        height: auto;
        background: #F4F4F4;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        padding: 20px;

        `;
export const DivRight = styled.div`
        width: 100%;
        height: auto;
        background: #F4F4F4;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
`;

export const ShareLinkDiv = styled.div`
        /* margin-top: 2rem; */
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        p{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #323B43;
        margin-bottom: 0.4rem;
        }

        span{
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: #999999;

        }
        `;

export const ImageDiv = styled.div`
        background: #E6E6E6;
        border-radius: 6px;
        width: 243px;
        height: 177px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        img{
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
        }
        `;

export const CopyTextDiv = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 1rem;
        margin-left: 2rem;

        input{
        width: 179px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: none;
        outline: none;
        padding: 0px 1rem;
        margin-right: 0.8rem;
        }

        button{
        width: 60px;
        height: 40px;
        background: #0c54f4;
        border-radius: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        color: #FAFAFA;
        border: none;
        cursor: pointer;
        outline: none;

        :hover{
                background: #2B6AB4;
                transition: 0.5s;
        }
        }
        `;


export const TextDiv = styled.div`
        /* margin-top: 2rem; */
        display: flex;
        flex-direction: column;
        margin-left: 0rem;


        p{
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #323B43;
            margin-bottom: 0.5rem;
        }

        span{
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            color: #999999;
        }
`;

export const ButtonDiv = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 1rem;


`;


export const Button1 = styled.button`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px;
        width: 100%;
        height: 40px;
        background: #0c54f4;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        outline: none;
        margin-bottom: 0.5rem;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        color: #ffffff;

`;
export const ButtonLink = styled.a`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px;
        width: 100%;
        height: 40px;
        background: #0c54f4;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        outline: none;
        margin-bottom: 0.5rem;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        color: #ffffff;

`;


export const Button2 = styled.button`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px;
        width: 100%;
        height: 40px;
        background: #0c54f4;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 8px;
        text-align: center;
        color: #FFFFFF;

`;


export const EmbedDiv = styled.div`
        width: 577px;
        height: 184px;
        background: #FAFAFA;
        border-radius: 6px;
        margin-bottom: 1rem;
        margin-left: 13px;

        div{
                padding: 1rem;
                
          p{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #323B43;
        margin-bottom: 0.5rem;


          }

          span{
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        color: #999999;
        line-height: 21px;
          }

        }

        div{
                button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px;
        width: 537px;
        height: 40px;
        background: #0c54f4;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        color: #ffffff;

        :hover{
                background: #6FAAEF;
                transition: 0.5s;
                color: #FFFFFF;
        }       

                }
        }
`;

export const Spans  = styled(Link)`
        color: #0c54f4;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        text-decoration: none;

`;


export const ThumbnailModal = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        width: 394px;
        height: 509px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        

        textarea{
        width: 362px;
        height: 119px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 1rem;
        outline: none;
        resize: none;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 21px;
        color: #323B43;
        }

        button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px;
        width: 362px;
        height: 40px;
        background: #0c54f4;
        border-radius: 5px;
        margin: 15px 0px; 
        border: none;
        cursor: pointer;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 8px;
        text-align: center;
        color: #FFFFFF;

        :hover{
                background: #2B6AB4;
                transition: 0.9s;
        }
        }       

`;

export const RangeDiv = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        /* width: 100%; */

        input{
        width: 100%;
        height: 40px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 1rem;
        outline: none;
        resize: none;
        cursor: pointer;
        }

`;
export const Screen = styled.div`
        width: 362px;
        height: 159px;
        border-radius: 4.02504px;
        flex: none;
        flex-grow: 0;
        margin: 15px 0px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img{

        }
`;

export const LabeDiv = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;

        label{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        color: #323B43;
        }

        div{
        width: 20px;
        height: 20px;
        background: #850088;
        border: none;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
        }

        
`;