export const CREATE_TEAM_REQUEST = "CREATE_TEAM_REQUEST";
export const CREATE_TEAM_SUCCESS = "CREATE_TEAM_SUCCESS";
export const CREATE_TEAM_FAIL = "CREATE_TEAM_FAIL";

export const GET_TEAM_REQUEST = "GET_TEAM_REQUEST";
export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAIL = "GET_TEAM_FAIL";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_FAIL = "DELETE_TEAM_FAIL";

export const EDIT_TEAM_REQUEST = "EDIT_TEAM_REQUEST";
export const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCCESS";
export const EDIT_TEAM_FAIL = "EDIT_TEAM_FAIL";