export const titleCase = (str) => {
  if(!str){
    return str
  }
  return (str + '').replace(/^(.)|\s+(.)/g, ($1) => {
    return $1.toUpperCase()
  })
};

export const getInitials = (str) => {
  let initials = "";
  let strArray = str.split(" ");
  strArray.forEach((str) => {
      initials += str[0];
  });
  return initials;
}

export const formatDate = (date) => {
  let dateObj = new Date(date);
  let month = dateObj.getUTCMonth() + 1; //months from 1-12
  let day = dateObj.getUTCDate();
  let year = dateObj.getUTCFullYear();

  return day + "/" + month + "/" + year;
}

export const createDateFromMysql = function(mysql_date){
  var t = mysql_date.toString().split("T")[0].split("-");
  var date = t[2]+"/"+t[1]+"/"+t[0]
  var d = new Date(mysql_date)
  d = d.toLocaleString('en-US', {timeZone: "America/New_York", hour12:true}).toLowerCase().replace(/[pam]/g, "").split(", ")
  var time = d.pop();
  // var date = d[0];
  // We then parse  the mdy into parts
  var dateArray = date.split('/');
  var month = parseInt(dateArray[0]);
  var day = parseInt(dateArray[1]);
  var year = parseInt(dateArray[2]);
  // Putting it all together
  var formattedDate = day+'/'+month+'/'+year + ' ' + time.split(":")[0]+":"+time.split(":")[1];
  return  formattedDate
}

export const asteriskPassword = (password) => {
  let asteriskPassword = "";
  for (let i = 0; i < password.length; i++) {
      if (i < password.length - 4) {
          asteriskPassword += "*";
      } else {
          asteriskPassword += password[i];
      }
  }
  return asteriskPassword;
};

export const arraySearch = (array, keyword) => {
  const searchTerm = keyword.toLowerCase()
  return array.filter(value => {
      return value.name.toLowerCase().match(new RegExp(searchTerm, 'g'))
  })
}

export const arraySearchs = (array, keyword) => {
  const searchTerm = keyword.toLowerCase()
  return array.filter(value => {
      return value.testimony_name.toLowerCase().match(new RegExp(searchTerm, 'g'))
  })
}

  // write a function that shortens the text to a certain length
export const shortenText = (text, length) => {
    if (text.length > length) {
        return text.substring(0, length) + "...";
    } else {
        return text;
    }
}


export const uniqueId = (length, type = "alphanumeric") => {
  var result = '';
  var characters = type === "number" ? '0123456789' : type === "alphabet" ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}