import {
    CREATE_WORKSPACE_REQUEST,
    CREATE_WORKSPACE_SUCCESS,
    CREATE_WORKSPACE_FAIL,
    GET_WORKSPACE_REQUEST,
    GET_WORKSPACE_SUCCESS,
    GET_WORKSPACE_FAIL,
    EDIT_WORKSPACE_REQUEST,
    EDIT_WORKSPACE_SUCCESS,
    EDIT_WORKSPACE_FAIL,
    DELETE_WORKSPACE_REQUEST,
    DELETE_WORKSPACE_SUCCESS,
    DELETE_WORKSPACE_FAIL,
    SAVE_BRANDS_REQUEST,
    SAVE_BRANDS_SUCCESS,
    SAVE_BRANDS_FAIL,
    UPLOAD_BRAND_LOGO_REQUEST,
    UPLOAD_BRAND_LOGO_SUCCESS,
    UPLOAD_BRAND_LOGO_FAIL,
    GET_WORKSPACE_BY_ID_REQUEST,
    GET_WORKSPACE_BY_ID_SUCCESS,
    GET_WORKSPACE_BY_ID_FAIL,
    WORKSPACE_RESET,
  } from "./WorkSpaceType";
  import axios from "axios";
  import { BACKEND_BASE_URL } from "../backendUrl";
  
  
export const createWorkspace = (name, token, category) => async (dispatch, getState) => {
      try {
          dispatch({
          type: CREATE_WORKSPACE_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };

          const { data } = await axios.post(
            `${BACKEND_BASE_URL}workspace/create_workspace`,
            { name, token, category },
            config
          );

          dispatch({
                type: CREATE_WORKSPACE_SUCCESS,
                payload: data,
                });
      
      } catch (error) {
          dispatch({
          type: CREATE_WORKSPACE_FAIL,
          payload:
              error.response && error.response.data.message
          });
      }
      }
  
export const getWorkspace = () => async (dispatch, getState) => {
      try {
          dispatch({
            type: GET_WORKSPACE_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
         
          const { data } = await axios.get(
            `${BACKEND_BASE_URL}workspace/get_workspaces?token=${userData.data.token}&is_team_member=${userData.data.is_team_member}&team_id=${userData.data.team_id}`, { config },
            config
          );
        
          dispatch({
            type: GET_WORKSPACE_SUCCESS,
            payload: data,
          });
          
      
      } catch (error) {
          dispatch({
          type: GET_WORKSPACE_FAIL,
          payload:
              error.response && error.response.data.message
          });
      }
      }
  
export const editWorkspace = (workspace) => async (dispatch, getState) => {
      try {
          dispatch({
          type: EDIT_WORKSPACE_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
          const { data } = await axios.put(
          `${BACKEND_BASE_URL}workspace/edit_workspace`, workspace,
          config
          );

        //   const { data } = await axios.put(`${BACKEND_BASE_URL}workspace/edit_workspace`, 
        //     {
        //     data: { name, token,  workspace_id },
        //     config
        //   }
        // );
      
          dispatch({
          type: EDIT_WORKSPACE_SUCCESS,
          payload: data,
          });
      
      } catch (error) {
          dispatch({
          type: EDIT_WORKSPACE_FAIL,
          payload:
              error.response && error.response.data.message
          });
          console.log(error.response.data.message, "error");
      }
      }
  
export const deleteWorkspace = (token, workspace_id) => async (dispatch, getState) => {
      try {
          dispatch({
          type: DELETE_WORKSPACE_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
          
            const { data } = await axios.delete(`${BACKEND_BASE_URL}workspace/delete_workspace`, 
            {
              data: { token, workspace_id },
              config
            }
          );

          dispatch({
          type: DELETE_WORKSPACE_SUCCESS,
          payload: data,
          });

          dispatch({
            type: WORKSPACE_RESET,
          })
      
      } catch (error) {
          dispatch({
          type: DELETE_WORKSPACE_FAIL,
          payload:
              error.response && error.response.data.message
          });
      }
      }

export const saveBrands = (workspace) => async (dispatch, getState, loadState  ) => {
      try {
          dispatch({
          type: SAVE_BRANDS_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
         
          const { data } = await axios.put(
          `${BACKEND_BASE_URL}workspace/save_brand_styles`, workspace, config );
           //write a fuction to reset the workSpaceInfo in the local storage
           localStorage.setItem("workSpaceInfo", JSON.stringify(data));
          dispatch({
          type: SAVE_BRANDS_SUCCESS,
          payload: data,
          });
      
      } catch (error) {
          dispatch({
          type: SAVE_BRANDS_FAIL,
          payload:
              error.response && error.response.data.message
          });
          console.log(error);
      }
      }

export const uploadBrandLogo = (token, workspace_id, file_buffer) => async (dispatch, getState) => {
      try {
          dispatch({
          type: UPLOAD_BRAND_LOGO_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
         
          const { data } = await axios.post(
          `${BACKEND_BASE_URL}workspace/upload_brand_logo`, { token, workspace_id, file_buffer },
          config
          );
      
          dispatch({
          type: UPLOAD_BRAND_LOGO_SUCCESS,
          payload: data,
          });
      
      } catch (error) {
          dispatch({
          type: UPLOAD_BRAND_LOGO_FAIL,
          payload:
              error.response && error.response.data.message
          });
      }
      }

export const getWorkspaceById = (token, workspace_id) => async (dispatch, getState) => {
      try {
          dispatch({
          type: GET_WORKSPACE_BY_ID_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
         
          
          const { data } = await axios.get(
          `${BACKEND_BASE_URL}workspace/get_workspace_by_id?workspace_id=${workspace_id}&token=${token}`, { config },
          config
          );
         
          dispatch({
          type: GET_WORKSPACE_BY_ID_SUCCESS,
          payload: data,
          });
          
          localStorage.setItem("workSpaceInfo", JSON.stringify(data.data.workspaces));

      } catch (error) {
          dispatch({
          type: GET_WORKSPACE_BY_ID_FAIL,
          payload:
              error.response && error.response.data.message
          });
      }
      }