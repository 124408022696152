import {
    CREATE_MESSAGE_REQUEST,
    CREATE_MESSAGE_SUCCESS,
    CREATE_MESSAGE_FAIL,
    GET_MESSAGE_REQUEST,
    GET_MESSAGE_SUCCESS,
    GET_MESSAGE_FAIL,
    CREATE_NOTIFICATION_REQUEST,
    CREATE_NOTIFICATION_SUCCESS,
    CREATE_NOTIFICATION_FAIL,
    GET_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_SUCCESS,
    GET_NOTIFICATION_FAIL,
} from "./MessageType";

export const getMessageReducer = (state = { messageData: {} }, action) => {
    switch (action.type) {
      case GET_MESSAGE_REQUEST:
        return { ...state, loading: true };
      case GET_MESSAGE_SUCCESS:
        return { loading: false, messageData: action.payload, message: action.payload.message, success: true };
      case GET_MESSAGE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };


export const createMessageReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_MESSAGE_REQUEST:
        return { loading: true };
      case CREATE_MESSAGE_SUCCESS:
        return { loading: false, createMessageData: action.payload, success: true, message: action.payload.message };
      case CREATE_MESSAGE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const createNotificationReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_NOTIFICATION_REQUEST:
        return { loading: true };
      case CREATE_NOTIFICATION_SUCCESS:
        return { loading: false, createNotificationData: action.payload, success: true, message: action.payload.message };
      case CREATE_NOTIFICATION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const getNotificationReducer = (state = { notificationData: {} }, action) => {
    switch (action.type) {
      case GET_NOTIFICATION_REQUEST:
        return { ...state, loading: true };
      case GET_NOTIFICATION_SUCCESS:
        return { loading: false, notificationData: action.payload, message: action.payload.message, success: true };
      case GET_NOTIFICATION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };