import React, { useRef } from 'react'
import FooterLanding from './FooterLanding'
import HeaderLanding from './HeaderLanding'

const Contact = () => {
    const inputFile = useRef(null)

    const open_file = (e) => {
        e.stopPropagation()
        inputFile.current.click();
    }

    return (
        <>
            <div className="main_landing_page_div">
                <div className="landing_page_top">
                    <HeaderLanding />

                    <div className='contact_div'>
                        <div className='contact_div_top'>
                            <h3>Contact our representatives</h3>
                            <p>We’re always here to help. Send Us a message!</p>
                        </div>
                        <div className='contact_div_body'>
                            <div className='contact_div_form'>
                                <div className='form_group'>
                                    <label>Full name</label> <br />
                                    <input type="text" className='form_input' />
                                </div>
                                <div className='form_group'>
                                    <label>Email</label> <br />
                                    <input type="text" className='form_input' />
                                </div>
                                <div className='form_group'>
                                    <label>Subject</label> <br />
                                    <input type="text" className='form_input' />
                                </div>
                                <div className='form_group'>
                                    <label>Description</label> <br />
                                    <textarea></textarea>
                                </div>
                                <div className='form_group'>
                                    <label>Attachements</label> <br />
                                    <input type="file" className="custom-file-input" ref={inputFile} />
                                    <div className='custom-file-btn' onClick={open_file}>Add file or drop files here</div>
                                </div>
                                <div className='form_group'>
                                    <button>Send message</button>
                                </div>
                            </div>
                        </div>
                        <div className='contact_div_info'>
                            <div className='contact_div_info_item'>
                                <p>Sales representative</p>
                                <h5>+234811234567</h5>
                            </div>
                            <div className='contact_div_info_item'>
                                <p>Support Email</p>
                                <h5>blucodetech.gmail.com</h5>
                                <h5>support@testimonio.io</h5>
                            </div>
                            <div className='contact_div_info_item'>
                                <p>Location</p>
                                <h5>10, Gbagada Street,</h5>
                                <h5>Lagos State</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterLanding />
            </div>

        </>
    )
}

export default Contact