import styled from "styled-components";
import { Link } from "react-router-dom";


export const Div = styled.div`
         /* height: 100vh; */
         display: flex;
         justify-content: center;
          align-items: center;

`;
export const MainContainer = styled.div`
          height: auto;

`;
export const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 480px;
        background-color: ${({ theme }) => theme.colors.white};
        margin: 0rem auto;
        border:1px solid #f1f1f1;
        border-radius:5px;
        padding:30px;

        @media (max-width: ${({ theme }) => theme.mobile}) {
          width: 100%;
          margin: 0 auto;
          border: none;
        }
`;

export const Contain = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 85%;
        margin-bottom: 0;
    }
`


export const Divs = styled.div`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
  }
`;


export const Border = styled.div`
  height: 455px;
    width: 480px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 auto;

    @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    height: 100%;
  }

    
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 0rem;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #999999;
    margin-bottom: 0rem;
    margin-top: 1rem;

    p{
      color: black;
      text-align: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 85%;
  }
`;

export const LoginTitle = styled.h1`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 0px;
  text-align: center;
  display: flex;
    align-items: center;
    justify-content: center;
  color: ${({ theme }) => theme.colors.dark};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 10%;
    margin-bottom: -0.5rem;
    font-size: 14px;
  }
`;

export const LoginSubtitle = styled.p`
width: 300px;
height: 48px;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: -0.3px;
color: #323B43;


`;







export const GoogleIcon = styled.img`
  width: 70%;
  object-fit: contain;
  height: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
//   margin-right: 1rem;
  outline: none;
//   margin-top: 1.3rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 25px;
    height: 25px;
    margin-top: 1.4rem;
    margin-left: 1.4rem;
  }
`;





export const ForgotPassword = styled.a`
  width: 116px;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #aa2080;
  cursor: pointer;
  text-decoration: none;
`;

export const LoginButton = styled.button`
  width: 50%;
  height: 50px;
  background: #0c54f4;
  border-radius: 12px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 600px;
  cursor: pointer;
  &:hover {
    background: #0b43bf;
    transition: 0.5s;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 40px;
    font-size: 14px;
    width: 100%;
    width: 50%;
  }
`;

export const LoginButtonText = styled.p`
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fafafa;
`;

export const Text = styled.p`
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 1.5rem;
  color: #323b43;
`;

export const Button = styled(Link)`
          width: 100%;
          height: 50px;
          background: #0c54f4;
          border-radius: 5px;
          border: none;
          color: ${({ theme }) => theme.colors.white};
          font-size: 18px;
          font-weight: 600px;
          cursor: pointer;
          margin-top: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          
          &:hover {
            background: #0b43bf;
            transition: 0.5s;
          }

          @media (max-width: ${({ theme }) => theme.mobile}) {
            height: 40px;
            font-size: 14px;
            width: 100%;
            width: 85%;
          }
`;

export const LinkText = styled(Link)`
  color: #aa2080;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;

`
export const Bottom = styled.div`
  display: flex;
  position: absolute;
  right: 70px;
  bottom: 50px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: -4rem;
    padding-right: 0;
    justify-content: center;
    position: inherit;
  }
`;

export const Select = styled.select`
  width: 106px;
  height: 45px;
  border: none;
  color: ${({ theme }) => theme.colors.dark};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0rem;
  padding: 10px;
  background-color: none;
  background: none;
`;

export const SelectText = styled.p`
  width: 28px;
  height: 17px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  color: #999999;
  margin-top: 15px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-right: 2.5rem;
    margin-top: 15px;
  }
`;

export const Button1 = styled.button`
      width: 384px;
            height: 53px;
            background: #0c54f4;
            border-radius: 8px;
            border: none;
            color: #FFFFFF;
            outline: none;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            letter-spacing: -0.5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            margin-bottom: 20px;


            :hover{
                background: #1C72D6;
                transition: 0.9s;
            }

            @media (max-width: ${({ theme }) => theme.mobile}) {
                width: 100% !important;
            }
`;

export const UploadFileButton = styled.div`
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 384px;
        height: 53px;
        background: none;
        border-radius: 8px;
        border: none;
        color: #FFFFFF;
        outline: none;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        text-align: center;
        letter-spacing: -0.5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border: 1px solid #0c54f4;
        color: #0c54f4;

        :hover{
            background: #1C72D6;
            transition: 0.9s;
        }

        :hover > button{
            color: #ffffff
        }

        button {
                border: 0px solid #E6E6E6;
                border-radius: 5px;
                cursor: pointer;
                padding: 8px 20px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                color: #0c54f4;
                margin: 0px;
                background: transparent;
                margin-bottom: 0rem;
                cursor: pointer;

                @media (max-width: ${({ theme }) => theme.mobile}) {
                  width: 100% !important;
                }
        }

        input {
                font-size: 100px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                margin: 0px;
                margin-bottom: 0rem;
        }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: initial;

        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (max-width: ${({ theme }) => theme.mobile}) {
                width: 100% !important;
            }
        }
    }

    img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        /* object-fit: cover; */
    }

    p{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #323B43;
    margin-bottom: 20px;
    margin-top: 20px;
    }

    span{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #999999;
    margin-bottom: 20px;

    }

    form input{
        width: 384px;
        height: 51px;
        margin-bottom: 20px;
        background: #FAFAFA;
        font-size: 16px;
        border: 1px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5px;
        padding-left: 20px;

        &:focus{
            outline: none;
            border: 1px solid #2196F3;
        }
    }

    form button{
        width: 384px;
        height: 53px;
        background: #0c54f4;
        border-radius: 8px;
        border: none;
        color: #FFFFFF;
        outline: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;


        :hover{
            background: #1C72D6;
            transition: 0.9s;
        }
    }

    button img{
        width: 20px;
        height: 20px;
    }
`;

export const Circle = styled.div`
    width: 120px;
    height: 120px;
    background: #0c54f4;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 67px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
`;