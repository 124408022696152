import React from "react";
import "./index.css";
import IntegrationDoneBody from "../components/IntegrationDoneBody";



const IntegrationDone = () => {
  return (
    <div className="main-layout">
          <div className="main-layout__content" style={{maxWidth: "100%", minHeight: "100%", padding: "0px", border: "none"}}>
            <IntegrationDoneBody />
          </div>
    </div>
  );
};

export default IntegrationDone;