import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./index.css"

import * as FaIcons from 'react-icons/fa';
import { IconContext } from 'react-icons';

const HeaderLanding = () => {
  const [navbar, setNavbar] = useState(false);

  const toggleNavBar = (e) => {
    e.stopPropagation()

    setNavbar(!navbar)
  }

  return (
    <>

      <div className='header_div'>
        <div className='main_header_div'>
          <div className='main_leader_left'>
            <Link to="/">
              <img src="/images/A.png" alt="" />
            </Link>
          </div>
          <div className='main_header_right'>
            <div className='nav_icon' onClick={toggleNavBar}>
              <IconContext.Provider value={{ color: '#3C42D7' }} />
              {navbar ? (<FaIcons.FaTimes />) : (<FaIcons.FaBars />)}

            </div>
            <div className='head_nav_text'>
              <div className='head_nav_items'><Link to='features'>Features</Link></div>
              <div className='head_nav_items'><Link to='/pricing'>Pricing</Link></div>
              <div className='head_nav_items'><Link to='/contact'>Contact us</Link></div>
              <div className='head_nav_items'><Link to='/login'>Sign In</Link></div>
            </div>
            {/* <div className='head_nav_btn'>
              <Link to='/signup'>
                <button>Get Started for Free</button>
              </Link>
            </div> */}
          </div>
        </div>

        {navbar && (
          <div className='navbar_toggle'>
            <div className='nav_item_mobile'>
              <Link to="/landing">Home</Link>
            </div>
            <div className='nav_item_mobile'>
              <Link to="/features">Features</Link>
            </div>
            <div className='nav_item_mobile'>
              <Link to="/contact">Contact us</Link>
            </div>
            <div className='nav_item_mobile'>
              <Link to="login">Sign In</Link>
            </div>
            <div className='nav_item_mobile'>
              <div className='head_nav_btn_mobile'>
                <Link to='/signup'>
                  <button>Get Started for Free</button>
                </Link>
              </div>
            </div>
          </div>
        )
        }
      </div>
    </>
  )
}

export default HeaderLanding