import React from "react";
import {
  MainContainer,
  Div,
  Container,
  Title,
  TextWrapper,
  LoginForm,
  Icon,
  LoginInput,
  Button,
  // SmallText,
  FirstFormDiv,
  InputDiv,
  IconDiv,
  SmallTextDiv,
  // LinkText,
} from "./CreateNewPasswordStyle";

const CreateNewPassword = () => {
  return (
    <>
<Div>
  <div>
  <MainContainer>


  <Container>
        <TextWrapper>
          <Title>Create new password</Title>
        </TextWrapper>

        <LoginForm>
          <FirstFormDiv>
            <IconDiv>
              <Icon src="/images/lock.svg" />
            </IconDiv>

            <InputDiv>
              <LoginInput
                placeholder="Enter new password"
                name="resetCode"
                id="resetCode"
              />
            </InputDiv>
          </FirstFormDiv>

          <FirstFormDiv>
            <IconDiv>
              <Icon src="/images/lock.svg" />
            </IconDiv>

            <InputDiv>
              <LoginInput
                placeholder="Confirm new password"
                name="resetCode"
                id="resetCode"
              />
            </InputDiv>
          </FirstFormDiv>

          

          <Button>Complete reset</Button>
        </LoginForm>
      </Container>

      <SmallTextDiv>
        {/* <SmallText>
          Don’t have an account? <LinkText to="/signup">Sign up</LinkText>
        </SmallText> */}
      </SmallTextDiv>
  </MainContainer>
  </div>
</Div>


   

      {/* <BottomSelect>
        <BottomText>Help</BottomText>
        <Select name="help" id="help" className="help">
          <option>English</option>
          <option>French</option>
          <option>German</option>
          <option>Spanish</option>
        </Select>
      </BottomSelect> */}
    </>
  );
};

export default CreateNewPassword;
