import React from 'react'
import "./index.css"
import { Link } from 'react-router-dom'
import FooterLanding from './FooterLanding'
import HeaderLanding from './HeaderLanding'

const Features = () => {
    return (
        <>
            <div className="main_landing_page_div">
                <div className="landing_page_top">
                    <HeaderLanding />

                    <div className='features_div'>
                        <div className='features_div_top'>
                            <h3>Everything you need to create sleeky and catchy videos</h3>
                        </div>
                        <div className='divsion_line'></div>
                        <div className='features_div_body'>
                            <div className='feature_item_div column_reverse'>
                                <div className='feature_item_left text_decor'>
                                    <h3>Create and send personalised video emails</h3>
                                    <p>Send personalized video emails to customers and leads for a healthier relationship, and instill a sense of brand loyalty in them. </p>
                                    <div className="get_started_btn">
                                        <Link to='/signup'>
                                            <button>Get Started for Free</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className='feature_item_right'>
                                    <div className='image_gif'>
                                        <img src='/images/Dashboard.gif' alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className='feature_item_div'>
                                <div className='feature_item_left'>
                                    <div className='image_gif'>
                                        <img src='/images/Team member.gif' alt='' />
                                    </div>
                                </div>
                                <div className='feature_item_right text_decor'>
                                    <h3>Invite Teammates</h3>
                                    <p>Bring your team members to your workspace or account to allow them to request feedbacks, create videos, and much more.</p>
                                    <div className="get_started_btn">
                                        <Link to='/signup'>
                                            <button>Get Started for Free</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='feature_item_div column_reverse'>
                                <div className='feature_item_left text_decor'>
                                    <h3>Make use of ready-made templates</h3>
                                    <p>Use awesome videos templates pre-recorded by business owners like you, or designed videos by experienced designers and video producers.</p>
                                    <div className="get_started_btn">
                                        <Link to='/signup'>
                                            <button>Get Started for Free</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className='feature_item_right'>
                                    <div className='image_gif'>
                                        <img src='/images/templates.gif' alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className='feature_item_div'>
                                <div className='feature_item_left'>
                                    <div className='image_gif'>
                                        <img src='/images/Feedback.gif' alt='' />
                                    </div>
                                </div>
                                <div className='feature_item_right text_decor'>
                                    <h3>Feedbacks</h3>
                                    <p>Get video, audio or text feedbacks from your loyal customers and use them in your marketing campaigns as testimonials. </p>
                                    <div className="get_started_btn">
                                        <Link to='/signup'>
                                            <button>Get Started for Free</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='getStartedBox'>
                    <div className='getStartedFlex'>
                        <div className='getStartedBoxLeft'>
                            <p>Start connecting and engaging with your customers. It’s unlimited</p>

                            <div className="get_started_btn">
                                <Link to='/signup'>
                                    <button>Get Started for Free</button>
                                </Link>
                            </div>
                        </div>
                        <div className='getStartedBoxRight'>
                            <img src='/images/thumb.svg' alt='' />
                        </div>
                    </div>
                </div>

                <FooterLanding />
            </div>
        </>
    )
}

export default Features