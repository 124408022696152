/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../../redux/auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import InfoModal from '../InfoModal';
import { getUserDetail } from "../../redux/account/AccountAction";
import "./index.css";

export default function Index() {
    const dispatch = useDispatch();
    const [userToken, setUserToken] = React.useState(null);

    const getUser = useSelector((state) => state.getUser);
    const { loading, user } = getUser;

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const [acessLevel, setAcesslevel] = React.useState([]);
    const [infoModals, setInfoModals] = React.useState(false);

    React.useEffect(() => {
        if (!userData) {
            window.location.href = "/login";
        } else {
            setUserToken(userData?.data?.token);
            dispatch(getUserDetail(userData.data.token));
        }
    }, [userData, dispatch]);


    React.useEffect(() => {
        if (user) {
            setAcesslevel(user?.data?.profile?.acls);
        }
    }, [user]);

    let url_params_array = window.location.pathname.split("/");
    let active_route = url_params_array.pop();

    const logoutHandler = () => {
        dispatch(logout());
    };

    React.useEffect(() => {
        const script = document.createElement("script");
        script.src = "//code.jivosite.com/widget/O9HhqSWcyy";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <div className="main-layout__sidebar">
            <div className="sidebar__wrapper">
                <div className="react-sanfona sidebar__list">

                    <div className={active_route.includes("dashboard") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                        <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/dashboard">
                            <div className="sidebar__item-trigger-inner">
                                <div className="sidebar__item-trigger-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-2" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="5 12 3 12 12 3 21 12 19 12" />
                                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                            <rect x="10" y="12" width="4" height="4" />
                                        </svg>
                                    </div>
                                </div>
                                Dashboard
                            </div>
                        </Link>
                    </div>

                    <div className={active_route.includes("testimonys") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                        <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/testimonys">
                            <div className="sidebar__item-trigger-inner">
                                <div className="sidebar__item-trigger-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-message-circle-2" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
                                            <line x1="12" y1="12" x2="12" y2="12.01" />
                                            <line x1="8" y1="12" x2="8" y2="12.01" />
                                            <line x1="16" y1="12" x2="16" y2="12.01" />
                                        </svg>
                                    </div>
                                </div>
                                Testimonials
                            </div>
                        </Link>
                    </div>

                    <div className={active_route.includes("videos") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                        <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/videos">
                            <div className="sidebar__item-trigger-inner">
                                <div className="sidebar__item-trigger-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                            <rect x="3" y="6" width="12" height="12" rx="2" />
                                        </svg>
                                    </div>
                                </div>
                                Videos
                            </div>
                        </Link>
                    </div>

                    <div className={active_route.includes("workspaces") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                        <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/workspaces">
                            <div className="sidebar__item-trigger-inner">
                                <div className="sidebar__item-trigger-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="3" y1="21" x2="21" y2="21" />
                                            <path d="M5 21v-14l8 -4v18" />
                                            <path d="M19 21v-10l-6 -4" />
                                            <line x1="9" y1="9" x2="9" y2="9.01" />
                                            <line x1="9" y1="12" x2="9" y2="12.01" />
                                            <line x1="9" y1="15" x2="9" y2="15.01" />
                                            <line x1="9" y1="18" x2="9" y2="18.01" />
                                        </svg>
                                    </div>
                                </div>
                                Workspaces
                            </div>
                        </Link>
                    </div>

                    {(acessLevel?.length === 1 && acessLevel?.includes('FE')) || (acessLevel?.length === 2 && acessLevel?.includes('FE', 'RE')) ? ("") 
                    : (
                        <div className={active_route.includes("dfy-gency") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"} >
                            <a className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" href="https://docs.google.com/document/d/1AeP6dLQJmUmbv26o89qRztSPxUJfZDkbP2V5lVH6ci4/edit?usp=sharing" target="_blank" rel="noopener noreferrer"

                            >
                                <div className="sidebar__item-trigger-inner">
                                    <div className="sidebar__item-trigger-icon">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layout-grid-add" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <rect x="4" y="4" width="6" height="6" rx="1" />
                                                <rect x="14" y="4" width="6" height="6" rx="1" />
                                                <rect x="4" y="14" width="6" height="6" rx="1" />
                                                <path d="M14 17h6m-3 -3v6" />
                                            </svg>
                                        </div>
                                    </div>
                                    DFY Agency
                                </div>
                            </a>
                        </div>
                    )}

                    {(acessLevel?.length === 1 && acessLevel?.includes('FE')) || (acessLevel?.length === 2 && acessLevel?.includes('FE', 'RE')) ? ("") 
                    : (
                        <div className={active_route.includes("team") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                            <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/team">
                                <div className="sidebar__item-trigger-inner">
                                    <div className="sidebar__item-trigger-icon">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="9" cy="7" r="4" />
                                                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                            </svg>
                                        </div>
                                    </div>
                                    Teammates
                                </div>
                            </Link>
                        </div>
                    )}
                    {(acessLevel?.length === 1 && acessLevel?.includes('FE')) || (acessLevel?.length === 2 && acessLevel?.includes('FE', 'RE')) ? ("") 
                    : (
                        <div className={active_route.includes("reseller") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                            <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/reseller">
                                <div className="sidebar__item-trigger-inner">
                                    <div className="sidebar__item-trigger-icon">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-users" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="9" cy="7" r="4" />
                                                <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                                                <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                                            </svg>
                                        </div>
                                    </div>
                                    Reseller
                                </div>
                            </Link>
                        </div>
                    )}

                    <div className={active_route.includes("integrations") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                        <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/integrations">
                            <div className="sidebar__item-trigger-inner">
                                <div className="sidebar__item-trigger-icon">
                                    <div>
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.1 15.6L11.5 14.15L9.35 12L11.5 9.85L10.1 8.4L6.5 12L10.1 15.6ZM14.9 15.6L18.5 12L14.9 8.4L13.5 9.85L15.65 12L13.5 14.15L14.9 15.6ZM5.5 21C4.95 21 4.479 20.8043 4.087 20.413C3.69567 20.021 3.5 19.55 3.5 19V5C3.5 4.45 3.69567 3.979 4.087 3.587C4.479 3.19567 4.95 3 5.5 3H9.7C9.91667 2.4 10.2793 1.91667 10.788 1.55C11.296 1.18333 11.8667 1 12.5 1C13.1333 1 13.7043 1.18333 14.213 1.55C14.721 1.91667 15.0833 2.4 15.3 3H19.5C20.05 3 20.521 3.19567 20.913 3.587C21.3043 3.979 21.5 4.45 21.5 5V19C21.5 19.55 21.3043 20.021 20.913 20.413C20.521 20.8043 20.05 21 19.5 21H5.5ZM12.5 4.25C12.7167 4.25 12.896 4.179 13.038 4.037C13.1793 3.89567 13.25 3.71667 13.25 3.5C13.25 3.28333 13.1793 3.104 13.038 2.962C12.896 2.82067 12.7167 2.75 12.5 2.75C12.2833 2.75 12.1043 2.82067 11.963 2.962C11.821 3.104 11.75 3.28333 11.75 3.5C11.75 3.71667 11.821 3.89567 11.963 4.037C12.1043 4.179 12.2833 4.25 12.5 4.25ZM5.5 19H19.5V5H5.5V19ZM5.5 5V19V5Z" fill="#979DAA" />
                                        </svg>

                                    </div>
                                </div>
                                Integrations
                            </div>
                        </Link>
                    </div>

                    <div className={active_route.includes("profile") ? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                        <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/profile">
                            <div className="sidebar__item-trigger-inner">
                                <div className="sidebar__item-trigger-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                                            <circle cx="12" cy="12" r="3" />
                                        </svg>
                                    </div>
                                </div>
                                Settings
                            </div>
                        </Link>
                    </div>

                    <div style={{ position: "absolute", bottom: "100px" }}>
                        {/* <div className={active_route.includes("tutorial")? "active_sidebar react-sanfona-item sidebar__item" : "react-sanfona-item sidebar__item"}>
                            <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="/workspaces">
                                <div className="sidebar__item-trigger-inner">
                                    <div className="sidebar__item-trigger-icon">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18"></path><line x1="13" y1="8" x2="15" y2="8"></line><line x1="13" y1="12" x2="15" y2="12"></line>
                                            </svg>
                                        </div>
                                    </div>
                                    Tutorial
                                </div>
                            </Link>
                        </div> */}
                        <div className="react-sanfona-item sidebar__item" onClick={logoutHandler}>
                            <Link className="sidebar__item-trigger sidebar__item-trigger--fill" id="react-sanfona-item-title-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" aria-controls="react-sanfona-item-body-931bb6c8-4e52-4a91-9631-a9dc3dd0a9ee" to="#">
                                <div className="sidebar__item-trigger-inner">
                                    <div className="sidebar__item-trigger-icon">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-settings" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                                                <line x1="12" y1="4" x2="12" y2="12" />
                                            </svg>
                                        </div>
                                    </div>
                                    Logout
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </div>

            {infoModals && (
                <InfoModal onClose={() => setInfoModals(false)} />
            )}
        </div>
    );
};