import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userRegisterReducer, userLoginReducer, verifyReducer, resendCodeReducer, resetPassword, } from "./auth/AuthReducers";
import { getUserReducer, editUserReducer, changePasswordReducer, uploadPhotoReducer} from "./account/AccontReducer";
import {createWorkspace, getWorkspace, editWorkspace, deleteWorkspace, saveBrands, uploadBrandLogo, getWorkspaceById} from "./workSpace/WorkSpaceReducer";
import {getMessageReducer, createMessageReducer, createNotificationReducer, getNotificationReducer} from "./messages/MessageReducer";
import {createTeamReducer, getTeamReducer, deleteTeamReducer, editTeamReducer} from "./team/TeamReducer";
import {createFeedBackReducer, getFeedBackReducer, deleteFeedBackReducer} from "./feedBack/FeedBackReducer";
import {getVideoReducer, editVideoReducer, uploadVideoReducer} from "./video/VideoReducer";

const reducer = combineReducers({
  // user reducers
  userRegister: userRegisterReducer,
  userLogin: userLoginReducer,
  verify: verifyReducer,
  resendCode: resendCodeReducer,
  reset: resetPassword,

  // account reducers
  getUser: getUserReducer,
  edit:editUserReducer,
  changePassword: changePasswordReducer,
  uploadPhoto: uploadPhotoReducer,

  // workspace reducers
  createWork: createWorkspace,
  getWork: getWorkspace,
  editWork: editWorkspace,
  deleteWork: deleteWorkspace,
  saveBrand: saveBrands,
  uploadBrand: uploadBrandLogo,
  getWorkById: getWorkspaceById,

  // message reducers
  getMessage: getMessageReducer,
  createMessage: createMessageReducer,
  createNotification: createNotificationReducer,
  getNotification: getNotificationReducer,

  // team reducers
  createTeam: createTeamReducer,
  getTeam: getTeamReducer,
  deleteTeam: deleteTeamReducer,
  editTeam: editTeamReducer,

  // feedBack reducers
  createFeedBack: createFeedBackReducer,
  getFeedBack: getFeedBackReducer,
  deleteFeedBack: deleteFeedBackReducer,

  // video reducers
  getVideo: getVideoReducer,
  editVideo: editVideoReducer,
  uploadVideo: uploadVideoReducer,

});

const userDataFromStorage = localStorage.getItem("userData")
  ? JSON.parse(localStorage.getItem("userData"))
  : null;

const workSpaceDataFromStorage = localStorage.getItem("workSpaceInfo")
  ? JSON.parse(localStorage.getItem("workSpaceInfo"))
  : null;

const initialState = {
  userLogin: { userData: userDataFromStorage },
  getWorkById: { workSpaceInfo: workSpaceDataFromStorage },
};

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

// const middleware = [thunk];
// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

export default store;