/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./index.css";
import { getWorkspace } from "../../redux/workSpace/WorkSpaceAction";
import { getUserDetail } from "../../redux/account/AccountAction";
import { logout } from "../../redux/auth/AuthActions";
import Loader from "../loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { titleCase, getInitials } from "../../functions";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import $ from 'jquery';

import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";

export default function Index(props) {
    const dispatch = useDispatch();

    const [userToken, setUserToken] = React.useState(null);

    const getWorkById = useSelector((state) => state.getWorkById);
    const { workSpaceInfo } = getWorkById;

    const [navigationMenuVisible, setNavigationMenuVisible] = React.useState(false);
    const [workspaceMenuVisible, setWorkspaceMenuVisible] = React.useState(false);
    const [name, setName] = React.useState("");
    const [photo, setPhoto] = React.useState('');

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const getUser = useSelector((state) => state.getUser);
    const { loading, user } = getUser;

    const getWork = useSelector((state) => state.getWork);
    const { getWorkspaceData } = getWork;

    const [activeWorkspaceName, setActiveWorkspaceName] = React.useState("Not Specified");
    const [isLoadingState, setIsLoadingState] = React.useState(false);

    React.useEffect(() => {
        if (Object.keys(user).length > 0) {
            let user_detail = user?.data?.profile;
            if (user_detail) {
                setPhoto(user_detail.photo);
                setName(titleCase(user_detail.name));
            }
        }
    }, [user]);

    React.useEffect(() => {
        if (!userData) {
            window.location.href = "/login";
        } else {
            setUserToken(userData?.data?.token);
            dispatch(getUserDetail(userData.data.token));
            dispatch(getWorkspace({ token: userToken }));
        }
    }, [userData, dispatch, userToken]);

    // React.useEffect(() => {
    //     let user_name = userData?.data?.name;
    //     if (user_name) {
    //         setName(titleCase(user_name));
    //     }
    // }, [userData]);

    React.useEffect(() => {
        if (workSpaceInfo) {
            let workSpaceInfo_name = workSpaceInfo[0]?.name;
            setActiveWorkspaceName(titleCase(workSpaceInfo_name));
        }
    }, [workSpaceInfo]);

    const continueWithSelectedWorkspace = (selected_workspace_id) => {
        setIsLoadingState(true);
        let user_token = userData?.data?.token;

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}workspace/get_workspace_by_id?workspace_id=${selected_workspace_id}&token=${user_token}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            toast.success("Workspace selected successfully", { transition: bounce });
            localStorage.setItem("workSpaceInfo", JSON.stringify(response.data.data.workspaces));
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }).catch(function (error) {
            setIsLoadingState(false);
        });

    };

    const logoutHandler = () => {
        dispatch(logout());
    };

    const openWorkspaceMenu = (event) => {
        event.stopPropagation();
        setNavigationMenuVisible(false);
        setWorkspaceMenuVisible((workspaceMenuVisible) => !workspaceMenuVisible);
    };

    const openProfileMenu = (event) => {
        event.stopPropagation();
        setWorkspaceMenuVisible(false);
        setNavigationMenuVisible((navigationMenuVisible) => !navigationMenuVisible);
    };


    return (
        <>
            {isLoadingState | loading ? <Loader /> : null}
            <header className="header__wrapper">
                <div className="header">
                    <Link to="/">
                        <img className="header_logo" src="/images/A.png" alt="Testimonio" />
                    </Link>
                    <div className="header__month-wrapper">
                        <div className="header__counter-wrapper">
                            <p className="header__month-counter" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <span style={{ fontWeight: 'bold' }}>Active workspace:</span>

                                <div style={{ marginLeft: "10px" }} className="dropDownUser__wrapper">
                                    <div onClick={(event) => openWorkspaceMenu(event)} className="dropDownUser__trigger">
                                        <p className="dropDownUser__trigger-name">{activeWorkspaceName}</p>
                                        <div className="dropDownUser__trigger-arrow">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <polyline points="6 9 12 15 18 9" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>

                                    {/* MENU DROPDOWN */}
                                    {workspaceMenuVisible && (
                                        <div className="dropDownUser__list workspace_dropdown">

                                            {getWorkspaceData?.map((workspace) => (
                                                <Link onClick={() => continueWithSelectedWorkspace(workspace.workspace_id)} key={workspace._id} className="dropDownUser__item dropDownUser__item--stroke">
                                                    <div className="dropDownUser__item-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <line x1="3" y1="21" x2="21" y2="21" />
                                                                <path d="M5 21v-14l8 -4v18" />
                                                                <path d="M19 21v-10l-6 -4" />
                                                                <line x1="9" y1="9" x2="9" y2="9.01" />
                                                                <line x1="9" y1="12" x2="9" y2="12.01" />
                                                                <line x1="9" y1="15" x2="9" y2="15.01" />
                                                                <line x1="9" y1="18" x2="9" y2="18.01" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    {titleCase(workspace.name)}
                                                </Link>
                                            ))}

                                        </div>
                                    )}

                                </div>

                            </p>
                        </div>
                        {/* <div className="team__button">
                        <Link to="/billing">
                            <button type="button" className="button_with_add_icon button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-rocket" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
                                    <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
                                    <circle cx="15" cy="9" r="1" />
                                </svg>
                                <p className="button__text">Upgrade</p>
                            </button>
                        </Link>
                    </div> */}
                    </div>
                    <div className="header__action-wrapper">
                        <div className="dropDownUser__wrapper">
                            <div onClick={(event) => openProfileMenu(event)} className="dropDownUser__trigger">
                                {/* <div className="avatar">
                                    <div className="avatar-initials"><div className="avatar-initials__content">{getInitials(name)}</div></div>
                                </div> */}

                                {photo ? (
                                    <div className="avatar-initials"  
                                     style={{
                                        background: "none",
                                        marginRight: "7px",
                                     }}
                                    ><div className="avatar-initials__content">
                                    <img src={photo} alt="" 
                                        style={{borderRadius: '50%'}}
                                    />
                                </div></div>
                                ) : (
                                    <div className="avatar">
                                        <div className="avatar-initials">
                                            <div className="avatar-initials__content">{getInitials(name)}</div>
                                        </div>
                                    </div>
                                )}
                                <p className="dropDownUser__trigger-name">{name}</p>
                                <div className="dropDownUser__trigger-arrow">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <polyline points="6 9 12 15 18 9" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            {/* MENU DROPDOWN */}
                            {navigationMenuVisible && (
                                <div className="dropDownUser__list menu_dropdown">
                                    <Link className="dropDownUser__item dropDownUser__item--stroke" to="/profile">
                                        <div className="dropDownUser__item-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="12" cy="7" r="4" />
                                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                </svg>
                                            </div>
                                        </div>
                                        Profile
                                    </Link>
                                    {/* <Link className="dropDownUser__item dropDownUser__item--stroke" to="/billing">
                                    <div className="dropDownUser__item-icon">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-wallet" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                                                <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                                            </svg>
                                        </div>
                                    </div>
                                    Billing
                                </Link> */}
                                    <Link className="dropDownUser__item dropDownUser__item--stroke" to="/workspaces">
                                        <div className="dropDownUser__item-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="3" y1="21" x2="21" y2="21" />
                                                    <path d="M5 21v-14l8 -4v18" />
                                                    <path d="M19 21v-10l-6 -4" />
                                                    <line x1="9" y1="9" x2="9" y2="9.01" />
                                                    <line x1="9" y1="12" x2="9" y2="12.01" />
                                                    <line x1="9" y1="15" x2="9" y2="15.01" />
                                                    <line x1="9" y1="18" x2="9" y2="18.01" />
                                                </svg>
                                            </div>
                                        </div>
                                        Workspaces
                                    </Link>
                                    <a className="dropDownUser__item dropDownUser__item--stroke" href="https://www.youtube.com/channel/UCBBxejg1W5jrUQYwOzWOsww/videos" target="_blank" rel="noopener noreferrer">
                                        <div className="dropDownUser__item-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                                                    <line x1="13" y1="8" x2="15" y2="8" />
                                                    <line x1="13" y1="12" x2="15" y2="12" />
                                                </svg>
                                            </div>
                                        </div>
                                        Tutorial
                                    </a>
                                    <a className="dropDownUser__item dropDownUser__item--stroke" onClick={logoutHandler}>
                                        <div className="dropDownUser__item-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-power" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                                                    <line x1="12" y1="4" x2="12" y2="12" />
                                                </svg>
                                            </div>
                                        </div>
                                        Logout
                                    </a>
                                </div>
                            )}

                        </div>
                        <a href="https://bluecodetechnologies.freshdesk.com/support/tickets/new" target="_blank" rel="noopener noreferrer" className="header__help header__help--stroke">
                            <div className="header__help-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="12" cy="12" r="9" />
                                        <line x1="12" y1="17" x2="12" y2="17.01" />
                                        <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </header>
        </>
    );
}

$(document).on("click", function () {
    $(".workspace_dropdown").hide();
    $(".menu_dropdown").hide();
});