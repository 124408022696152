import styled from "styled-components";
import { Link } from "react-router-dom";

export const Div = styled.div`
         height: 100vh;
         display: flex;
         justify-content: center;
          align-items: center;

`;
export const MainContainer = styled.div`
          height: auto;

`;
export const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 480px;
        background-color: ${({ theme }) => theme.colors.white};
        margin: 0rem auto;
        border:1px solid #f1f1f1;
        border-radius:5px;
        padding:30px;

        @media (max-width: ${({ theme }) => theme.mobile}) {
          width: 315px;
          /* height: 100%; */
        }
`;

export const TextWrapper = styled.div`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          width: 100%;
          margin-bottom: 1rem;

          @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 85%;
            margin-left: 0.5rem;
          }
`;


export const Title = styled.h1`
        width: 100%;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${({ theme }) => theme.colors.dark};

        @media (max-width: ${({ theme }) => theme.mobile}) {
          font-size: 24px;
          margin-top: 10%;
          margin-bottom: -0.5rem;
        }
`;


export const Subtitle = styled.p`
        /* width: 300px; */
        height: 48px;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #323B43;
        padding: 0px 20px;
        margin-top: 1rem;

        @media (max-width: ${({ theme }) => theme.mobile}) {
          font-size: 14px;
          margin-top: 2rem;
          margin-bottom: 2rem;
          width: 300px;
`;

export const LoginForm = styled.form`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          position: relative;

          @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
          }
`;


export const GoogleIcon = styled.img`
          width: 22px;
          object-fit: contain;
          height: 22px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          outline: none;
`;

export const LoginInput = styled.input`
          width: 100%;
          height: 50px;
          color: ${({ theme }) => theme.colors.dark};
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          border: none;
          box-sizing: border-box;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          text-indent: 40px;
          border: 1px solid #e6e6e6;
          border-radius: 5px;
          background-color: #fafafa;

          &:focus {
            border: 1px solid #0c54f4;
          }

          @media (max-width: ${({ theme }) => theme.mobile}) {
            height: 40px;
            font-size: 14px;
            width: 100%;
          }
`;


export const ForgotPassword = styled.a`
          width: 116px;
          height: 18px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          letter-spacing: -0.5px;
          color: #aa2080;
          cursor: pointer;
          text-decoration: none;
`;

export const Button = styled.button`
          width: 100%;
          height: 50px;
          background: #0c54f4;
          border-radius: 5px;
          border: none;
          color: ${({ theme }) => theme.colors.white};
          font-size: 18px;
          font-weight: 600px;
          cursor: pointer;
          margin-top: 20px;
          
          &:hover {
            background: #0b43bf;
            transition: 0.5s;
          }

          @media (max-width: ${({ theme }) => theme.mobile}) {
            height: 40px;
            font-size: 14px;
            width: 100%;
            width: 85%;
          }
`;

export const LoginButton = styled.button`
          width: 50%;
          height: 50px;
          background: #0c54f4;
          border-radius: 5px;
          border: none;
          color: ${({ theme }) => theme.colors.white};
          font-size: 18px;
          font-weight: 600px;
          cursor: pointer;
          
          &:hover {
            background: #0b43bf;
            transition: 0.5s;
          }

          @media (max-width: ${({ theme }) => theme.mobile}) {
            height: 40px;
            font-size: 14px;
            width: 100%;
            width: 85%;
          }
`;

export const LoginButtonText = styled.p`
          width: 100%;
          height: 18px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
          color: #fafafa;
`;



export const LinkText = styled(Link)`
          color: #aa2080;
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;
`

export const Bottom = styled.div`
          display: flex;
          position: absolute;
          right: 70px;
          bottom: 50px;

          @media (max-width: ${({ theme }) => theme.mobile}) {
            margin-top: -4rem;
            padding-right: 0;
            justify-content: center;
            position: inherit;
          }
`;

export const Select = styled.select`
          width: 106px;
          height: 45px;
          border: none;
          color: ${({ theme }) => theme.colors.dark};
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          cursor: pointer;
          box-sizing: border-box;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0rem;
          padding: 10px;
          background-color: none;
          background: none;
`;

export const SelectText = styled.p`
          width: 28px;
          height: 17px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-align: right;
          color: #999999;
          margin-top: 15px;

          @media (max-width: ${({ theme }) => theme.mobile}) {
            padding-right: 2.5rem;
            margin-top: 15px;
          }
`;


export const FirstFormDiv = styled.div`
          display: flex;
          position: relative;
          width: 100%;
          align-items: center;
          background: tramsparent;
          margin-top: 1rem;

          @media (max-width: ${({ theme }) => theme.mobile}) {
              width: 85%;
          }
`;


export const InputDiv = styled.div`
        width: inherit;
        border-radius: 5px;

        @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
        }

`;

export const IconDiv = styled.div`
          position: absolute;
          left: 10px;
          right: 10px;
          background: transition;
          width: 30px;

          @media (max-width: ${({ theme }) => theme.mobile}) {
              width: 30px;
          }
`;


export const Icon = styled.div`
          position: absolute;
          right: 10px;
          background: transition;
          width: 30px;

          @media (max-width: ${({ theme }) => theme.mobile}) {
              width: 30px;
          }
`;


export const Icon2 = styled.div`
          position: absolute;
          right: 10px;
          background: transition;
          width: 30px;

          @media (max-width: ${({ theme }) => theme.mobile}) {
              width: 30px;
          }
`;



export const SmallTextDiv = styled.div`
        

          @media (max-width: ${({ theme }) => theme.mobile}) {
            margin-top: 2rem;
            margin-bottom: 1rem;
            width: 85%;
          }
`;

export const SmallText = styled.p`
          width: 100%;
          height: 18px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-align: center;
          margin-top: 1.5rem;
          color: #323b43;
`;