import {
    GET_VIDEO_REQUEST,
    GET_VIDEO_SUCCESS,
    GET_VIDEO_FAIL,
    EDIT_VIDEO_REQUEST,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAIL,
    UPLOAD_VIDEO_REQUEST,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAIL,
} from "./VideoTypes";
import axios from "axios";
import { BACKEND_BASE_URL } from "../backendUrl";

export const getVideos = (token, workspace_id) => async (dispatch, getState) => {
    try {
        dispatch({
          type: GET_VIDEO_REQUEST,
        });
    
        const {
        userLogin: { userData },
        } = getState();
    
        const config = {
          headers: {
              Authorization: `Bearer ${userData.data.token}`,
          },
        };
    
        const { data } = await axios.get(`${BACKEND_BASE_URL}video/get_videos?token=${token}&workspace_id=${workspace_id}`, config);
        dispatch({
          type: GET_VIDEO_SUCCESS,
          payload: data,
        });
        
    } catch (error) {
        dispatch({
        type: GET_VIDEO_FAIL,
        payload:
            error.response && error.response.data.message});
    }
    }

export const  editvideo = (video) => async (dispatch, getState) => {
        try {
            dispatch({
            type: EDIT_VIDEO_REQUEST,
            });
        
            const {
            userLogin: { userData },
            } = getState();
        
            const config = {
            headers: {
                Authorization: `Bearer ${userData.data.token}`,
            },
            };
        
            const { data } = await axios.put(
                `${BACKEND_BASE_URL}video/edit_video`,
                video, config
            );
    
            dispatch({
            type: EDIT_VIDEO_SUCCESS,
            payload: data,
            });
        } catch (error) {
            dispatch({
            type: EDIT_VIDEO_FAIL,
            payload:
                error.response && error.response.data.message});
        }
    }

export const uploadVideos = (token, workspace_id, file_base64) => async (dispatch, getState) => {
        console.log(token, workspace_id, file_base64, 'file')
        try {
          dispatch({
            type: UPLOAD_VIDEO_REQUEST,
          });
      
          const {
            userLogin: { userData },
          } = getState();
      
          const config = {
            headers: {
              Authorization: `Bearer ${userData.data.token}`,
            },
          };
      
          const { data } = await axios.post(`${BACKEND_BASE_URL}video/upload_video`, {token, workspace_id, file_base64}, config);
      
          dispatch({
            type: UPLOAD_VIDEO_SUCCESS,
            payload: data,
          });
      
        } catch (error) {
          const message =
            error.response && error.response.data.message
            
          dispatch({
            type: UPLOAD_VIDEO_FAIL,
            payload: message,
          });
        }
      }