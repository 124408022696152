import React from "react";
import "./index.css";

export default function Index() {
  return (
        <div className="main-layout__container">
            <div className="settings-layout">
                <p className="settings-layout__title">Billing plans</p>
                <p className="settings-layout__subTitle">Chooose a plan that best fits your needs, don't worry you can change <br></br> and upgrade anytime without any stress or hassle. Yes no stress.</p>
                
                <div className="settings-layout__nav">
                    <div className="settingsNav__list">
                        <a className="settingsNav__item settingsNav__item--stroke" href="/workspaces">
                            <div className="settingsNav__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <line x1="3" y1="21" x2="21" y2="21" />
                                      <path d="M5 21v-14l8 -4v18" />
                                      <path d="M19 21v-10l-6 -4" />
                                      <line x1="9" y1="9" x2="9" y2="9.01" />
                                      <line x1="9" y1="12" x2="9" y2="12.01" />
                                      <line x1="9" y1="15" x2="9" y2="15.01" />
                                      <line x1="9" y1="18" x2="9" y2="18.01" />
                                    </svg>
                                </div>
                            </div>
                            Workspace
                        </a>
                        <a className="settingsNav__item settingsNav__item--stroke" href="/profile">
                            <div className="settingsNav__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <circle cx="12" cy="7" r="4" />
                                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                    </svg>
                                </div>
                            </div>
                            Profile
                        </a>
                        <a className="settingsNav__item settingsNav__item--stroke" href="/branding">
                            <div className="settingsNav__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />
                                        <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />
                                        <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
                                        <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
                                    </svg>
                                </div>
                            </div>
                            Branding
                        </a>
                        <a className="settingsNav__item settingsNav__item--stroke settingsNav__item--active" href="/billing">
                            <div className="settingsNav__item-icon">
                                <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                                        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                                    </svg>
                                </div>
                            </div>
                            Billing
                        </a>
                    </div>
                </div>

                <div className="settings-layout__content">
                    <div className="billing__plan-page">
                        <div className="billing__info-table">
                            <div className="table">
                                <div className="table__container">
                                    <div className="table__innerContainer">
                                        <div className="table__row billing__table-row">
                                            <div className="billing__table-column billing__table-column--header billing__table-column--name"></div>
                                            <div className="billing__table-column billing__table-column--header">
                                                <div className="billing__table-title">Free</div>
                                                <div className="billing__table--description">Get up to 3+ videos access per month for free.</div>
                                                <div className="billing__current-plan">Current Plan</div>
                                            </div>
                                            <div className="billing__table-column billing__table-column--header">
                                                <div className="billing__table-title">Personal</div>
                                                <div className="billing__table--description">Best for individuals and social influencers.</div>
                                                <div className="billing__table-button">
                                                    <button type="button" className="button button--primary"><p className="button__text">Upgrade</p></button>
                                                </div>
                                            </div>
                                            <div className="billing__table-column billing__table-column--header">
                                                <div className="billing__table-title">Business</div>
                                                <div className="billing__table--description">Best for small businesses and large enterprises.</div>
                                                <div className="billing__table-button">
                                                    <button type="button" className="button button--primary"><p className="button__text">Upgrade</p></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing__plan-info table__row billing__table-row--borderless settings__text settings__text--bold settings__text--default-size">Plan Information</div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Plan Cost</div>
                                        <div className="billing__table-column billing__table-column">Free</div>
                                        <div className="billing__table-column billing__table-column">$20 / month</div>
                                        <div className="billing__table-column billing__table-column">$30 / month</div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Videos per month</div>
                                        <div className="billing__table-column billing__table-column">3</div>
                                        <div className="billing__table-column billing__table-column">Unlimited</div>
                                        <div className="billing__table-column billing__table-column">Unlimited</div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Feedbacks per month</div>
                                        <div className="billing__table-column billing__table-column">0</div>
                                        <div className="billing__table-column billing__table-column">10</div>
                                        <div className="billing__table-column billing__table-column">Unlimited</div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Workspaces</div>
                                        <div className="billing__table-column billing__table-column">1</div>
                                        <div className="billing__table-column billing__table-column">5</div>
                                        <div className="billing__table-column billing__table-column">Unlimited</div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Google Drive Integration</div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Dropbox Integration</div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">One Drive Integration</div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Box Integration</div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing__table-row table__row">
                                        <div className="billing__table-column billing__table-column--name">Team Management</div>
                                        <div className="billing__table-column billing__table-column"></div>
                                        <div className="billing__table-column billing__table-column"></div>
                                        <div className="billing__table-column billing__table-column">
                                            <div className="billing__table-icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <circle cx="12" cy="12" r="9" />
                                                        <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};