/* eslint-disable no-unused-vars */
import React from "react";
import Loader from "../loader/Loader";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { titleCase, arraySearch, createDateFromMysql } from "../../functions";
import InfoModal from '../InfoModal';
import { getUserDetail } from "../../redux/account/AccountAction";
import "./index.css";

import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";
import {
    SharelinkModal,
} from "../TestimonyBody/ModalStyle";
toast.configure();

export default function Index() {
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const [userToken, setUserToken] = React.useState(null);

    const getUser = useSelector((state) => state.getUser);
    const { loading, user } = getUser;


    const [usersData, setUsersData] = React.useState(null);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [isLoadingState2, setIsLoadingState2] = React.useState(false);
    const [tokens, setToken] = React.useState(userData.data.token);
    const [filteredUsersData, setFilteredUsersData] = React.useState([]);
    const [count, setCount] = React.useState(null);
    const [createUserModal, setCreateUserModal] = React.useState(false);
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [acls, setAcls] = React.useState([]);
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");
    const [acessLevel, setAcesslevel] = React.useState([]);
    const [infoModals, setInfoModals] = React.useState(false);

    // RE UL

    React.useEffect(() => {
        dispatch(getUserDetail(userData.data.token));

    }, [dispatch, userData.data.token]);

    React.useEffect(() => {
        if (user) {
            setAcesslevel(user?.data?.profile?.acls)
        }
    }, [user]);

    React.useEffect(() => {
        setIsLoadingState(true);
        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}account/get_reseller_users?resellerToken=${tokens}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setUsersData(response.data.data.users.reverse());
            setIsLoadingState(false);
        }).catch(function (error) {
            setUsersData([]);
            setIsLoadingState(false);
        });

    }, [tokens]);

    React.useEffect(() => {
        if (usersData) {
            setFilteredUsersData(usersData);
        }
    }, [usersData]);


    const handleOnChange = async (e) => {
        let value = e.target.value;
        if (value.length > 2) {
            let search = await arraySearch(usersData, value);
            setFilteredUsersData(search);
            setCount(search.length);
        } else {
            setFilteredUsersData(usersData);
            setCount(usersData?.length);
        }
    };

    const creatUser = async (e) => {
        e.preventDefault();
        if (acessLevel?.length === 1 && acessLevel?.includes('FE')) {
            setInfoModals(true);
            return;
        }
        if (name === "" || email === "" || password === "" || acls?.length === 0) {
            toast.error("Please fill all the fields", { transition: bounce });
            return;
        }
        setIsLoadingState2(true);

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}authentication/signup`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                "name": name,
                "email": email,
                "password": password,
                "acls": acls,
                "resellerToken": tokens,
            }
        };


        axios(config).then(function (response) {
            toast.success("User created successfully.", { transition: bounce });
            setCreateUserModal(false);
            setIsLoadingState2(false);
            setName("");
            setEmail("");
            setPassword("");
            setTimeout(() => {
                window.location.reload();
            }, 200);
        }).catch(function (error, response) {
            // toast.error(error.response.data.message, { transition: bounce });
            setError(error.response.data.message);
            setIsLoadingState2(false);
        });
    };


    const deleteUser = (token, resellerToken) => {
        if (window.confirm('Are you sure you want to delete this user?')) {

            var config = {
                method: 'delete',
                url: `${BACKEND_BASE_URL}authentication/delete_reseller_member`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: {
                    "resellerToken": resellerToken,
                    "token": token
                }
            };

            axios(config).then(function (response) {
                toast.success("User deleted successfully.", { transition: bounce });
                setTimeout(() => {
                    window.location.reload(false);
                }, 200);
            }).catch(function (error) {
                toast.error("User deletion failed.", { transition: bounce });
                setTimeout(() => {
                    window.location.reload(false);
                }, 200);
            });

        }
    };


    return (
        <>
            {isLoadingState ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="team">
                    <div className="team__header-container">
                        <div className="team__header-title-group">
                            <div className="company__billet-container">
                                <p className="team__header-title">Reseller</p>
                            </div>
                            <div className="team__header-description">
                                Create your user account and start using the platform.
                            </div>
                        </div>
                        <div className="team__button">
                            <button type="button" className="button_with_add_icon button button--primary" onClick={() => {
                                if (acessLevel?.length === 1 && acessLevel?.includes('FE')) {
                                    setInfoModals(true);
                                    return;
                                }
                                // if (acessLevel.length === 2 && acessLevel.includes('FE', 'RE')) {
                                //     setInfoModals(true);
                                //     return;
                                // }
                                setCreateUserModal(true);
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <p className="button__text">Create A User</p>
                            </button>
                        </div>
                    </div>

                    <div className="tableFilters__wrapper tableFilters__itemGroup">
                        <div className="tableFilters__item tableFilters__search">
                            <div className="form__input-wrapper">
                                <input type="text" className="form__input form__input--withIcon" placeholder="Search users by name..." onChange={handleOnChange} />
                                <div className="form__input-icon">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="10" cy="10" r="7" />
                                                <line x1="21" y1="21" x2="15" y2="15" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tableFilters__itemGroup">
                            <div className="tableFilters__item tableFilters__item--medium created_by_dropdown">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setTypeIsVisible((typeIsVisible) => !typeIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="3" y="4" width="18" height="4" rx="2" />
                                                        <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                        <line x1="10" y1="12" x2="14" y2="12" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Type: <span>Video</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {typeIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Video</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Audio</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Text</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tableFilters__item tableFilters__item--medium">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedOnIsVisible((createdOnIsVisible) => !createdOnIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="4" y="5" width="16" height="16" rx="2" />
                                                        <line x1="16" y1="3" x2="16" y2="7" />
                                                        <line x1="8" y1="3" x2="8" y2="7" />
                                                        <line x1="4" y1="11" x2="20" y2="11" />
                                                        <rect x="8" y="15" width="2" height="2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Created on: <span>Today</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdOnIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Today</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Yesterday</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last week</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last month</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last quater</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last year</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {filteredUsersData?.length > 0 ? (
                        // DATA LIST
                        <div className="settings-layout">
                            <div className="settings-layout__content">
                                <div className="documents__wrapper">
                                    <div className="app_table__table">
                                        <div className="table documents__table">
                                            <div className="table__container">
                                                <div className="table__innerContainer">
                                                    <div className="table__row table__header">
                                                        <div className="table__column table__column--date app_table__column--medium">Name</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Email</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Date created</div>
                                                        <div className="table__column table__column--action">Action</div>
                                                    </div>

                                                    {filteredUsersData?.map((user, index) => (
                                                        <div className="table__row table__dataRow" key={index}>
                                                            <div className="table__column table__column--text app_table__column--medium">{titleCase(user.name)}</div>
                                                            <div className="table__column table__column--text app_table__column--medium">{user.email}</div>
                                                            <div className="table__column table__column--text app_table__column--medium">{createDateFromMysql(user.createdAt)}</div>



                                                            <div className="table__column table__column--action">
                                                                <div className="documents__optionsDropdown table_action_list">
                                                                    <button title="Delete" className="documents__optionsDropdownTrigger" onClick={
                                                                        () => {
                                                                            deleteUser(user.token, user.resellerToken);
                                                                        }
                                                                    }>
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ef2f2f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <line x1="4" y1="7" x2="20" y2="7" />
                                                                                <line x1="10" y1="11" x2="10" y2="17" />
                                                                                <line x1="14" y1="11" x2="14" y2="17" />
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // EMPTY STATE
                        <div className="documents__empty-table">
                            <div className="empty-table">
                                <div className="empty-table__wrapper">
                                    {/* <div className="empty-table__icon empty-table__icon--team">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <rect x="4" y="4" width="6" height="6" rx="1" />
                                                <rect x="14" y="4" width="6" height="6" rx="1" />
                                                <rect x="4" y="14" width="6" height="6" rx="1" />
                                                <rect x="14" y="14" width="6" height="6" rx="1" />
                                            </svg>
                                        </div>
                                    </div>
                                </div> */}
                                    <div style={{ "marginTop": "10px" }} className="empty-table__header">No Users Yet!</div>
                                    {/* <div className="empty-table__description">
                                    Create new users now!
                                </div> */}
                                    <div className="empty-table__button">
                                        <div className="team__button">
                                            <button type="button" className="button_with_add_icon button button--primary" onClick={() => {
                                                if (acessLevel?.length === 1 && acessLevel?.includes('FE')) {
                                                    setInfoModals(true);
                                                    return;
                                                }
                                                // if (acessLevel.length === 2 && acessLevel.includes('FE', 'RE')) {
                                                //     setInfoModals(true);
                                                //     return;
                                                // }
                                                setCreateUserModal(true);
                                            }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="12" y1="5" x2="12" y2="19" />
                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                </svg>
                                                <p className="button__text">
                                                    Create A User
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>


                {/* CREATE USER*/}
                {createUserModal && (
                    <div className="app_modal">
                        <div style={{ width: "auto" }} className="app_modal__dialog" >
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "10px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">
                                            Create User
                                        </p>

                                        <div onClick={() => {
                                            setCreateUserModal(false);
                                        }} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {error && (
                                        <div className="error">
                                            {error}
                                        </div>
                                    )}
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "500px" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <SharelinkModal>
                                                            <label>Name</label>
                                                            <input type="text" placeholder="Name"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                                required
                                                            />

                                                            <label>Email</label>
                                                            <input type="email" placeholder="Email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                required
                                                            />

                                                            <label>ACLS</label>
                                                            <select
                                                                value={acls}
                                                                onChange={(e) => setAcls(e.target.value)}
                                                            >
                                                                <option value="">Select ACL</option>
                                                                <option value="FE">Testimonio</option>
                                                                <option value="RE">Testimonio Unlimited</option>
                                                                <option value="UL">Testimonio DFY Agency</option>
                                                            </select>

                                                            <label>Password</label>
                                                            <input type="password" placeholder="Password"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                required
                                                            />

                                                            {isLoadingState2 ? (
                                                                <button>
                                                                    Loading...
                                                                </button>
                                                            ) : (
                                                                <button onClick={creatUser}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                                    </svg>
                                                                    Create User
                                                                </button>
                                                            )}

                                                        </SharelinkModal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {infoModals && (
                    <InfoModal onClose={() => setInfoModals(false)} />
                )}
            </div>
        </>
    );
};