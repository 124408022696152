import React from 'react'
import { Link } from 'react-router-dom'

const FooterLanding = () => {
    return (
        <>

            <div className='main_footer_div'>
                <div className='footer_left'>
                    <div className='footer_left_logo'>
                        <Link to="/landing">
                        <img src="/images/A.png" alt="" />
                        </Link>
                        <p>Testimonio is the best in connecting and engaging your customers.</p>
                    </div>
                    <div className='reserved_rights'>
                        <p>©2022 All right reserved. </p>
                    </div>
                </div>
                <div className='footer_right'>
                    <div className='footer_right_item'>
                        <div className='footer_left_item_header'>
                            <p>Platform</p>
                        </div>
                        <div className='footer_left_item_body'>
                            <Link to='/pricing'>Pricing</Link> <br />
                            <Link to='/features'>Features</Link>
                        </div>
                    </div>
                    <div className='footer_right_item'>
                        <div className='footer_left_item_header'>
                            <p>Resources</p>
                        </div>
                        <div className='footer_left_item_body'>
                            <Link to='/pricing'>Blog</Link> <br />
                            <Link to='/pricing'>Help Center</Link>
                        </div>
                    </div>
                    <div className='footer_right_item'>
                        <div className='footer_left_item_header'>
                            <p>Company</p>
                        </div>
                        <div className='footer_left_item_body'>
                            <Link to='/contact'>Contact Us</Link> <br />
                            <Link to='/contact'>Terms & Conditions</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FooterLanding