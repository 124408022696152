import styled from "styled-components";
import { Link } from "react-router-dom";
export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 400px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    height: 100%;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 85%;
    margin-left: 0.5rem;
  }
`;

export const LoginTitle = styled.h1`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  margin: 0px;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 24px;
  
    margin-top: 10%;
    margin-bottom: -0.5rem;
  }
`;

export const LoginSubtitle = styled.h2`
  width: 100%;
  height: 21px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  
  color: ${({ theme }) => theme.colors.grey};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
    margin-top: 1.3rem;
    
  }
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

export const GoogleButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.white};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  cursor: pointer;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  outline: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border: 1px solid #0c55f4;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 40px;
    font-size: 14px;
    
    width: 85%;
  }
`;

export const ButtonText = styled.p`
  width: 100%;
  height: 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  
  color: #323b43;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
    
  }
`;

export const Image = styled.img`
  width: 30px;
  object-fit: contain;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  outline: none;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 25px;
    height: 25px;
  }
`;

export const Image2 = styled.img`
  width: 20px;
  object-fit: contain;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  outline: none;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 25px;
    height: 25px;
  }
`;

export const Image3 = styled.img`
  width: 22px;
  object-fit: contain;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  outline: none;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 25px;
    height: 25px;
  }
`;

export const LineDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 1rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

export const Line = styled.div`
  width: 200px;
  height: 0px;
  border: 1px solid #e6e6e6;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 80px;
  }
`;

export const LineText = styled.p`
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  text-align: center;
  color: #999999;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 14px;
    
    width: 40%;
  }
`;

export const LoginInput = styled.input`
  width: 100%;
  height: 50px;
  color: ${({ theme }) => theme.colors.dark};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border: none;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-indent: 40px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background-color: #fafafa;

  &:focus {
    border: 1px solid #0c54f4;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 40px;
    font-size: 14px;
    width: 100%;
  }
`;

export const Divs = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  height: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 85%;
  }
`;

export const ForgotPassword = styled.a`
  width: 116px;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-align: center;
  letter-spacing: -0.5px;
  color: #aa2080;
  cursor: pointer;
  text-decoration: none;
`;

export const LoginButton = styled.button`
  width: 100%;
  height: 50px;
  background: #0c54f4;
  border-radius: 5px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  font-weight: 600px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background: #0b43bf;
    transition: 0.5s;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 40px;
    font-size: 14px;
    width: 100%;
    width: 85%;
  }
`;

export const LoginButtonText = styled.p`
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #fafafa;
`;

export const Text = styled.p`
  width: 100%;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 1.5rem;
  color: #323b43;
`;

export const LinkText = styled(Link)`
  color: #aa2080;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;

`

export const Bottom = styled.div`
  display: flex;
  position: absolute;
  right: 70px;
  bottom: 50px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 2rem;
    padding-right: 0;
    justify-content: center;
    position: inherit;
  }
`;

export const Select = styled.select`
  width: 106px;
  height: 45px;
  border: none;
  color: ${({ theme }) => theme.colors.dark};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0rem;
  padding: 10px;
  background-color: none;
  background: none;
`;

export const SelectText = styled.p`
  width: 28px;
  height: 17px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  color: #999999;
  margin-top: 15px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-right: 2.5rem;
    margin-top: 15px;
  }
`;



export const FirstFormDiv = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    background: tramsparent;
    margin-top: 1rem;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 85%;
    }
    

`;

export const InputDiv = styled.div`
    width: inherit;
    border-radius: 5px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
        width: 100%;
    }

`;

export const IconDiv = styled.div`
position: absolute;
left: 10px;
right: 10px;
background: transition;
width: 30px;

@media (max-width: ${({ theme }) => theme.mobile}) {
    width: 30px;
}
`;


export const Icon = styled.div`
position: absolute;
right: 10px;
background: transition;
width: 30px;

@media (max-width: ${({ theme }) => theme.mobile}) {
    width: 30px;
}
`;
export const Icon2 = styled.div`
position: absolute;
right: 10px;
background: transition;
width: 30px;

@media (max-width: ${({ theme }) => theme.mobile}) {
    width: 30px;
}
`;