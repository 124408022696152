/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Container,
  Title,
  Subtitle,
  TextWrapper,
  LoginForm,
  Image,
  Image2,
  LoginInput,
  ForgotPassword,
  Div,
  Button,
  // SmallText,
  InputWrapper,
  InputDiv,
  IconDiv,
  Icon,
  // LinkText,
} from "./LoginStyle";
import { login } from "../../../redux/auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {bounce} from "../../../toast"
toast.configure();


const Login = () => {

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, message, userData } = userLogin;

  const getWorkById = useSelector((state) => state.getWorkById);
  const {  workSpaceInfo } = getWorkById;

  const empty = (mixedVar) => {
      var undef
      var key
      var i
      var len
      var emptyValues = [undef, null, false, 0, '', '0', 'null', 'undefined']

      for (i = 0, len = emptyValues.length; i < len; i++) {
          if (mixedVar === emptyValues[i] || typeof mixedVar == "undefined") {
              return true
          }
      }

      if (typeof mixedVar === 'object') {
          for (key in mixedVar) {
              if (mixedVar.hasOwnProperty(key)) {
                  return false
              }
          }
          return true
      }

      return false
  }

  useEffect(() => {
    if (userData) {
      toast.success(message, { transition: bounce });
      setTimeout(() => {
        console.log()
        if(empty(workSpaceInfo)){
          document.location.href = "/choose-work-space";
        }else{
          document.location.href = "/dashboard";
        }
      }, 800);
    }
    if (error) {
      toast.error(error, { transition: bounce });
    }
  }, [userData, message, error]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };

  return (
    <>
      <Container>
        <TextWrapper>
          <Title>Login to your acount</Title>
          <Subtitle>Connect to customers with video</Subtitle>
        </TextWrapper>
        {loading && <Loader />}
        <LoginForm onSubmit={submitHandler}>
          {/* <GoogleButton>
            <ButtonText>
              <Image src="/images/google-login.svg" />
              Sign in with Google
            </ButtonText>
          </GoogleButton>

          <LineDiv>
            {" "}
            <Line /> <LineText>Or sign in using Email</LineText> <Line />{" "}
          </LineDiv> */}

          <InputWrapper>
            <IconDiv>
              <Image src="/images/email2.svg" />
            </IconDiv>
            <InputDiv>
              <LoginInput
                placeholder="Email address"
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputDiv>
          </InputWrapper>

          <InputWrapper>
            <IconDiv>
              <Image2 src="/images/lock.svg" />
            </IconDiv>

            <Icon onClick={togglePasswordVisiblity}>
              <Image2
                {...(passwordShown
                  ? { src: "/images/eye-open.svg" }
                  : { src: "/images/eye.svg" })}
              />
            </Icon>

            <InputDiv>
              <LoginInput
                placeholder="Password"
                name="password"
                id="password"
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputDiv>
          </InputWrapper>

          <Div>
            <ForgotPassword to="/reset-password">
              Forgot Password?
            </ForgotPassword>
          </Div>
          <Button>Log in</Button>
          {/* <SmallText>
            Don’t have an account? <LinkText to="/signup">Sign up</LinkText>
          </SmallText> */}
        </LoginForm>
      </Container>
{/* 
      <BottomSelect>
        <BottomText>Help</BottomText>
        <Select name="help" id="help" className="help">
          <option>English</option>
          <option>French</option>
          <option>German</option>
          <option>Spanish</option>
        </Select>
      </BottomSelect> */}
    </>
  );
};

export default Login;
