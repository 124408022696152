/* eslint-disable no-unused-vars */
import React from 'react';
import { Div, Circle, MainContainer, Container, Wrapper, InputWrapper } from "./PersonaRequestStyle";
import { titleCase, getInitials } from "../../functions";
import ReactStars from "react-rating-stars-component";

const Request = (props) => {

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const feedbackOwnerData = JSON.parse(props.feedbackOwnerData);

    const [rate, setRate] = React.useState(0);

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRate(newRating);
    };

    const submitPersona = (event) => {
        event.preventDefault();

        if (event.target.fullname.value && event.target.email.value) {
            if (validateEmail(event.target.email.value)) {
                let payload = {
                    name: event.target.fullname.value,
                    email: event.target.email.value,
                    rating: rate,
                };
                props.setFeedbackPersonaData(payload);
                props.setActiveScreen("type");
            }
        }
    };



    return (
        <>
            {/* <Div> */}
                <div className="margin-top-containers">
                    <MainContainer>

                        <Container>
                            <Wrapper>

                                {/* <Circle>{getInitials(titleCase(feedbackOwnerData.name))}</Circle> */}
                                <img src={feedbackOwnerData.photo} alt="" />
                                <p>{titleCase(feedbackOwnerData.name.split(/(\s+)/).filter(e => e.trim().length > 0)[0])} wants a feedback from you!</p>
                                <span>We need to know who this feedback message is coming from, Please fill out your info below.</span>

                                <form onSubmit={(event) => submitPersona(event)}>
                                    <InputWrapper>

                                        <input type="text" name="fullname" placeholder='Your full name' />
                                    </InputWrapper>
                                    <InputWrapper>

                                        <input type="email" name="email" placeholder='Your email address' />
                                    </InputWrapper>
                                   
                                    <div className='star_rating'>
                                    <label
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginBottom: "20px",
                                            fontSize: "18px",
                                            fontWeight: "500",
                                            color: "#323B43",


                                        }}
                                    >
                                        Leave a rating
                                    </label>
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={40}
                                        activeColor="#ffd700"
                                        value={0}
                                        isHalf={true}

                                    />
                                </div>
                                    

                                    <div style={{
                                        display: 'flex',
                                        position: 'relative',
                                    }}>
                                        <button type="submit">
                                            Continue
                                            <div style={{
                                                display: "flex",
                                                position: 'absolute',
                                                right: '20px',
                                            }}>
                                                <img src="/images/arrow-white.svg" alt="arrow" />
                                            </div>

                                        </button>
                                    </div>
                                </form>

                            </Wrapper>
                        </Container>

                    </MainContainer>
                </div>
                <br />
            {/* </Div> */}
        </>
    );
};

export default Request;
