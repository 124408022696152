import { Helmet } from "react-helmet";

const Metadata = ({ title, image, ogTitle, ogDesc }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={ogTitle} />
            <meta property="og:description" content={ogDesc} />
            <meta property="og:image" content={image} />
        </Helmet>
    );
};

export default Metadata;
