/* eslint-disable no-useless-escape */
import React, { useState, useReducer } from 'react';
import "./index.css"

const formReducer = (state, event) => {
 return {
   ...state,
   [event.name]: event.value
 }
}

const Landing = () => {

    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false);
    const [modal, setModal] = useState(false);
    const [link, setLink] = useState("");
    const [message , setMessage] = useState('');

    const validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    
    const submitEmail = event => {
      event.preventDefault();
      if (validateEmail(formData.email)) {

        setSubmitting(true);

        var payload = {
          "api_key": "KPD00U",
          "email": formData.email,
          "referral_link": document.URL
        }

        fetch('https://getwaitlist.com/api/v1/waitlists/submit', {
          method: 'post',
          body: JSON.stringify(payload),
          headers: {
              "Content-Type": "application/json"
          },
        }).then(function (response) { return response.json() }).then(function (data) {
          setSubmitting(false);
          // alert("Hurray! you have been added to the early adopters waiting list successfully.")
          setModal(true);
          setLink(data.referral_link);
        });

      } else {
        alert("The email address you provided is invalid, please check and try again.")
      }
    }

    const handleChange = event => {
      setFormData({
        name: event.target.name,
        value: event.target.value,
      });
    }

      const copyLink = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    document.execCommand("copy");
   setMessage('Link copied to clipboard')
  };

    return (
        <>
            <div>
              <div className="container">
                <div className="main">
                  <div className="topleft">
                    <img src="/images/logos.svg" alt="logo" />
                  </div>
                  <div className="topright">
                    <a href="/blog">Go to blog</a>
                  </div>
                  <div className="content">
                    <div className="title">
                      <h1>Connect &amp; engage your customers with
                        <mark className="circle_mark">video.</mark>
                      </h1>
                    </div>
                    <div className="subtitle">
                      <p>Build Human Relationship, shorten sales cycles, Increase email conversions, Drive engagement,
                        qualify more leads and close deals faster.</p>
                    </div>
                    <div className="forms">
                      <form onSubmit={submitEmail}>
                        <input onChange={handleChange} type="text" name="email" placeholder="Email" />
                        <button type="submit">
                          {submitting? "Submitting..." : "Join the beta. Its free!" }
                        </button>
                      </form>
                      
                    </div>
                  </div>
                </div>
                <div className="image-wrapper">
                  <div className="image-div">
                    <img src="/images/dashboard.svg" alt="" />
                  </div>
                </div>
              </div>
              <div>
                <div className="why-us">
                  <div className="heading">
                    <h1>Why <span className="spanss">Testimonio</span> for <br /><span className="spanss"> your business</span> </h1>

                    

                  </div>
                  <div className="about">
                    <div className="curve-wrapper">
                      <div className="angle">
                        <img src="/images/angle.svg" alt="" />
                      </div>
                      <div className="curve">
                        <p>Because the future of business is personal</p>
                      </div>
                    </div>
                    <div className="curve-wrapper">
                      <div className="angle2">
                        <img src="/images/angle2.svg" alt="" />
                      </div>
                      <div className="curve2">
                        <p>Stand out in your customers inbox</p>
                      </div>
                    </div>
                    <div className="curve-wrapper">
                      <div className="angle3">
                        <img src="/images/angle3.svg" alt="" />
                      </div>
                      <div className="curve3">
                        <p>Personalized and accessible online experience</p>
                      </div>
                    </div>
                  </div>
                  <div className="read">
                    <h1>Read our latest blog posts</h1>
                    <a href="/blog">Go to blog</a>
                    <p>&copy; Copyright reserved, Testimonio 2022</p>
                  </div>
                  {/* <button type="button" id="myBtn" className='btn'>Modal</button> */}
                </div>
              </div>
            </div>

            {/* The Modal */}
            {/* <div id="myModal" className="modal">
              
              <div className="modal-content">
                <div className="circle">
                  <img src="/images/cong.svg" alt="" className='cong'/>
                </div>
                <div className="modal-header">
                  <h2>Well done!</h2>
                  <p>You have successfully joined the waiting list. Check your mail</p>
                </div>
                <div className="modal-link">
                  <div className="link">
                  </div>
                  <p>copy link to share</p>
                </div>
                <span className="close">×</span>
              </div>
            </div> */}

                  {/* The Modal */}
      <div
        id="myModal"
        className="modal"
        style={{
          display: modal ? "block" : "none",
          opacity: modal ? "1" : "0",
          visibility: modal ? "visible" : "hidden",
          transition: "opacity 0.3s ease-in-out",
          transitionDelay: "0.3s",
          transitionDuration: "0.3s",
          transitionProperty: "opacity, visibility",
        }}
      >
        {/* Modal Content */}
        <div className="modal-content">
          <div className="modal-header">
            <div className="circle">
              <img src="/images/cong.svg" alt="" />
            </div>
            <span className="close" onClick={() => setModal(false)}>
              &times;
            </span>
            <h2>Well done!</h2>
            <p>
              You have successfully joined the waiting list. Check your mail
            </p>
          </div>

          <p>{message}</p>
          <div className="modal-body">
            <div className="modal-link">
              <input type="text"
              value={link}
              id="myInput"
              readOnly
              onClick={copyLink}
              />
              <p>copy link to share</p>
            </div>
          </div>
        </div>
      </div>

        </>
    )
}

export default Landing
