import React from "react";
import "./index.css";
import DashboardNavigation from "../components/DashboardNavigation";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardFooter from "../components/DashboardFooter";
import BrandingBody from "../components/BrandingBody";

const Branding = () => {
  return (
    <div className="main-layout">
          <DashboardNavigation />
          <div className="main-layout__content">
            <DashboardSidebar />
            <BrandingBody />
          </div>
          <DashboardFooter />
    </div>
  );
};

export default Branding;
