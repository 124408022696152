/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import {
    VideoModalDiv,
    TextDiv2,
    ImageDiv2,
    IconDiv2,
    VideoModalFooter,
} from "./VideoModalStyle";

import {
    ShareVideoDiv,
    ShareDiv,
    DivLeft,
    DivRight,
    ShareLinkDiv,
    ImageDiv,
    CopyTextDiv,
    TextDiv,
    ButtonDiv,
    Button1,
    ButtonLink
} from "./ShareModalStyle";

import "./index.css";
import { getVideos } from "../../redux/video/VideoAction";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import axios from "axios";
import $ from 'jquery';
import { titleCase, arraySearch } from "../../functions";
toast.configure();

export default function Index() {

    const dispatch = useDispatch();
    const [createdOnIsVisible, setCreatedOnIsVisible] = React.useState(false);
    const [createdByIsVisible, setCreatedByIsVisible] = React.useState(false);
    const [analyticsIsVisible, setAnalyticsIsVisible] = React.useState(false);
    const [activeViewAnalyticsVideo, setActiveViewAnalyticsVideo] = React.useState(null);
    const [activeShareVideo, setActiveShareVideo] = React.useState(null);
    const [shareIsVisible, setShareIsVisible] = React.useState(false);
    const [websiteCopy, setWebsiteCopy] = React.useState(false);
    const [linkCopy, setLinkCopy] = React.useState(false);
    const [emailCopy, setEmailCopy] = React.useState(false);
    const [animatedCopy, setAnimatedCopy] = React.useState(false);
    const [stillCopy, setStillCopy] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [filteredVideos, setFilteredVideos] = React.useState([]);
    const [count, setCount] = React.useState(null);
    const [facebookCopy, setFacebookCopy] = React.useState(false);
    const [twitterCopy, setTwitterCopy] = React.useState(false);
    const [linkedinCopy, setLinkedinCopy] = React.useState(false);
    const [whatsappCopy, setWhatsappCopy] = React.useState(false);
    const [feedbackVideos, setFeedbackVideos] = React.useState([]);
    const [activeFeedbackVideo, setActiveFeedbackVideo] = React.useState(null);
    const [feedbackIsVisible, setFeedbackIsVisible] = React.useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const getWorkById = useSelector((state) => state.getWorkById);
    const { workSpaceInfo } = getWorkById;

    const getVideo = useSelector((state) => state.getVideo);
    let { loading: loadingGetVideo, videoData } = getVideo;

    const [workspace_id, setWorkspace_id] = React.useState(workSpaceInfo[0]?.workspace_id);
    const [token, setToken] = React.useState(userData.data.token);

    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [integrationData, setIntegrationData] = React.useState(null);

    //check if user is logged in 
    React.useEffect(() => {
        if (!userData) {
            window.location.href = "/login";
        } else {
            dispatch(getVideos(token, workspace_id));

            setIsLoadingState(true);
            var config = {
                method: 'get',
                url: `${BACKEND_BASE_URL}integrations/get_integrations?token=${token}&workspace_id=${workspace_id}`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            axios(config).then(function (response) {
                setIsLoadingState(false);
                setIntegrationData(response.data.data.integrations.apps);
            }).catch(function (error) {
                setIntegrationData([]);
            });

            var config2 = {
                method: 'get',
                url: `${BACKEND_BASE_URL}feedback/get_feedbacks_videos?token=${token}&workspace_id=${workspace_id}&type=video`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            axios(config2).then(function (response) {
                setIsLoadingState(false);
                setFeedbackVideos(response.data.data.feedbacks);
            }).catch(function (error) {
                setIntegrationData([]);
            });

        }
    }, []);

    React.useEffect(() => {
        if (videoData) {
            setFilteredVideos(videoData);
        }
    }, [videoData]);


    const handleOnChange = async (e) => {
        let value = e.target.value;
        if (value.length > 2) {
            let search = await arraySearch(videoData, value);
            setFilteredVideos(search);
            setCount(search.length);
        } else {
            setFilteredVideos(videoData);
            setCount(videoData?.length);
        }
    };


    const deleteVideo = (video_id) => {
        if (window.confirm('Are you sure you want to delete this video?')) {

            var config = {
                method: 'delete',
                url: `${BACKEND_BASE_URL}video/delete_video`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: {
                    "token": token,
                    "video_id": video_id
                }
            };

            axios(config).then(function (response) {
                toast.success("Video deleted successfully");
                window.location.reload();
            }).catch(function (error) {
                toast.error("Error deleting video");
                window.location.reload();
            });

        }
    };

    const deleteFeedback = (feedback_id) => {
        if (window.confirm('Are you sure you want to delete this video?')) {

            var config = {
                method: 'delete',
                url: `${BACKEND_BASE_URL}feedback/delete_feedback`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: {
                    "token": token,
                    "feedback_id": feedback_id
                }
            };

            axios(config).then(function (response) {
                toast.success("Video deleted successfully");
                window.location.reload();
            }).catch(function (error) {
                toast.error("Error deleting video");
                window.location.reload();
            });

        }
    };


    const viewAnaltics = (video) => {
        setActiveViewAnalyticsVideo(video);
        setAnalyticsIsVisible(true);
    };

    const shareOrEmbedVideo = (video) => {
        setActiveShareVideo(video);
        setShareIsVisible(true);
    };

    const shareTestimonyVideo = (video) => {
        setActiveFeedbackVideo(video);
        setFeedbackIsVisible(true);
    };

    // COPY TO CLIPBOARD
    const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    };

    const copyTextToClipboard = (text, type) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            if (type === "link") {
                setLinkCopy(true);
                setTimeout(() => {
                    setLinkCopy(false);
                }, 5000);
            }
            if (type === "email") {
                setEmailCopy(true);
                setTimeout(() => {
                    setEmailCopy(false);
                }, 5000);
            }
            if (type === "website") {
                setWebsiteCopy(true);
                setTimeout(() => {
                    setWebsiteCopy(false);
                }, 5000);
            }
            if (type === "facebook") {
                setFacebookCopy(true);
                setTimeout(() => {
                    setFacebookCopy(false);
                }, 5000);
            }
            if (type === "linkedin") {
                setLinkedinCopy(true);
                setTimeout(() => {
                    setLinkedinCopy(false);
                }, 5000);
            }
            if (type === "whatsapp") {
                setWhatsappCopy(true);
                setTimeout(() => {
                    setWhatsappCopy(false);
                }, 5000);
            }
            if (type === "twitter") {
                setTwitterCopy(true);
                setTimeout(() => {
                    setTwitterCopy(false);
                }, 5000);
            }
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

    const exportToDropBox = (url) => {

        setIsLoadingState(true);

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}integrations/dropbox/upload`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                token: token,
                workspace_id: workspace_id,
                file_url: url
            }
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            toast.success("Video exported to dropbox successfully");
        }).catch(function (error) {
            toast.error("Error exporting video to dropbox");
            setIsLoadingState(false);
        });
    };

    const exportToGoogleDrive = (url) => {

        setIsLoadingState(true);

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}integrations/google_drive/upload`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                token: token,
                workspace_id: workspace_id,
                file_url: url
            }
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            toast.success("Video exported to google drive successfully");
        }).catch(function (error) {
            toast.error("Error exporting video to google drive");
            setIsLoadingState(false);
        });
    };

    const exportToOneDrive = (url) => {

        setIsLoadingState(true);

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}integrations/one_drive/upload`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                token: token,
                workspace_id: workspace_id,
                file_url: url
            }
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            toast.success("Video exported to one drive successfully");
        }).catch(function (error) {
            toast.error("Error exporting video to one drive");
            setIsLoadingState(false);
        });
    };

    const exportToBox = (url) => {

        setIsLoadingState(true);

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}integrations/box/upload`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                token: token,
                workspace_id: workspace_id,
                file_url: url
            }
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            toast.success("Video exported to box successfully");
        }).catch(function (error) {
            toast.error("Error exporting video to box");
            setIsLoadingState(false);
        });
    };

    const showVideoDropdown = (event, video_id) => {
        event.stopPropagation();
        // $(".video_dropdown").hide();
        $(`#video_dropdown_${video_id}`).toggle();
    };

    const showTestimonyVideoDropdown = (event, feedback_id) => {
        event.stopPropagation();
        // $(".video_dropdown").hide();
        $(`#video_dropdown_${feedback_id}`).toggle();
    };

    const handleParentClick = () => {
        $(".video_dropdown").hide();
    };

    const animatedVideoCodeRef = React.useRef(null);
    const copyAnimatedImage = () => {
        setAnimatedCopy(true);
        setTimeout(() => {
            setAnimatedCopy(false);
        }, 5000);

        let range = document.createRange();
        range.selectNodeContents(animatedVideoCodeRef.current);

        let sel = window.getSelection();
        sel.removeAllRanges();

        sel.addRange(range);
        document.execCommand("Copy");
        sel.removeAllRanges();
        //paste the copied data to mailto body
        document.addEventListener("paste", function (event) {
            var clipText = event.clipboardData.getData("Text");
            window.location = `mailto:?subject=I wanted you to see this site&body=${clipText}`;
        });
    };

    const stillVideoCodeRef = React.useRef(null);
    const copyStillImage = () => {
        setStillCopy(true);
        setTimeout(() => {
            setStillCopy(false);
        }, 5000);

        let range = document.createRange();
        range.selectNodeContents(stillVideoCodeRef.current);

        let sel = window.getSelection();
        sel.removeAllRanges();

        sel.addRange(range);
        document.execCommand("Copy");
        sel.removeAllRanges();
        //paste the copied data to mailto body
        document.addEventListener("paste", function (event) {
            var clipText = event.clipboardData.getData("Text");
            window.location = `mailto:?subject=I wanted you to see this site&body=${clipText}`;
        });
    };

    return (

        <>
            {isLoadingState ? <Loader /> : null}
            <div onClick={() => handleParentClick()} className="main-layout__container" id="bodyWrapper">
                <div className="team">


                    <div className="team__header-container">
                        <div className="team__header-title-group">
                            <div className="company__billet-container">
                                <p className="team__header-title">Videos</p>
                            </div>
                            <div className="team__header-description">Create Awesome Videos by recording from your camera, your screen, or both, or by uploading a video file,<br></br> and make your videos and testimonial videos go viral.</div>


                        </div>
                        <div className="team__button">
                            <a href="/create-video">
                                <button type="button" className="button_with_add_icon button button--primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <p className="button__text">Add New Video</p>
                                </button>
                            </a>
                        </div>
                    </div>


                    {/* FILTERS */}
                    <div className="tableFilters__wrapper tableFilters__itemGroup">
                        <div className="tableFilters__item tableFilters__search">
                            <div className="form__input-wrapper">
                                <input type="text" className="form__input form__input--withIcon" placeholder="Search videos by name..." onChange={handleOnChange} />
                                <div className="form__input-icon">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="10" cy="10" r="7" />
                                                <line x1="21" y1="21" x2="15" y2="15" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tableFilters__itemGroup">
                            <div className="tableFilters__item tableFilters__item--medium created_by_dropdown">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedByIsVisible((createdByIsVisible) => !createdByIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="12" cy="7" r="4" />
                                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Created by: <span>Me</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdByIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Me</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Team</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tableFilters__item tableFilters__item--medium">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedOnIsVisible((createdOnIsVisible) => !createdOnIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="4" y="5" width="16" height="16" rx="2" />
                                                        <line x1="16" y1="3" x2="16" y2="7" />
                                                        <line x1="8" y1="3" x2="8" y2="7" />
                                                        <line x1="4" y1="11" x2="20" y2="11" />
                                                        <rect x="8" y="15" width="2" height="2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Created on: <span>Today</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdOnIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Today</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Yesterday</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last week</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last month</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last quater</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last year</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {loadingGetVideo && <Loader />}

                    {filteredVideos?.length === 0 && feedbackVideos.length === 0 ? (
                        <div className="documents__empty-table">
                            <div className="empty-table">
                                <div className="empty-table__wrapper">
                                    <div className="empty-table__icon empty-table__icon--team">
                                        <div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                                    <rect x="3" y="6" width="12" height="12" rx="2" />
                                                    <line x1="7" y1="12" x2="11" y2="12" />
                                                    <line x1="9" y1="10" x2="9" y2="14" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="empty-table__header">Start creating some videos!</div>
                                    <div className="empty-table__description">Add your first video content to your account</div>
                                    <div className="empty-table__button">
                                        <div className="team__button">
                                            <a href="/create-video">
                                                <button type="button" className="button_with_add_icon button button--primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                    </svg>
                                                    <p className="button__text">Add New Video</p>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) :

                        (
                            <div className="app-billing" >
                                <div className="app-billing__plans-container" style={{ marginBottom: "20px" }}>
                                    <div className="app-billing__plan-cards-container">



                                        {filteredVideos?.map((video, index) => (
                                            <div className="app-plan" key={video.video_id}>
                                                <div className="app-plan__wrapper">
                                                    <div className="app_plan_cover">
                                                        <video
                                                            loop
                                                            onMouseOver={(event) => event.target.play()}
                                                            onMouseOut={(event) => event.target.pause()}
                                                        >
                                                            <source src={video.url} type="video/mp4"></source>
                                                        </video>
                                                    </div>
                                                    <div className="app-plan__header">
                                                        <div className="app-plan__title">{titleCase(video.name.substring(0, 30)) + "..."}</div>
                                                        <div className="app-plan__title app-plan__desc">Created by <span>{titleCase(video.author)}</span></div>
                                                    </div>
                                                    <div className="app-plan__content-section">
                                                        <div className="app_stats_group">
                                                            <div className="app-plan__content-column">
                                                                <div className="app-plan__content-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="2" />
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span>Visits {video.visits}</span>
                                                            </div>
                                                            <div className="app-plan__content-column">
                                                                <div className="app-plan__content-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M7 4v16l13 -8z" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span>Plays {video.plays}</span>
                                                            </div>
                                                            <div className="app-plan__content-column">
                                                                <div className="app-plan__content-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <line x1="3" y1="12" x2="6" y2="12" />
                                                                            <line x1="12" y1="3" x2="12" y2="6" />
                                                                            <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
                                                                            <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
                                                                            <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
                                                                            <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span>Clicks {video.clicks}</span>
                                                            </div>
                                                            {/* <div className="app-plan__content-column">
                                                                <div className="app-plan__content-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span>Likes {video.likes}</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="settingsSignature__dropDown-wrapper">
                                                    <div className="settingsSignature__dropDown-inner">

                                                        <div onClick={(event) => showVideoDropdown(event, video.video_id)} className="settingsSignature__dropDown-trigger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="1" />
                                                                <circle cx="12" cy="19" r="1" />
                                                                <circle cx="12" cy="5" r="1" />
                                                            </svg>
                                                        </div>

                                                        <div style={{ display: 'none' }} id={`video_dropdown_${video.video_id}`} className="video_dropdown settingsSignature__dropDown-list">
                                                            <a href={`/create-page?video_id=${video.video_id}`}>
                                                                <div className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                    <div className="settingsSignature__dropDown-item-icon">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                <line x1="16" y1="5" x2="19" y2="8" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <p className="settingsSignature__dropDown-item-label">Edit</p>
                                                                </div>
                                                            </a>
                                                            <div onClick={() => shareOrEmbedVideo(video)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                <div className="settingsSignature__dropDown-item-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                                                                            <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <p className="settingsSignature__dropDown-item-label">Share</p>
                                                            </div>
                                                            <a target="_blank" href={video.url} download rel="noreferrer">
                                                                <div className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                    <div className="settingsSignature__dropDown-item-icon">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
                                                                                <line x1="12" y1="13" x2="12" y2="22" />
                                                                                <polyline points="9 19 12 22 15 19" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <p className="settingsSignature__dropDown-item-label">Download</p>
                                                                </div>
                                                            </a>
                                                            <div onClick={() => viewAnaltics(video)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                <div className="settingsSignature__dropDown-item-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <rect x="10" y="9" width="4" height="12" rx="1.105" />
                                                                            <rect x="17" y="3" width="4" height="18" rx="1.105" />
                                                                            <circle cx="5" cy="19" r="2" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <p className="settingsSignature__dropDown-item-label">Analytics</p>
                                                            </div>
                                                            {integrationData?.filter((app) => { return app.name === "dropbox"; })[0]?.is_connected && (
                                                                <div onClick={() => exportToDropBox(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                    <div className="settingsSignature__dropDown-item-icon">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <rect x="3" y="4" width="18" height="4" rx="2" />
                                                                                <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                                                <line x1="10" y1="12" x2="14" y2="12" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <p className="settingsSignature__dropDown-item-label">Export to Dropbox</p>
                                                                </div>
                                                            )}
                                                            {integrationData?.filter((app) => { return app.name === "google_drive"; })[0]?.is_connected && (
                                                                <div onClick={() => exportToGoogleDrive(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                    <div className="settingsSignature__dropDown-item-icon">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M12 10l-6 10l-3 -5l6 -10z" />
                                                                                <path d="M9 15h12l-3 5h-12" />
                                                                                <path d="M15 15l-6 -10h6l6 10z" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <p className="settingsSignature__dropDown-item-label">Export to GDrive</p>
                                                                </div>
                                                            )}
                                                            {integrationData?.filter((app) => { return app.name === "one_drive"; })[0]?.is_connected && (
                                                                <div onClick={() => exportToOneDrive(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                    <div className="settingsSignature__dropDown-item-icon">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-12" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <p className="settingsSignature__dropDown-item-label">Export to OneDrive</p>
                                                                </div>
                                                            )}
                                                            {integrationData?.filter((app) => { return app.name === "box"; })[0]?.is_connected && (
                                                                <div onClick={() => exportToBox(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                                    <div className="settingsSignature__dropDown-item-icon">
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <rect x="4" y="4" width="16" height="16" rx="2" />
                                                                                <path d="M4 13h3l3 3h4l3 -3h3" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                    <p className="settingsSignature__dropDown-item-label">Export to Box</p>
                                                                </div>
                                                            )}
                                                            <div onClick={() => deleteVideo(video.video_id)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--delete">
                                                                <div className="settingsSignature__dropDown-item-icon">
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <line x1="4" y1="7" x2="20" y2="7" />
                                                                            <line x1="10" y1="11" x2="10" y2="17" />
                                                                            <line x1="14" y1="11" x2="14" y2="17" />
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <p className="settingsSignature__dropDown-item-label">Delete</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        ))}

                                    </div>
                                </div>
                            </div>

                        )}


                    {/* testimonial videos */}

                    <div className="company__billet-container">
                        {feedbackVideos.length === 0 ? ("") : (
                            <p className="team__header-title">
                                Testimonial Videos
                            </p>
                        )}

                    </div>
                    <div className="app-billing">
                        <div className="app-billing__plans-container" style={{ marginBottom: "40px" }}>
                            <div className="app-billing__plan-cards-container">
                                {feedbackVideos?.map((video, index) => (
                                    <div className="app-plan" key={video.video_id}>
                                        <div className="app-plan__wrapper">
                                            <div className="app_plan_cover">
                                                <video
                                                    loop
                                                    onMouseOver={(event) => event.target.play()}
                                                    onMouseOut={(event) => event.target.pause()}
                                                >
                                                    <source src={video.url} type="video/mp4"></source>
                                                </video>
                                            </div>
                                            <div className="app-plan__header">
                                                {/* <div className="app-plan__title">{titleCase(video.name.substring(0, 30)) + "..."}</div> */}
                                                <div className="app-plan__title app-plan__desc">Shared by <span>{titleCase(video.name)}</span></div>
                                            </div>
                                            <div className="app-plan__content-section">
                                                <div className="app_stats_group">
                                                    <div className="app-plan__content-column">
                                                        <div className="app-plan__content-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <circle cx="12" cy="12" r="2" />
                                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <span>Visits {video.visits}</span>
                                                    </div>
                                                    <div className="app-plan__content-column">
                                                        <div className="app-plan__content-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M7 4v16l13 -8z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <span>Plays {video.plays}</span>
                                                    </div>
                                                    <div className="app-plan__content-column">
                                                        <div className="app-plan__content-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="3" y1="12" x2="6" y2="12" />
                                                                    <line x1="12" y1="3" x2="12" y2="6" />
                                                                    <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
                                                                    <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
                                                                    <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
                                                                    <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <span>Clicks {video.clicks}</span>
                                                    </div>
                                                    {/* <div className="app-plan__content-column">
                                                        <div className="app-plan__content-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <span>Likes {video.likes}</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="settingsSignature__dropDown-wrapper">
                                            <div className="settingsSignature__dropDown-inner">

                                                <div onClick={(event) => showTestimonyVideoDropdown(event, video.feedback_id)} className="settingsSignature__dropDown-trigger">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="12" cy="12" r="1" />
                                                        <circle cx="12" cy="19" r="1" />
                                                        <circle cx="12" cy="5" r="1" />
                                                    </svg>
                                                </div>

                                                <div style={{ display: 'none' }} id={`video_dropdown_${video.feedback_id}`} className="video_dropdown settingsSignature__dropDown-list">

                                                    <a href={`/create-page-testimony?video_id=${video.feedback_id}`}>
                                                        <div className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                            <div className="settingsSignature__dropDown-item-icon">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                        <line x1="16" y1="5" x2="19" y2="8" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="settingsSignature__dropDown-item-label">Edit</p>
                                                        </div>
                                                    </a>

                                                    <div onClick={() => shareTestimonyVideo(video)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                        <div className="settingsSignature__dropDown-item-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                                                                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <p className="settingsSignature__dropDown-item-label">Share</p>
                                                    </div>

                                                    <a target="_blank" href={video.url} download rel="noreferrer">
                                                        <div className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                            <div className="settingsSignature__dropDown-item-icon">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4" />
                                                                        <line x1="12" y1="13" x2="12" y2="22" />
                                                                        <polyline points="9 19 12 22 15 19" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="settingsSignature__dropDown-item-label">Download</p>
                                                        </div>
                                                    </a>


                                                    <div onClick={() => viewAnaltics(video)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                        <div className="settingsSignature__dropDown-item-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <rect x="10" y="9" width="4" height="12" rx="1.105" />
                                                                    <rect x="17" y="3" width="4" height="18" rx="1.105" />
                                                                    <circle cx="5" cy="19" r="2" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <p className="settingsSignature__dropDown-item-label">Analytics</p>
                                                    </div>

                                                    {integrationData?.filter((app) => { return app.name === "dropbox"; })[0]?.is_connected && (
                                                        <div onClick={() => exportToDropBox(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                            <div className="settingsSignature__dropDown-item-icon">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <rect x="3" y="4" width="18" height="4" rx="2" />
                                                                        <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                                        <line x1="10" y1="12" x2="14" y2="12" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="settingsSignature__dropDown-item-label">Export to Dropbox</p>
                                                        </div>
                                                    )}

                                                    {integrationData?.filter((app) => { return app.name === "google_drive"; })[0]?.is_connected && (
                                                        <div onClick={() => exportToGoogleDrive(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                            <div className="settingsSignature__dropDown-item-icon">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M12 10l-6 10l-3 -5l6 -10z" />
                                                                        <path d="M9 15h12l-3 5h-12" />
                                                                        <path d="M15 15l-6 -10h6l6 10z" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="settingsSignature__dropDown-item-label">Export to GDrive</p>
                                                        </div>
                                                    )}

                                                    {integrationData?.filter((app) => { return app.name === "one_drive"; })[0]?.is_connected && (
                                                        <div onClick={() => exportToOneDrive(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                            <div className="settingsSignature__dropDown-item-icon">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-12" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="settingsSignature__dropDown-item-label">Export to OneDrive</p>
                                                        </div>
                                                    )}

                                                    {integrationData?.filter((app) => { return app.name === "box"; })[0]?.is_connected && (
                                                        <div onClick={() => exportToBox(video.url)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--edit">
                                                            <div className="settingsSignature__dropDown-item-icon">
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <rect x="4" y="4" width="16" height="16" rx="2" />
                                                                        <path d="M4 13h3l3 3h4l3 -3h3" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <p className="settingsSignature__dropDown-item-label">Export to Box</p>
                                                        </div>
                                                    )}

                                                    <div onClick={() => deleteFeedback(video.feedback_id)} className="settingsSignature__dropDown-item settingsSignature__dropDown-item--delete">
                                                        <div className="settingsSignature__dropDown-item-icon">
                                                            <div>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <line x1="4" y1="7" x2="20" y2="7" />
                                                                    <line x1="10" y1="11" x2="10" y2="17" />
                                                                    <line x1="14" y1="11" x2="14" y2="17" />
                                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <p className="settingsSignature__dropDown-item-label">Delete</p>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                ))}

                            </div>
                        </div>
                    </div>

                </div>

                {/* PREVIEW */}
                {analyticsIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "500px" }} className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "20px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">Video Analytics</p>
                                        <div onClick={() => setAnalyticsIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "inherit" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <VideoModalDiv>

                                                            <ImageDiv2>
                                                                <video style={{ borderRadius: "5px", width: "100%", height: "auto" }} src={activeViewAnalyticsVideo.url} controls playsInline></video>
                                                            </ImageDiv2>

                                                            <VideoModalFooter>
                                                                <h1>{titleCase(activeViewAnalyticsVideo.page.title.text)}</h1>
                                                                {/* <p>{titleCase(activeViewAnalyticsVideo.page.description.text)}</p> */}
                                                            </VideoModalFooter>

                                                            <IconDiv2>
                                                                <TextDiv2>
                                                                    <p>Total Visits</p>
                                                                    <div className="analytics_grid">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="2" />
                                                                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                        </svg>
                                                                        <p>{activeViewAnalyticsVideo.visits}</p>
                                                                    </div>
                                                                </TextDiv2>
                                                                <TextDiv2>
                                                                    <p>Total Plays</p>
                                                                    <div className="analytics_grid">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M7 4v16l13 -8z" />
                                                                        </svg>
                                                                        <p>{activeViewAnalyticsVideo.plays}</p>
                                                                    </div>
                                                                </TextDiv2>
                                                                <TextDiv2>
                                                                    <p>Total Clicks</p>
                                                                    <div className="analytics_grid">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <line x1="3" y1="12" x2="6" y2="12" />
                                                                            <line x1="12" y1="3" x2="12" y2="6" />
                                                                            <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
                                                                            <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
                                                                            <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
                                                                            <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                                                                        </svg>
                                                                        <p>{activeViewAnalyticsVideo.clicks}</p>
                                                                    </div>
                                                                </TextDiv2>
                                                                {/* <TextDiv2>
                                                                    <p>Total Likes</p>
                                                                    <div className="analytics_grid">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                                                                        </svg>
                                                                        <p>{activeViewAnalyticsVideo.likes}</p>
                                                                    </div>
                                                                </TextDiv2> */}
                                                            </IconDiv2>
                                                        </VideoModalDiv>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* SHARE */}
                {shareIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "auto" }} className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "20px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">Share Video</p>
                                        <div onClick={() => setShareIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "inherit" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">

                                                        <ShareVideoDiv>
                                                            <ShareDiv>
                                                                <DivLeft>
                                                                    <ImageDiv>
                                                                        <video style={{ objectFit: "cover", borderRadius: "5px", width: "100%", height: "100%" }} src={activeShareVideo.url} autoPlay muted loop playsInline></video>
                                                                        <img src="/images/play-blue.svg" alt="" />
                                                                    </ImageDiv>

                                                                    <div>
                                                                        <ShareLinkDiv>
                                                                            <p>Share link</p>
                                                                            <span>Paste in LinkedIn, Slack or anywhere</span>
                                                                        </ShareLinkDiv>

                                                                        <CopyTextDiv>
                                                                            <input type="text" value={`https://testimonio.io/share/${activeShareVideo.video_id}`} />
                                                                            <button onClick={() => copyTextToClipboard(`https://testimonio.io/share/${`${activeShareVideo.video_id}`}`, "link")}>{linkCopy ? "Copied" : "Copy"}</button>
                                                                        </CopyTextDiv>

                                                                    </div>
                                                                </DivLeft>

                                                                <DivRight>
                                                                    <div style={{ width: "100%", paddingRight: "10px" }}>
                                                                        <TextDiv>
                                                                            <p>Autoresponder Usage</p>
                                                                            <span>Copy & paste video anywhere.</span>
                                                                        </TextDiv>

                                                                        <ButtonDiv>

                                                                            <div className="hide_element">
                                                                                <div ref={animatedVideoCodeRef}>
                                                                                    <a href={`https://testimonio.io/share/${activeShareVideo.video_id}`}>
                                                                                        <div><img width="70%"
                                                                                            src={activeShareVideo.animated_image}
                                                                                            alt="Animated thumbnail for video"
                                                                                        /></div>
                                                                                        <br></br><div>Click to watch video</div>
                                                                                    </a>
                                                                                </div>

                                                                                <div ref={stillVideoCodeRef}>
                                                                                    <a href={`https://testimonio.io/share/${activeShareVideo.video_id}`}>
                                                                                        <div><img width="70%"
                                                                                            src={activeShareVideo.still_image}
                                                                                            alt="Still thumbnail for video"
                                                                                        /></div>
                                                                                        <br></br><div>Click to watch video</div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                            <Button1 onClick={() => copyTextToClipboard(activeShareVideo.email_embed_code, "email")}>{emailCopy ? "Email Code Copied" : "Copy Email Code"}</Button1>
                                                                            <Button1 onClick={() => copyTextToClipboard(activeShareVideo.website_embed_code, "website")}>{websiteCopy ? "Website Code Copied" : "Copy Website Code"}</Button1>
                                                                        </ButtonDiv>
                                                                    </div>

                                                                    <div style={{ width: "100%", paddingLeft: "10px" }}>
                                                                        <TextDiv>
                                                                            <p>Email Usage</p>
                                                                            <span>Copy and paste code in your emails.</span>
                                                                        </TextDiv>

                                                                        <ButtonDiv>
                                                                            <Button1 onClick={() => copyAnimatedImage()}>{animatedCopy ? "Animated Video Copied" : "Copy Animated Video"}</Button1>
                                                                            <Button1 onClick={() => copyStillImage()}>{stillCopy ? "Still Video Copied" : "Copy Still Video"}</Button1>
                                                                        </ButtonDiv>
                                                                    </div>
                                                                </DivRight>

                                                                <br />

                                                                <DivRight>
                                                                    <div style={{ width: "100%", paddingRight: "10px" }}>
                                                                        <TextDiv>
                                                                            <p>Social Media Sharing Links</p>
                                                                            <p></p>
                                                                        </TextDiv>

                                                                        <ButtonDiv>

                                                                            <div className="hide_element">
                                                                                <div ref={animatedVideoCodeRef}>
                                                                                    <a href={`https://testimonio.io/share/${activeShareVideo.video_id}`}>
                                                                                        <div><img width="70%"
                                                                                            src={activeShareVideo.animated_image}
                                                                                            alt="Animated thumbnail for video"
                                                                                        /></div>
                                                                                        <br></br><div>Click to watch video</div>
                                                                                    </a>
                                                                                </div>

                                                                                <div ref={stillVideoCodeRef}>
                                                                                    <a href={`https://testimonio.io/share/${activeShareVideo.video_id}`}>
                                                                                        <div><img width="70%"
                                                                                            src={activeShareVideo.still_image}
                                                                                            alt="Still thumbnail for video"
                                                                                        /></div>
                                                                                        <br></br><div>Click to watch video</div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>


                                                                            <ButtonLink href={`https://www.facebook.com/sharer/sharer.php?u=https://testimonio.io/share/${activeShareVideo.video_id}`} target="_blank" rel="noopener noreferrer"
                                                                            >Facebook Link</ButtonLink>

                                                                            <ButtonLink href={`https://www.linkedin.com/sharing/share-offsite/?url=https://testimonio.io/share/${activeShareVideo.video_id}`} target="_blank" rel="noopener noreferrer"
                                                                            >Linkedin Link</ButtonLink>
                                                                        </ButtonDiv>
                                                                    </div>

                                                                    <div style={{ width: "100%", paddingLeft: "10px" }}>
                                                                        <TextDiv>
                                                                            <p></p>
                                                                            <span><br /><br /><p></p></span>
                                                                        </TextDiv>

                                                                        <ButtonLink href={`https://api.whatsapp.com/send?text=https://testimonio.io/share/${activeShareVideo.video_id}`} target="_blank" rel="noopener noreferrer"
                                                                        >Whatsapp Link</ButtonLink>

                                                                        <ButtonLink href={`https://twitter.com/intent/tweet?url=https://testimonio.io/share/${activeShareVideo.video_id}`} target="_blank" rel="noopener noreferrer"
                                                                        >Twitter Link</ButtonLink>
                                                                    </div>
                                                                </DivRight>


                                                            </ShareDiv>
                                                        </ShareVideoDiv>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

                {/* SHARE TESTIMONY */}
                {feedbackIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "auto" }} className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "20px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">Share Testmonial Video</p>
                                        <div onClick={() => setFeedbackIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "inherit" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">

                                                        <ShareVideoDiv>
                                                            <ShareDiv>
                                                                <DivLeft>
                                                                    <ImageDiv>
                                                                        <video style={{ objectFit: "cover", borderRadius: "5px", width: "100%", height: "100%" }} src={activeFeedbackVideo.url} autoPlay muted loop playsInline></video>
                                                                        <img src="/images/play-blue.svg" alt="" />
                                                                    </ImageDiv>

                                                                    <div>
                                                                        <ShareLinkDiv>
                                                                            <p>Share link</p>
                                                                            <span>Paste in LinkedIn, Slack or anywhere</span>
                                                                        </ShareLinkDiv>

                                                                        <CopyTextDiv>
                                                                            <input type="text" value={`https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`} />
                                                                            <button onClick={() => copyTextToClipboard(`https://testimonio.io/share-testimony/${`${activeFeedbackVideo.feedback_id}`}`, "link")}>{linkCopy ? "Copied" : "Copy"}</button>
                                                                        </CopyTextDiv>

                                                                    </div>
                                                                </DivLeft>


                                                                <DivRight>
                                                                    <div style={{ width: "100%", paddingRight: "10px" }}>
                                                                        <TextDiv>
                                                                            <p>Autoresponder Usage</p>
                                                                            <span>Copy & paste video anywhere.</span>
                                                                        </TextDiv>

                                                                        <ButtonDiv>

                                                                            <div className="hide_element">
                                                                                <div ref={animatedVideoCodeRef}>
                                                                                    <a href={`https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`}>
                                                                                        <div><img width="70%"
                                                                                            src={activeFeedbackVideo.animated_image}
                                                                                            alt="Animated thumbnail for video"
                                                                                        /></div>
                                                                                        <br></br><div>Click to watch video</div>
                                                                                    </a>
                                                                                </div>

                                                                                <div ref={stillVideoCodeRef}>
                                                                                    <a href={`https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`}>
                                                                                        <div><img width="70%"
                                                                                            src={activeFeedbackVideo.still_image}
                                                                                            alt="Still thumbnail for video"
                                                                                        /></div>
                                                                                        <br></br><div>Click to watch video</div>
                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                            <Button1 onClick={() => copyTextToClipboard(activeFeedbackVideo.email_embed_code, "email")}>{emailCopy ? "Email Code Copied" : "Copy Email Code"}</Button1>
                                                                            <Button1 onClick={() => copyTextToClipboard(activeFeedbackVideo.website_embed_code, "website")}>{websiteCopy ? "Website Code Copied" : "Copy Website Code"}</Button1>
                                                                        </ButtonDiv>
                                                                    </div>

                                                                    <div style={{ width: "100%", paddingLeft: "10px" }}>
                                                                        <TextDiv>
                                                                            <p>Email Usage</p>
                                                                            <span>Copy and paste code in your emails.</span>
                                                                        </TextDiv>

                                                                        <ButtonDiv>
                                                                            <Button1 onClick={() => copyAnimatedImage()}>{animatedCopy ? "Animated Video Copied" : "Copy Animated Video"}</Button1>
                                                                            <Button1 onClick={() => copyStillImage()}>{stillCopy ? "Still Video Copied" : "Copy Still Video"}</Button1>
                                                                        </ButtonDiv>
                                                                    </div>
                                                                </DivRight>


                                                                <br />

                                                                <DivRight>
                                                                    <div style={{ width: "100%", paddingRight: "10px" }}>
                                                                        <TextDiv>
                                                                            <p>Social Media Sharing Links</p>
                                                                            <p></p>
                                                                        </TextDiv>

                                                                        <ButtonDiv>



                                                                            <ButtonLink href={`https://www.facebook.com/sharer/sharer.php?u=https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`} target="_blank" rel="noopener noreferrer"
                                                                            >Facebook Link</ButtonLink>

                                                                            <ButtonLink href={`https://www.linkedin.com/sharing/share-offsite/?url=https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`} target="_blank" rel="noopener noreferrer"
                                                                            >Linkedin Link</ButtonLink>

                                                                        </ButtonDiv>
                                                                    </div>

                                                                    <div style={{ width: "100%", paddingLeft: "10px" }}>
                                                                        <TextDiv>
                                                                            <p></p>
                                                                            <span><br /><br /><p></p></span>
                                                                        </TextDiv>

                                                                        <ButtonLink href={`https://api.whatsapp.com/send?text=https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`} target="_blank" rel="noopener noreferrer"
                                                                        >Whatsapp Link</ButtonLink>
                                                                        <ButtonLink href={`https://twitter.com/intent/tweet?url=https://testimonio.io/share-testimony/${activeFeedbackVideo.feedback_id}`} target="_blank" rel="noopener noreferrer"
                                                                        >Twitter Link</ButtonLink>
                                                                    </div>
                                                                </DivRight>


                                                            </ShareDiv>
                                                        </ShareVideoDiv>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )}

            </div>
        </>
    );
};