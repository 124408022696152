export const CREATE_FEEDBACK_REQUEST = "CREATE_FEEDBACK_REQUEST";
export const CREATE_FEEDBACK_SUCCESS = "CREATE_FEEDBACK_SUCCESS";
export const CREATE_FEEDBACK_FAIL = "CREATE_FEEDBACK_FAIL";

export const GET_FEEDBACK_REQUEST = "GET_FEEDBACK_REQUEST";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAIL = "GET_FEEDBACK_FAIL";

export const DELETE_FEEDBACK_REQUEST = "DELETE_FEEDBACK_REQUEST";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAIL = "DELETE_FEEDBACK_FAIL";