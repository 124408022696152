import React from "react";
import {
  MainContainer,
  Div,
  Container,
  LoginTitle,
  TextWrapper,
  GoogleIcon,
  Button,
  Bottom,
  Select,
  SelectText,
  Contain,
  Circle,
} from "./SuccessStyle";

const Success = () => {
  return (
    <>

    <Div>
      <div>
      <MainContainer>
      <Container>
        <Contain>
          <Circle>
            <GoogleIcon src="/images/Vector.svg" />
          </Circle>
        </Contain>

        <TextWrapper>
          <LoginTitle>Your password has been reset</LoginTitle>
          <span>
            Don’t worry, a temporary password has been sent to your provided email address and you’ll be able to change it to something else when logged in.
          </span>
        </TextWrapper>

        <Button to="login">Goto sign in</Button>
      </Container>

      </MainContainer>
      </div>
    </Div>
     

      <Bottom>
        <SelectText>Help</SelectText>
        <Select name="help" id="help" className="help">
          <option>English</option>
          <option>French</option>
          <option>German</option>
          <option>Spanish</option>
        </Select>
      </Bottom>
    </>
  );
};

export default Success;
