import React from "react";
import "./index.css";
import Recorder from "./Recorder";

export default function Index() {

    // const [createdOnIsVisible, setCreatedOnIsVisible] = React.useState(false)
    // const [createdByIsVisible, setCreatedByIsVisible] = React.useState(false)
    // const [dropdownActiveState, setDropdownActiveState] = React.useState(false)
    
    return (
        <div className="main-layout__container">
            <div className="team">
                {/* EMPTY STATE */}
                <div className="documents__empty-table_create_video">
                    <div className="empty-table empty-table_create_video">
                        <div className="empty-table__wrapper_create_video">
                            <Recorder />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};