import React from "react";
import "./index.css";
import CollectFeedbackBody from "../components/CollectFeedbackBody";



const CollectFeedback = () => {
  return (
    <div className="main-layout">
          <div className="main-layout__content" style={{maxWidth: "100%", minHeight: "100%", padding: "0px", border: "none"}}>
            <CollectFeedbackBody />
          </div>
    </div>
  );
};

export default CollectFeedback;