import React from "react";
import "./index.css";
import EmbedTestimonyBody from "../components/EmbedTestimonyBody";



const EmbedTestimony = () => {
  return (
    <div className="main-layout">
          <div className="main-layout__content" style={{overflow: "hidden", maxWidth: "100%", minHeight: "100%", padding: "0px", border: "none"}}>
            <EmbedTestimonyBody />
          </div>
    </div>
  );
};

export default EmbedTestimony;