import React from 'react';
import ReactPlayer from 'react-player';
// import { Div, Circle, MainContainer, Container, Wrapper } from "./PersonaRequestStyle";
// import { titleCase, getInitials } from "../../functions";

const Request = (props) => {

    const submitPersona = (event) => {
        event.preventDefault();
        props.setActiveScreen("persona");
    };

    const testimonyData = JSON.parse(props.testimonyData);
    console.log(testimonyData);

    return (
        <>
            <div className="testimony_page">
                <div className="testimony_page_header">
                    <div className="testimony_page_header_title">
                        <h1>{testimonyData?.product_title}</h1>

                        <div className="testimony_body_image">
                            <img src={testimonyData?.product_image} alt="product_image" />
                        </div>

                        <p>{testimonyData?.product_description}</p>
                    </div>

                    {testimonyData?.product_video_url && (
                        <div className="feedback_video">
                            <ReactPlayer
                                url={testimonyData?.product_video_url}
                                controls={true}
                            />
                        </div>
                    )}
                    <div className="testimony_btns">
                        <button className='product_link_btn'>
                            <a href={testimonyData?.product_link} target="_blank" rel='noreferrer'>
                                Visit Product Page
                            </a>
                        </button>
                    </div>


                </div>

                {/* <div className="testimony_btns">
                    <button className='product_link_btn'>
                        <a href={testimonyData?.product_link} target="_blank" rel='noreferrer'>
                            Visit Product Page
                        </a>
                    </button>
                    <button className='testimonial_btns' onClick={submitPersona}>
                        Click Here To Leave Your Testimonial!
                    </button>
                </div> */}
                {testimonyData?.reward_title && (
                    <div className="reward_info">
                        <div className="reward_info_title">
                            <h3 style={{ display: "inline-block" }}>{testimonyData?.reward_title}</h3>
                        </div>
                        <div className="reward_img">
                            <img src={testimonyData?.reward_image} alt="reward_image" />
                        </div>
                        <div className="reward_dec">
                            <p>{testimonyData?.reward_dec}</p>
                        </div>
                    </div>
                )}


                <div className="testimonial_btn">
                    <button onClick={submitPersona}>
                        Click Here To Leave Your Testimonial!
                    </button>
                </div>

            </div>
        </>
    );
};

export default Request;
