import{
    CREATE_TEAM_REQUEST,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_FAIL,
    GET_TEAM_REQUEST,
    GET_TEAM_SUCCESS,
    GET_TEAM_FAIL,
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAIL,
    EDIT_TEAM_REQUEST,
    EDIT_TEAM_SUCCESS,
    EDIT_TEAM_FAIL,
} from "./TeamTypes";

export const createTeamReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_TEAM_REQUEST:
        return { loading: true };
      case CREATE_TEAM_SUCCESS:
        return { loading: false, createTeamData: action.payload, success: true, message: action.payload.message };
      case CREATE_TEAM_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const getTeamReducer = (state = { teamData: [] }, action) => {
    switch (action.type) {
      case GET_TEAM_REQUEST:
        return { ...state, loading: true };
      case GET_TEAM_SUCCESS:
        return { loading: false, teamData: action.payload.data.teams.reverse(), message: action.payload.message, success: true };
      case GET_TEAM_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const deleteTeamReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_TEAM_REQUEST:
        return { loading: true };
      case DELETE_TEAM_SUCCESS:
        return { loading: false, deleteTeamData: action.payload, success: true, message: action.payload.message };
      case DELETE_TEAM_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

export const editTeamReducer = (state = {}, action) => {
    switch (action.type) {
      case EDIT_TEAM_REQUEST:
        return { loading: true };
      case EDIT_TEAM_SUCCESS:
        return { loading: false, editTeamData: action.payload, success: true, message: action.payload.message };
      case EDIT_TEAM_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }