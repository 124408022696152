import React from "react";
import Loader from "../loader/Loader";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import axios from "axios";
import "./index.css";

export default function Index() {

    const [createdOnIsVisible, setCreatedOnIsVisible] = React.useState(false)
    const [templateData, setTemplateData] = React.useState(null)

    React.useEffect(() => {

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}templates/get_templates`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setTemplateData(response.data.data.templates.reverse())
        }).catch(function (error) {
            setTemplateData([])
        });

    }, [])

    if(templateData === null){
        return <Loader />
    }

    return (
        <div className="main-layout__container">
            <div className="team">
                <div className="team__header-container">
                    <div className="team__header-title-group">
                        <div className="company__billet-container">
                            <p className="team__header-title">Templates</p>
                        </div>
                        <div className="team__header-description">Use awesome videos templates pre-recorded by business owners like you, <br></br> or designed videos by experienced desingers and video producers.</div>
                    </div>
                    {/* <div className="team__button">
                        <button type="button" className="button_with_add_icon button button--primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-text" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                <line x1="9" y1="9" x2="10" y2="9" />
                                <line x1="9" y1="13" x2="15" y2="13" />
                                <line x1="9" y1="17" x2="15" y2="17" />
                            </svg>
                            <p className="button__text">Request A Template</p>
                        </button>
                    </div> */}
                </div>

                <div style={{justifyContent: "flex-end"}} className="tableFilters__wrapper tableFilters__itemGroup">
                    <div className="tableFilters__itemGroup">
                        <div className="tableFilters__item tableFilters__item--medium">
                            <div className="uiSelect__wrapper">
                                <div className="uiSelect__select">
                                    <div onClick={() => setCreatedOnIsVisible((createdOnIsVisible) => !createdOnIsVisible)} className="dropdown_button_cover">
                                        <div className="DayPickerContainer__icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-certificate" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                    <path d="M5 8v-3a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5" />
                                                    <circle cx="6" cy="14" r="3" />
                                                    <path d="M4.5 17l-1.5 5l3 -1.5l3 1.5l-1.5 -5" />
                                                </svg>
                                            </div>
                                        </div>  
                                        <div className="uiSelect__select-inner">
                                            <div className="uiSelect__select-value-wrapper">
                                                <p className="uiSelect__select-placeholder">Category: <span>Business</span></p>
                                            </div>
                                        </div>
                                        <div className="uiSelect__select-arrow">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <polyline points="6 9 12 15 18 9" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {createdOnIsVisible && (
                                        <div className="uiSelect__content-wrapper">
                                            <div className="uiSelect__search-list">
                                                <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                <div className="uiSelect__search-item"><p className="uiSelect__select-row">Business</p></div>
                                                <div className="uiSelect__search-item"><p className="uiSelect__select-row">Fashion</p></div>
                                                <div className="uiSelect__search-item"><p className="uiSelect__select-row">Entertainment</p></div>
                                                <div className="uiSelect__search-item"><p className="uiSelect__select-row">Agriculture</p></div>
                                                <div className="uiSelect__search-item"><p className="uiSelect__select-row">Catering</p></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {templateData.length > 0? (
                    // TEMPLATE LIST
                    <div className="app-billing">
                        <div className="app-billing__plans-container">
                            <div className="app-billing__plan-cards-container">
                                
                                <div className="app-plan">
                                    <div className="app-plan__wrapper">
                                        <div className="app_plan_cover">
                                            <video
                                                loop
                                                onMouseOver={(event) => event.target.play()}
                                                onMouseOut={(event) => event.target.pause()}
                                            >
                                                <source src="https://www.w3schools.com/tags/movie.mp4" type="video/mp4"></source>
                                            </video>
                                        </div>
                                        <div className="app-plan__header">
                                            <div className="app-plan__title">How to sell on social media</div>
                                            <div className="app-plan__title app-plan__desc">Created by <span>Maven Harry</span></div>
                                        </div>
                                        <div className="app-plan__button-wrapper">
                                            <button type="button" className="button app-plan__upgrade-button button--primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                    <line x1="12" y1="5" x2="12" y2="19" />
                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                </svg>
                                                <p className="button__text">Use This Template</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ) : (
                    // EMPTY STATE
                    <div className="documents__empty-table">
                        <div className="empty-table">
                            <div className="empty-table__wrapper">
                                <div className="empty-table__icon empty-table__icon--team">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <rect x="4" y="4" width="6" height="6" rx="1" />
                                                <rect x="14" y="4" width="6" height="6" rx="1" />
                                                <rect x="4" y="14" width="6" height="6" rx="1" />
                                                <rect x="14" y="14" width="6" height="6" rx="1" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div style={{"marginTop": "10px"}}className="empty-table__header">Templates are coming soon!</div>
                                <div className="empty-table__description">Create video from ready made themes.</div>
                                <div className="empty-table__button">
                                    {/* <div className="team__button">
                                        <button type="button" className="button_with_add_icon button button--primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-text" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                <line x1="9" y1="9" x2="10" y2="9" />
                                                <line x1="9" y1="13" x2="15" y2="13" />
                                                <line x1="9" y1="17" x2="15" y2="17" />
                                            </svg>
                                            <p className="button__text">Request A Template</p>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};