import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import GlobalStyles from "./Global";
import Login from "./components/auth/login/Login";
import SignUp from "./components/auth/signup/SignUp";
import Success from "./components/auth/success/Success";
import ResetPassword from "./components/auth/resetPassword/ResetPassword";
import ConfirmAccount from "./components/auth/confirmAccount/ConfirmAccount";
import CreateNewPassword from "./components/auth/createNewPassword/CreateNewPassword";
import CreateWorkSpace from "./components/auth/createWorkSpace/CreateWorkSpace";
import ChooseWorkSpace from "./components/auth/chooseWorkSpace/ChooseWorkSpace";
import Dashboard from "./screens/Dashboard";
import Videos from "./screens/Videos";
import Billing from "./screens/Billing";
import Integrations from "./screens/Integrations";
import Team from "./screens/Team";
import Home from "./screens/Home";
import Feedbacks from "./screens/Feedbacks";
import Testimony from "./screens/Testimony";
import Agency from "./screens/Agency";
import Reseller from "./screens/Reseller";
import Profile from "./screens/Profile";
import Workspaces from "./screens/Workspaces";
import Branding from "./screens/Branding";
import CreateVideo from "./screens/CreateVideo";
import CreatePage from "./screens/CreatePage";
import CreateTestimonyPage from "./screens/CreateTestimonyPage";
import CollectFeedback from "./screens/CollectFeedback";
import ShareVideo from "./screens/ShareVideo";
import ShareVideoTestimony from "./screens/ShareVideoTestimony";
import Embed from "./screens/Embed";
import EmbedTestimony from "./screens/EmbedTestimony";
import IntegrationDone from "./screens/IntegrationDone";
import LandingPage from "./screens/LandingPage";
import Pricing from "./components/landingPageBody/Pricing";
import Contact from "./components/landingPageBody/Contact";
import Features from "./components/landingPageBody/Features";
const theme = {
  colors: {
    white: "#fff",
    dark: "#323B43",
    grey: "#999999",
    footer: "#003333",
    lightGrey: "#fafafa",
  },
  mobile: '768px',
};

function App() {
  return (
    <>
      <Router>
        <Switch>
          <ThemeProvider theme={theme}>
          <GlobalStyles />
            <Route exact path="/" component={LandingPage} />
            <Route path="/landing" component={Home} exact />
            <Route path="/pricing" component={Pricing} exact />
            <Route path="/contact" component={Contact} exact />
            <Route path="/features" component={Features} exact />
            <Route path="/login" component={Login} exact />
            <Route path="/signup" component={SignUp} exact />
            <Route path="/success" component={Success} exact />
            <Route path="/reset-password" component={ResetPassword} exact />
            <Route path="/confirm-account/:userEmail" component={ConfirmAccount} exact />
            <Route path="/create-new-password" component={CreateNewPassword} exact />
            <Route path="/create-work-space" component={CreateWorkSpace} exact />
            <Route path="/choose-work-space" component={ChooseWorkSpace} exact />
            <Route path="/dashboard" component={Dashboard} exact />
            <Route path="/videos" component={Videos} exact />
            <Route path="/billing" component={Billing} exact />
            <Route path="/integrations" component={Integrations} exact />
            <Route path="/team" component={Team} exact />
            <Route path="/testimony/:testimonyId" component={Feedbacks} exact />
            <Route path="/testimonys" component={Testimony} exact />
            <Route path="/dfy-gency" component={Agency} exact />
            <Route path="/reseller" component={Reseller} exact />
            <Route path="/profile" component={Profile} exact />
            <Route path="/workspaces" component={Workspaces} exact />
            <Route path="/branding" component={Branding} exact />
            <Route path="/create-video" component={CreateVideo} exact />
            <Route path="/create-page" component={CreatePage} exact />
            <Route path="/create-page-testimony" component={CreateTestimonyPage} exact />
            <Route path="/feedback/:tokens/:workspaceId/:testimonyId" component={CollectFeedback} exact />
            <Route path="/share/:videoId" component={ShareVideo} exact />
            <Route path="/share-testimony/:testimoyId" component={ShareVideoTestimony} exact />
            <Route path="/embed/:videoId" component={Embed} exact />
            <Route path="/embed-testimony/:testimoyId" component={EmbedTestimony} exact />
            <Route path="/integration_done" component={IntegrationDone} exact />
          </ThemeProvider>
        </Switch>
      </Router>
    </>
  );
}

// stopReportingRuntimeErrors();
export default App;
