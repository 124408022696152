import React from "react";
import "./index.css";
import DashboardNavigation from "../components/DashboardNavigation";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardFooter from "../components/DashboardFooter";
import WorkspaceBody from "../components/WorkspaceBody";

const Workspace = () => {
  return (
    <div className="main-layout">
          <DashboardNavigation />
          <div className="main-layout__content">
            <DashboardSidebar />
            <WorkspaceBody />
          </div>
          <DashboardFooter />
    </div>
  );
};

export default Workspace;
