export const CREATE_WORKSPACE_REQUEST = 'CREATE_WORKSPACE_REQUEST'
export const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS'
export const CREATE_WORKSPACE_FAIL = 'CREATE_WORKSPACE_FAIL'

export const GET_WORKSPACE_REQUEST = 'GET_WORKSPACE_REQUEST'
export const GET_WORKSPACE_SUCCESS = 'GET_WORKSPACE_SUCCESS'
export const GET_WORKSPACE_FAIL = 'GET_WORKSPACE_FAIL'

export const EDIT_WORKSPACE_REQUEST = 'EDIT_WORKSPACE_REQUEST'
export const EDIT_WORKSPACE_SUCCESS = 'EDIT_WORKSPACE_SUCCESS'
export const EDIT_WORKSPACE_FAIL = 'EDIT_WORKSPACE_FAIL'
export const WORKSPACE_RESET = 'WORKSPACE_RESET'

export const DELETE_WORKSPACE_REQUEST = 'DELETE_WORKSPACE_REQUEST'
export const DELETE_WORKSPACE_SUCCESS = 'DELETE_WORKSPACE_SUCCESS'
export const DELETE_WORKSPACE_FAIL = 'DELETE_WORKSPACE_FAIL'

export const SAVE_BRANDS_REQUEST = 'SAVE_BRANDS_REQUEST'
export const SAVE_BRANDS_SUCCESS = 'SAVE_BRANDS_SUCCESS'
export const SAVE_BRANDS_FAIL = 'SAVE_BRANDS_FAIL'

export const UPLOAD_BRAND_LOGO_REQUEST = 'UPLOAD_BRAND_LOGO_REQUEST'
export const UPLOAD_BRAND_LOGO_SUCCESS = 'UPLOAD_BRAND_LOGO_SUCCESS'
export const UPLOAD_BRAND_LOGO_FAIL = 'UPLOAD_BRAND_LOGO_FAIL'

export const GET_WORKSPACE_BY_ID_REQUEST = 'GET_WORKSPACE_BY_ID_REQUEST'
export const GET_WORKSPACE_BY_ID_SUCCESS = 'GET_WORKSPACE_BY_ID_SUCCESS'
export const GET_WORKSPACE_BY_ID_FAIL = 'GET_WORKSPACE_BY_ID_FAIL'
