/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  LoginContainer,
  LoginTitle,
  LoginSubtitle,
  TextWrapper,
  LoginForm,
  ButtonText,
  GoogleButton,
  Image,
  Image2,
  Image3,
  LineDiv,
  Line,
  LineText,
  LoginInput,
  LoginButton,
  Text,
  Bottom,
  Select,
  SelectText,
  FirstFormDiv,
  InputDiv,
  IconDiv,
  Icon,
  LinkText,
} from "./SignUpStyle";
import { register } from "../../../redux/auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {bounce} from "../../../toast"
toast.configure();


const SignUp = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, message, registerData } = userRegister;

  useEffect(() => {
    if (registerData) {
      toast.success(message, { transition: bounce });
      setTimeout(() => {
        // document.location.href = `confirm-account/${registerData.data.email}`;
        document.location.href = `login`;
      }, 300);
    } else if (error) {
      toast.error(error, { transition: bounce });
    }
  }, [registerData, message, error]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(register(name, email, password));
  };

  return (
    <>
      <LoginContainer>
        <TextWrapper>
          <LoginTitle>Create an account</LoginTitle>
          <LoginSubtitle>
            Let’s get started and create your account
          </LoginSubtitle>
        </TextWrapper>
        {loading && <Loader />}
        <LoginForm onSubmit={submitHandler}>
          {/* <GoogleButton>
            <ButtonText>
              <Image src="/images/google-login.svg" />
              Sign Up with Google
            </ButtonText>
          </GoogleButton> */}

          {/* <LineDiv>
            {" "}
            <Line /> <LineText>Or sign in using Email</LineText> <Line />{" "}
          </LineDiv> */}

          <FirstFormDiv>
            <IconDiv>
              <Image2 src="/images/profile.svg" />
            </IconDiv>
            <InputDiv>
              <LoginInput
                placeholder="Full name"
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputDiv>
          </FirstFormDiv>

          <FirstFormDiv>
            <IconDiv>
              <Image3 src="/images/email2.svg" />
            </IconDiv>
            <InputDiv>
              <LoginInput
                placeholder="Email address"
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputDiv>
          </FirstFormDiv>

          <FirstFormDiv>
            <IconDiv>
              <Image3 src="/images/lock.svg" />
            </IconDiv>

            <Icon onClick={togglePasswordVisiblity}>
              <Image3
                {...(passwordShown
                  ? { src: "/images/eye-open.svg" }
                  : { src: "/images/eye.svg" })}
              />
            </Icon>

            <InputDiv>
              <LoginInput
                placeholder="Password"
                name="password"
                id="password"
                type={passwordShown ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputDiv>
          </FirstFormDiv>

          {loading ? (
            <LoginButton>Loading...</LoginButton>
          ) : (
            <LoginButton>Sign up</LoginButton>
          )}

          <Text>
            Already have an account? <LinkText to="/login">Sign In</LinkText>
          </Text>
        </LoginForm>
      </LoginContainer>

      {/* <Bottom>
        <SelectText>Help</SelectText>
        <Select name="help" id="help" className="help">
          <option>English</option>
          <option>French</option>
          <option>German</option>
          <option>Spanish</option>
        </Select>
      </Bottom> */}
    </>
  );
};

export default SignUp;
