/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import React, { useState, useEffect } from "react";
import {
  verification,
  resendVerifyCode,
} from "../../../redux/auth/AuthActions";
import { useParams } from "react-router-dom";
import {
  MainContainer,
  Div,
  Container,
  Title,
  TextWrapper,
  LoginForm,
  Icon,
  LoginInput,
  Button,
  SmallText,
  BottomSelect,
  Select,
  BottomText,
  FirstFormDiv,
  InputDiv,
  IconDiv,
  Subtitle,
  SmallTextDiv,
  LinkText,
} from "./ConfirmAccountStyle";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../../toast";
toast.configure();

const ConfirmAccount = () => {
  let { userEmail } = useParams();
  const dispatch = useDispatch();

  const resendCode = useSelector((state) => state.resendCode);
  const { code, success:resendSuccess, error:errorResend } = resendCode;

  const verify = useSelector((state) => state.verify);
  const { loading, error, message, verifyData } = verify;

  const [email, setEmail] = useState(userEmail);
  const [verification_code, setVerificationCode] = useState("");

  useEffect(() => {
    if (verifyData) {
      toast.success(message, { transition: bounce });
      setTimeout(() => {
        document.location.href = "/login";
      }, 500);
    } else {
      if (error) {
        toast.error(error, { transition: bounce });
      }
    }
  }, [verifyData, message, error]);

  useEffect(() => {
    if (resendSuccess) {
      toast.success("A token has been sent to your mail ",{ transition: bounce });
    } else {
      if (errorResend) {
        toast.error(errorResend, { transition: bounce });
      }
    }
  }, [resendSuccess, errorResend]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(verification(email, verification_code));
  };

  const resendCodeHandler = (e) => {
    e.preventDefault();
    dispatch(resendVerifyCode(email));
  };
  return (
    <>
      <Div>
        <div>
          <MainContainer>
            <Container>
              <TextWrapper>
                <Title>Verify your account</Title>
              </TextWrapper>
              {loading && <Loader />}
              <LoginForm onSubmit={submitHandler}>
                <FirstFormDiv>
                  <IconDiv>
                    <Icon src="/images/lock.svg" />
                  </IconDiv>

                  <InputDiv>
                    <LoginInput
                      placeholder="Enter code"
                      name="resetCode"
                      id="resetCode"
                      value={verification_code}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                  </InputDiv>
                </FirstFormDiv>

                <Button>Continue</Button>

                <Subtitle>
                  A code has been sent to your email. Enter that code here.
                </Subtitle>

                <button
                  style={{
                    marginTop: "0px",
                    border: "none",
                    background: "none",
                    color: "#aa2080",
                    fontSize: "14px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={resendCodeHandler}
                >
                  Resend code
                </button>
              </LoginForm>
            </Container>

            <SmallTextDiv>
              {/* <SmallText>
                Don’t have an account? <LinkText to="/signup">Sign up</LinkText>
              </SmallText> */}
            </SmallTextDiv>
          </MainContainer>
        </div>
      </Div>

      {/* <BottomSelect>
        <BottomText>Help</BottomText>
        <Select name="help" id="help" className="help">
          <option>English</option>
          <option>French</option>
          <option>German</option>
          <option>Spanish</option>
        </Select>
      </BottomSelect> */}
    </>
  );
};

export default ConfirmAccount;
