import {
    CREATE_WORKSPACE_REQUEST,
    CREATE_WORKSPACE_SUCCESS,
    CREATE_WORKSPACE_FAIL,
    GET_WORKSPACE_REQUEST,
    GET_WORKSPACE_SUCCESS,
    GET_WORKSPACE_FAIL,
    EDIT_WORKSPACE_REQUEST,
    EDIT_WORKSPACE_SUCCESS,
    EDIT_WORKSPACE_FAIL,
    DELETE_WORKSPACE_REQUEST,
    DELETE_WORKSPACE_SUCCESS,
    DELETE_WORKSPACE_FAIL,
    SAVE_BRANDS_REQUEST,
    SAVE_BRANDS_SUCCESS,
    SAVE_BRANDS_FAIL,
    UPLOAD_BRAND_LOGO_REQUEST,
    UPLOAD_BRAND_LOGO_SUCCESS,
    UPLOAD_BRAND_LOGO_FAIL,
    GET_WORKSPACE_BY_ID_REQUEST,
    GET_WORKSPACE_BY_ID_SUCCESS,
    GET_WORKSPACE_BY_ID_FAIL,
    WORKSPACE_RESET,
  } from "./WorkSpaceType";


export const createWorkspace = (state = {}, action) => {
      switch (action.type) {
        case CREATE_WORKSPACE_REQUEST:
          return { loading: true };
        case CREATE_WORKSPACE_SUCCESS:
          return { loading: false, workspaceData: action.payload, success: true, message: action.payload.message };
        case CREATE_WORKSPACE_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

export const getWorkspace = (state = [], action) => {
      switch (action.type) {
        case GET_WORKSPACE_REQUEST:
          return { loading: true };
        case GET_WORKSPACE_SUCCESS:
          return { loading: false, getWorkspaceData: action.payload.data.workspaces.reverse(), success: true, message: action.payload.message };
        case GET_WORKSPACE_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

export const editWorkspace = (state = {}, action) => {
      switch (action.type) {
        case EDIT_WORKSPACE_REQUEST:
          return { loading: true };
        case EDIT_WORKSPACE_SUCCESS:
          return { loading: false, editWorkspaceData: action.payload, success: true, message: action.payload.message };
        case EDIT_WORKSPACE_FAIL:
          return { loading: false, error: action.payload };
          case WORKSPACE_RESET:
          return {};
        default:
          return state;
      }
    }

export const deleteWorkspace = (state = {}, action) => {
      switch (action.type) {
        case DELETE_WORKSPACE_REQUEST:
          return { loading: true };
        case DELETE_WORKSPACE_SUCCESS:
          return { loading: false, deleteWorkspaceData: action.payload, success: true, message: action.payload.message };
        case DELETE_WORKSPACE_FAIL:
          return { loading: false, error: action.payload };
          case WORKSPACE_RESET:
          return {};
        default:
          return state;
      }
    }

export const saveBrands = (state = {}, action) => {
      switch (action.type) {
        case SAVE_BRANDS_REQUEST:
          return { loading: true };
        case SAVE_BRANDS_SUCCESS:
          return { loading: false, saveBrandsData: action.payload, success: true, message: action.payload.message };
        case SAVE_BRANDS_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

export const uploadBrandLogo = (state = {}, action) => {
      switch (action.type) {
        case UPLOAD_BRAND_LOGO_REQUEST:
          return { loading: true };
        case UPLOAD_BRAND_LOGO_SUCCESS:
          return { loading: false, uploadBrandLogoData: action.payload, success: true, message: action.payload.message };
        case UPLOAD_BRAND_LOGO_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    }

export const getWorkspaceById = (state = {}, action) => {
      switch (action.type) {
        case GET_WORKSPACE_BY_ID_REQUEST:
          return { loading: true };
        case GET_WORKSPACE_BY_ID_SUCCESS:
          return { loading: false, workSpaceInfo: action.payload.data.workspaces, success: true, message: action.payload.message };
        case GET_WORKSPACE_BY_ID_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    }