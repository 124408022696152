import styled from "styled-components";
import { Link } from "react-router-dom";

export const Header = styled.h2`
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        color: #323B43;
        margin-top: 2rem;
        margin-bottom: 2rem;
`;

export const NewVideoDiv = styled.div`
        width: 797px;
        height: 398.5px;
        background-color: #fafafa;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        

        p{
        font-style: normal;
        font-weight: 600;
        font-size: 20.5893px;
        color: #323B43;
        position: absolute;
        left: 420px;
        top: 190px;
        }
`;

export const Div = styled.div`
            display: flex;
            flex-direction: row;
            padding: 0px;
            width: 685.35px;
            margin-left: -1.5rem;
            margin-bottom: 2rem;
            margin-top: 5rem;

`;


export const ImageDiv = styled.div`
            width: 186.33px;
            height: 128.68px;
            background: #F5FAFF;
            border-radius: 6.17679px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 2rem;
            flex-direction: column;
            cursor: pointer;

            img{
                width: 30.88px;
                height: 30.88px;
                margin-bottom: 10px;
                cursor: pointer;
            }

            span{
            font-style: normal;
            font-weight: 600;
            font-size: 18.5304px;
            color: #0c54f4;
            }

`;

export const TabDiv = styled.div`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            width: 701.02px;
`;

export const Tab1 = styled.div`
        width: 394.29px;
        height: 89.56px;
        background: none;
        border-radius: 6.17679px;
        border: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: row;
        margin-right: 2rem;

        img{
        width: 56.62px;
        height: 56.62px;
        border-radius: 6.17679px;
        margin-right: 20px;
        }

        span{
        font-style: normal;
        font-weight: 500;
        font-size: 16.4714px;
        color: #323B43;
        width: 274.87px;
        }
`;



export const Tab2 = styled.div`
        width: 277.01px;
        height: 89.91px;
        background: none;
        border-radius: 6.17679px;
        border: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: row;

        span{
        color: #323B43;
        width: 234.98px;
        font-style: normal;
        font-weight: 500;
        font-size: 16.4714px;
        }

`;


export const LinkWrap = styled(Link)`
        color: #0c54f4;
        width: 234.98px;
        font-style: normal;
        font-weight: 500;
        font-size: 16.4714px;
        text-decoration: none;
        margin-left: 3px;

`;

export const Footerdiv = styled.div`
            width: 210px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            margin-top: 3rem;
            margin-left:18rem;

            p{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #999999;
            }

            img{
                width: 14.76px;
                height: 20.54px;
            }

            span{
                width: 51px;
                height: 19px;
                font-style: normal;
                font-weight: 900;
                font-size: 12.4008px;
                color: #323B43;
            }

`;

export const SharelinkModal = styled.div`
        display: flex;
        flex-direction: column;
        padding: 0px;
        position: relative;
        width: 100%;
        height: auto;
        background: #FFFFFF;
        margin-top: 0rem;

        input{
          height: 40px;
          margin-bottom: 15px;
          border-radius: 5px;
          border: 1px solid #E6E6E6;
          width: 100%;
          padding: 10px;
          color: #323B43;
          background: #d7e3e747;
          outline: none;
        }

        select{
                height: 40px;
                margin-bottom: 15px;
                border-radius: 5px;
                border: 1px solid #E6E6E6;
                width: 100%;
                padding: 10px;
                color: #323B43;
                background: #d7e3e747;
                outline: none;
        }

        button{
                height: 40px;
                margin-bottom: 10px;
                border-radius: 5px;
                background: #0c54f4;
        }

        textarea{
                height: auto;
                margin-bottom: 15px;
                border-radius: 5px;
                border: 1px solid #E6E6E6;
                width: 100%;
                padding: 10px;
                color: #323B43;
                background: #d7e3e747;
                outline: none;
        }

        label{
             margin-bottom: 10px;
        }

        button{
                display: flex;
                justify-content: center;
                align-items: center;
        }

`;

export const SharelinkModalHeader = styled.div`
        height: 40px;
        margin-top: -2rem;

        div{
        width: 48px;
        height: 48px;
        background: #DADADA;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img{
        width: 15px;
        height: 15px;
        }
        }
`;

export const SharelinkModalBody = styled.div`
        display: block;
        flex-direction: column;
        align-items: center;
        width: 100%;

        div{
        margin-top: 0rem;

        h1{
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        letter-spacing: -0.3px;
        color: #323B43;

        }
        p{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.3px;
        color: #666666;
        }

        span{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 21px;
                color: #0c54f4;
                cursor: pointer

        }

        }

`;

export const LinkDiv = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 350px;
        margin-top: 2rem;
`;

export const Links = styled(Link)`
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #666666;
        margin-right: 20px;
        text-decoration: none;
        cursor: pointer;

        &:hover{
                border-bottom: 1px solid #0c54f4;
                transition: all 0.4s ease-in-out;
        }
`;


export const InputDiv = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;


        input{
        height: 52px;
        width: 100%;
        margin-right: 20px;
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid #d7e3e7;
        padding: 0px 1rem;
        pointer-events: none;

        &:focus{
        outline: none;
                border: none;
        }
        }

        button{
        width: 132px;
        height: 50px;
        background: #0c54f4;
        border-radius: 5px;
        border: none;
        color: #FFFFFF;
        outline: none;
        }

`;


export const TeamModal = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 52px;
        width: 590px;
        height: 500px;
        background: #FFFFFF;
        margin-top: 3rem;
        margin-left: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      
`;

export const Button = styled.button`
        background: #259F46;
        border-radius: 24.2291px;
        width: 320px;
        margin-top: 1rem;
        padding: 15px;
        border: none;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 22.0485px;
        line-height: 26px;
        color: #FFFFFF;
        margin-right: 4.4rem;
`;

export const Button2 = styled.button`
        width: 140px;
        height: 53px;
        background: #F74A4A;
        border-radius: 24.2291px;
        border: none;
        outline: none;
        font-style: normal;
        font-weight: 500;
        font-size: 22.0485px;
        text-align: center;
        color: #FFFFFF;


`;

export const TeamlinkModalHeader = styled.div`
        height: 40px;
        margin-top: -2rem;

        div{
        width: 48px;
        height: 48px;
        background: #DADADA;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img{
        width: 15px;
        height: 15px;
        }
        }
`;

export const TeamLinkDiv = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 350px;
        margin-top: 2rem;
        width: 409px;

        


`;

export const TeamLinks = styled(Link)`
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #000000;
        margin-right: 20px;
        text-decoration: none;
        cursor: pointer;

        &:hover{
        border-bottom: 1px solid #0c54f4;
        transition: all 0.4s ease-in-out;
        }
`;

export const TeamDiv = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px;
        width: 500px;
        height: 110px;
        background: #fafafa;
        padding: 1rem;
        margin-top: 2rem;
        border-radius: 8px;

        div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        object-fit: cover;

        img{
        width: 76px;
        height: 76px;
        border-radius: 32px;
        object-fit: cover;
        }

        div{
                display: flex;
                flex-direction: column;
                margin-left: 15px;

                p{
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                margin-bottom:  10px;
                }

                span{
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                color: #999999;
                }
        }

        }

        p{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #000000;
        }
`;