import{
    CREATE_TEAM_REQUEST,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_FAIL,
    GET_TEAM_REQUEST,
    GET_TEAM_SUCCESS,
    GET_TEAM_FAIL,
    DELETE_TEAM_REQUEST,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_FAIL,
    EDIT_TEAM_REQUEST,
    EDIT_TEAM_SUCCESS,
    EDIT_TEAM_FAIL,
} from "./TeamTypes";
import axios from "axios";
import { BACKEND_BASE_URL } from "../backendUrl";

export const createTeams = (token, workspace_id, name, email, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_TEAM_REQUEST,
      });
  
      const {
        userLogin: { userData },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userData.data.token}`,
        },
      };
  
      const { data } = await axios.post(
        `${BACKEND_BASE_URL}team/create_team`,
        { token, workspace_id, name, email, password},
        config
      );
  
      dispatch({
        type: CREATE_TEAM_SUCCESS,
        payload: data,
      });
 
    } catch (error) {
      dispatch({
        type: CREATE_TEAM_FAIL,
        payload:
          error.response && error.response.data.message});
    }
    };

export const getTeams = (token, workspace_id) => async (dispatch, getState) => {
    try {
        dispatch({
        type: GET_TEAM_REQUEST,
        });
    
        const {
        userLogin: { userData },
        } = getState();
    
        const config = {
        headers: {
            Authorization: `Bearer ${userData.data.token}`,
        },
        };
    
        const { data } = await axios.get(`${BACKEND_BASE_URL}team/get_teams?token=${token}&workspace_id=${workspace_id}`, config);
    
        dispatch({
        type: GET_TEAM_SUCCESS,
        payload: data,
        });
    } catch (error) {
        dispatch({
        type: GET_TEAM_FAIL,
        payload:
            error.response && error.response.data.message});
    }
    }

export const deleteTeams = (token, team_id) => async (dispatch, getState) => {
    try {
        dispatch({
        type: DELETE_TEAM_REQUEST,
        });
    
        const {
        userLogin: { userData },
        } = getState();
    
        const config = {
        headers: {
            Authorization: `Bearer ${userData.data.token}`,
        },
        };
     
        const { data } = await axios.delete(`${BACKEND_BASE_URL}team/delete_team`, 
        {
          data: { token, team_id },
          config
        }
      );
        dispatch({
        type: DELETE_TEAM_SUCCESS,
        payload: data,
        });
    } catch (error) {
        dispatch({
        type: DELETE_TEAM_FAIL,
        payload:
            error.response && error.response.data.message});
    }
    }

export const  editTeams = (team) => async (dispatch, getState) => {
    try {
        dispatch({
        type: EDIT_TEAM_REQUEST,
        });
    
        const {
        userLogin: { userData },
        } = getState();
    
        const config = {
        headers: {
            Authorization: `Bearer ${userData.data.token}`,
        },
        };
    
        const { data } = await axios.put(
            `${BACKEND_BASE_URL}team/edit_team`,
            team, config
        );

        dispatch({
        type: EDIT_TEAM_SUCCESS,
        payload: data,
        });
    } catch (error) {
        dispatch({
        type: EDIT_TEAM_FAIL,
        payload:
            error.response && error.response.data.message});
    }
    }