/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  LoginContainer,
  LoginTitle,
  TextWrapper,
  LoginForm,
  LoginButton,
  FirstFormDiv,
  InputDiv,
  IconDiv,
  Selects,
} from "./ChooseWorkSpaceStyle";
import { getWorkspaceById, getWorkspace } from "../../../redux/workSpace/WorkSpaceAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {bounce} from "../../../toast"
import DashboardNavigation from "../../DashboardNavigation";
toast.configure();
const ChooseWorkSpace = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userData } = userLogin;

  const getWork = useSelector((state) => state.getWork);
  const { loading, error, message, getWorkspaceData } = getWork;

  const getWorkById = useSelector((state) => state.getWorkById);
  const { loading:loadinGetWorkById, error:errorGetWorkById, message:messageGetWorkById, success:successGetWorkById, workSpaceInfo  } = getWorkById;

  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  let userToken = userData?.data?.token


  useEffect(() => {
    if(!userData){
      window.location.href = "/login";
    }else{
      dispatch(getWorkspace({token:userToken}));
    }
  }, [userData, dispatch, userToken]);


  useEffect(() => {
    let data = getWorkspaceData
    if(data?.length === 0){
      window.location.href = "/create-work-space";
    }
  }, [getWork.getWorkspaceData, getWorkspaceData]);

  const getWorkspaceBy = (e) => {
    setSelectedWorkspace(e.target.value);
  }

  const continueWithSelectedWorkspace = () => {
    dispatch(getWorkspaceById( userData?.data?.token, selectedWorkspace));
  }

  useEffect(() => {
    if (successGetWorkById) {
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
    } else if (errorGetWorkById) {
      toast.error(errorGetWorkById, { transition: bounce });
    }
  }, [successGetWorkById, messageGetWorkById, errorGetWorkById]);


  return (
    <>
      <div style={{pointerEvents: "none"}}>
        <DashboardNavigation/>
      </div>
      <LoginContainer>
        <TextWrapper>
          <LoginTitle>Choose Workspace To Access</LoginTitle>
        </TextWrapper>
        {loading && <Loader />}
        <LoginForm>
          
          <FirstFormDiv>
            <IconDiv>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="3" y1="21" x2="21" y2="21" />
                  <path d="M5 21v-14l8 -4v18" />
                  <path d="M19 21v-10l-6 -4" />
                  <line x1="9" y1="9" x2="9" y2="9.01" />
                  <line x1="9" y1="12" x2="9" y2="12.01" />
                  <line x1="9" y1="15" x2="9" y2="15.01" />
                  <line x1="9" y1="18" x2="9" y2="18.01" />
              </svg>
            </IconDiv>

            <InputDiv>
              <Selects value={selectedWorkspace} onChange={getWorkspaceBy} >
                <option value="">Select Workspace</option>
                {getWorkspaceData?.map((workspace) => (
                  <option key={workspace._id} value={workspace.workspace_id}> {workspace.name} </option>
                ))} 
              </Selects>
            </InputDiv>
          </FirstFormDiv>

          <LoginButton onClick={() => continueWithSelectedWorkspace() }>Continue</LoginButton>

        </LoginForm>
      </LoginContainer>

    </>
  );
};

export default ChooseWorkSpace;
