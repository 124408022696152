import React from "react";
import "./index.css";
import ShareTestimonyBody from "../components/ShareTestimonyBody";



const ShareVideoTestimony = () => {
  return (
    <div className="main-layout">
          <div className="main-layout__content" style={{maxWidth: "100%", minHeight: "100%", padding: "0px", border: "none"}}>
            <ShareTestimonyBody />
          </div>
    </div>
  );
};

export default ShareVideoTestimony;