import{
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_RESET,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAIL,
} from "./AccountTypes"
import axios from "axios"
import { BACKEND_BASE_URL } from "../backendUrl";


export const getUserDetail = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_USER_REQUEST,
    });

    const {
      userLogin: { userData },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userData.data.token}`,
      },
    };

    const { data } = await axios.get(`${BACKEND_BASE_URL}account/get_user/?token=${userData.data.token}`, config);

    dispatch({
      type: GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
      
    dispatch({
      type: GET_USER_FAIL,
      payload: message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EDIT_USER_REQUEST,
    });

    const {
      userLogin: { userData },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userData.data.token}`,
      },
    };

    const { data } = await axios.put(`${BACKEND_BASE_URL}account/edit_user`, user, config);

    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: data,
    });
   
    dispatch({ type: GET_USER_SUCCESS, payload: data });

    dispatch({
      type: EDIT_USER_RESET,
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
      
    dispatch({
      type: EDIT_USER_FAIL,
      payload: message,
    });
  }
};

export const changeUserPassword = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CHANGE_PASSWORD_REQUEST,
    });

    const {
      userLogin: { userData },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userData.data.token}`,
      },
    };

    const { data } = await axios.put(`${BACKEND_BASE_URL}account/change_password`, user, config);
    

    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: data,
    });
   
    dispatch({ type: GET_USER_SUCCESS, payload: data });

    // dispatch({
    //   type: CHANGE_PASSWORD_RESET,
    // });

  } catch (error) {
    const message =
      error.response && error.response.data.message
      
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: message,
    });
  }
}

export const uploadPhotos = (token, file_base64) => async (dispatch, getState) => {
  console.log(token, file_base64, 'file')
  try {
    dispatch({
      type: UPLOAD_PHOTO_REQUEST,
    });

    const {
      userLogin: { userData },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userData.data.token}`,
      },
    };

    const { data } = await axios.put(`${BACKEND_BASE_URL}account/upload_photo`, {token, file_base64}, config);

    dispatch({
      type: UPLOAD_PHOTO_SUCCESS,
      payload: data,
    });
   
    dispatch({ type: GET_USER_SUCCESS, payload: data });


  } catch (error) {
    const message =
      error.response && error.response.data.message
      console.log(error)
      
    dispatch({
      type: UPLOAD_PHOTO_FAIL,
      payload: message,
    });
  }
}
