/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import "./index.css";
import PersonaRequest from "./PersonaRequest";
import MessageRequest from "./MessageRequest";
import SuccessRequest from "./SuccessRequest";
import RequestType from "./RequestType";
import Recorder from "./Recorder";
import Testimony from "./Testimony";
import Loader from "../loader/Loader";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";

import axios from "axios";

export default function Index() {
    const { tokens, workspaceId, testimonyId } = useParams();

    const [activeScreen, setActiveScreen] = React.useState(null);
    const [feedbackType, setFeadbackType] = React.useState(null);
    const [feedbackRecordData, setFeedbackRecordData] = React.useState(null);
    const [feedbackMessageData, setFeedbackMessageData] = React.useState(null);
    const [feedbackPersonaData, setFeedbackPersonaData] = React.useState(null);
    const [feedbackOwnerData, setFeedbackOwnerData] = React.useState(null);
    const [testimonyData, setTestimonyData] = React.useState(null);
    const [workspaceData, setWorkspaceData] = React.useState(null);

    // let url_params_array = window.location.pathname.split("/");
    // url_params_array = atob(url_params_array.pop()).split("@");
    // let token = url_params_array[0];
    // let workspaceId = url_params_array[1];

    React.useEffect(() => {

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}account/get_user?token=${tokens}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setFeedbackOwnerData(response.data.data.profile);
        }).catch(function (error) {
            console.log(error, "error");
            // window.location = `/`;
        });

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}workspace/get_workspace_by_id?token=${tokens}&workspace_id=${workspaceId}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setWorkspaceData(response?.data?.data?.workspaces[0]);
        }).catch(function (error) { });


        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}testimony/get_testimony_by_id?token=${tokens}&testimony_id=${testimonyId}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setTestimonyData(response.data.data.testimony[0]);
        }).catch(function (error) { });

    }, []);

    React.useEffect(() => {
        if (activeScreen === "done") {

            let payload = {
                "workspace_id": workspaceId,
                "testimony_id": testimonyId,
                "token": tokens,
                "type": feedbackType,
                "text": feedbackMessageData == null ? "" : feedbackMessageData,
                "name": feedbackPersonaData == null ? "" : feedbackPersonaData.name,
                "email": feedbackPersonaData == null ? "" : feedbackPersonaData.email,
                "file_base64": feedbackRecordData == null ? "" : feedbackRecordData,
                "rating": feedbackPersonaData == null ? "" : feedbackPersonaData.rating,
            };

            var config = {
                method: 'post',
                url: `${BACKEND_BASE_URL}feedback/create_feedback`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: payload
            };

            axios(config).then(function (response) {
                setActiveScreen("success");
            }).catch(function (error) {
                setActiveScreen(null);
               toast.error(error.message, { transition: bounce });
            });

        }
    }, [activeScreen]);

    const closeAndGoBack = () => {
        switch (activeScreen) {
            case null:
                setActiveScreen("testimony");
                break;

            case "type":
                setActiveScreen("persona");
                break;

            case "message":
            case "record":
                setActiveScreen("type");
                break;

            case "testimony":
                setActiveScreen("testimony");
                break;

            default:
                setActiveScreen("testimony");
                break;

        }
    };

    // if (activeScreen === "done") {
    //     return <Loader />;
    // }

    return (
        <>
            {activeScreen === "done" ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="teams">

                    {(activeScreen === null || activeScreen === "testimony") && (
                        !feedbackOwnerData ? null : <Testimony testimonyData={JSON.stringify(testimonyData)} setActiveScreen={setActiveScreen} />
                    )}

                    {(activeScreen === "persona") && (
                        !feedbackOwnerData ? null : <PersonaRequest feedbackOwnerData={JSON.stringify(feedbackOwnerData)} setFeedbackPersonaData={setFeedbackPersonaData} setActiveScreen={setActiveScreen} />
                    )}

                    {(activeScreen === "message") && (
                        !feedbackOwnerData ? null : <MessageRequest feedbackOwnerData={JSON.stringify(feedbackOwnerData)} setFeedbackMessageData={setFeedbackMessageData} setActiveScreen={setActiveScreen} />
                    )}

                    {(activeScreen === "type") && (
                        !feedbackOwnerData ? null : <RequestType feedbackOwnerData={JSON.stringify(feedbackOwnerData)} setActiveScreen={setActiveScreen} setFeadbackType={setFeadbackType} />
                    )}

                    {(activeScreen === "success") && (
                        !feedbackOwnerData ? null : <SuccessRequest feedbackOwnerData={JSON.stringify(feedbackOwnerData)} testimonyData={JSON.stringify(testimonyData)}/>
                    )}

                    {(activeScreen === "record") && (
                        !feedbackOwnerData ? null : <div className="documents__empty-table_feedback_video">
                            <div className="empty-table empty-table_feedback_video">
                                <div className="empty-table__wrapper_create_video">
                                    <Recorder feedbackOwnerData={JSON.stringify(feedbackOwnerData)} setFeedbackRecordData={setFeedbackRecordData} feedbackType={feedbackType} setActiveScreen={setActiveScreen} />
                                </div>
                            </div>
                        </div>
                    )}

                    {(activeScreen !== null && activeScreen !== "testimony") && (
                        <div style={{ width: "auto", height: "auto", cursor: "pointer", zIndex: 99999 }} onClick={() => { closeAndGoBack(); }}>
                            <div className="closeScreen">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.3" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                    <line x1="5" y1="12" x2="11" y2="18" />
                                    <line x1="5" y1="12" x2="11" y2="6" />
                                </svg>
                                <span>Go back</span>
                            </div>
                        </div>
                    )}
                    {/* 
                    {
                        (!feedbackOwnerData || !workspaceData)

                            ? null :

                            <>
                                <div className="bubble">
                                    <p>{workspaceData.feedback_introduction}</p>
                                    <span className="arrow"></span>

                                </div>

                                <div className="feedback_video">
                                    <video src="https://res.cloudinary.com/bluecode-technology/video/upload/c_scale,w_600/v1658220358/sendbetter/hvjlp2o4l1tdq80gumob.mp4" controls playsInline></video>
                                </div>

                            </>



                    } */}





                    <div className="footer_logo">
                        <p>Video message by</p>
                        <img src="/images/A.png" alt="" />
                    </div>

                </div>
            </div>
        </>
    );
};