import React from "react";
import "./index.css";

export default function Index() {
    return (
        <footer className="footer">
            <div style={{
                display: "flex",
                position: "absolute",
                left: "63px",
                zIndex: "999",
            }}>
                <a className="dropDownUser__item dropDownUser__item--stroke" href="https://www.youtube.com/channel/UCBBxejg1W5jrUQYwOzWOsww/videos" target="_blank" rel="noopener noreferrer">
                    <div className="dropDownUser__item-icon">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                                <line x1="13" y1="8" x2="15" y2="8" />
                                <line x1="13" y1="12" x2="15" y2="12" />
                            </svg>
                        </div>
                    </div>
                    Tutorial
                </a>
            </div>

            © 2022 Testimonio |
            <a href="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
        </footer>
    );
};

// (function(d){var s = d.createElement("script");s.setAttribute("data-account", "OgpcjvfgDk");s.setAttribute("src", "https://accessibilityserver.org/widget.js");(d.body || d.head).appendChild(s);})(document)
