import {
    GET_VIDEO_REQUEST,
    GET_VIDEO_SUCCESS,
    GET_VIDEO_FAIL,
    EDIT_VIDEO_REQUEST,
    EDIT_VIDEO_SUCCESS,
    EDIT_VIDEO_FAIL,
    UPLOAD_VIDEO_REQUEST,
    UPLOAD_VIDEO_SUCCESS,
    UPLOAD_VIDEO_FAIL,
} from "./VideoTypes";

export const getVideoReducer = (state = { videoData: [] }, action) => {
    switch (action.type) {
      case GET_VIDEO_REQUEST:
        return { ...state, loading: true };
      case GET_VIDEO_SUCCESS:
        return { loading: false, videoData: action.payload.data.videos.reverse(), message: action.payload.message, success: true };
      case GET_VIDEO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const editVideoReducer = (state = {}, action) => {
    switch (action.type) {
      case EDIT_VIDEO_REQUEST:
        return { loading: true };
      case EDIT_VIDEO_SUCCESS:
        return { loading: false, editVideoData: action.payload, success: true, message: action.payload.message };
      case EDIT_VIDEO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

export const uploadVideoReducer = (state = {}, action) => {
    switch (action.type) {
      case UPLOAD_VIDEO_REQUEST:
        return { loading: true };
      case UPLOAD_VIDEO_SUCCESS:
        return { loading: false, success: true, uploadVideoData:action.payload, message: action.payload.message };
      case UPLOAD_VIDEO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }