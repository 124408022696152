/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

export default function Index() {
    return (
        <div className="main-layout__container">
            <div className="dashboard__wrapper">
                <h1 className="dashboard__title">What do you want to do today?</h1>
                <ul className="dashboard__list">
                    <li className="dashboard__item">
                        <div className="dashboard__item-icon-wrapper">
                            <div className="dashboard__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                        <rect x="3" y="6" width="12" height="12" rx="2" />
                                        <line x1="7" y1="12" x2="11" y2="12" />
                                        <line x1="9" y1="10" x2="9" y2="14" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="dashboard__item-label">
                            Start Collecting Testimonials!
                        </p>
                        <p className="dashboard__item-desc">
                            Collect your first testimonial from your customers
                        </p>
                        <Link className="dashboard__item-link" to="testimonys">
                            <button type="button" className="button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                Collect Testimonial
                            </button>
                        </Link>
                    </li>
                    <li className="dashboard__item">
                        <div className="dashboard__item-icon-wrapper">
                            <div className="dashboard__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                        <rect x="3" y="6" width="12" height="12" rx="2" />
                                        <line x1="7" y1="12" x2="11" y2="12" />
                                        <line x1="9" y1="10" x2="9" y2="14" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <p className="dashboard__item-label">
                            Start Creating Some Videos!
                        </p>
                        <p className="dashboard__item-desc">
                            Add your first video content to your account
                        </p>
                        <Link className="dashboard__item-link" to="/videos">
                            <button type="button" className="button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                Add New Video
                            </button>
                        </Link>
                    </li>
                    {/* <li className="dashboard__item">
                            <div className="dashboard__item-icon-wrapper">
                                <div className="dashboard__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-send" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <line x1="10" y1="14" x2="21" y2="3" />
                                            <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <p className="dashboard__item-label">Showcase</p>
                            <p className="dashboard__item-desc">Let your customers see your products and interact.</p>
                            <a className="dashboard__item-link" href="/videos">
                                <button type="button" className="button button--primary"><p className="button__text">Start</p></button>
                            </a>
                        </li> */}
                </ul>
            </div>
        </div>
    );
};