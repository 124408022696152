import React from 'react'
import "./Loader.css"
const Loader = () => {
    return (
        <>
            <div className="overlay">
  <div className="overlay__inner">
    <div className="overlay__content"><span className="spinner" /></div>
  </div>
</div>

        </>
    )
}

export default Loader
