/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import "./index.css";
import Loader from "../loader/Loader";
import axios from "axios";

export default function Index() {

    const [embedData, setEmbedData] = React.useState(null); 
    const [isPlaying, setIsPlaying] = React.useState(false); 
    const vidRef = React.useRef(null); 

    let url_params_array = window.location.pathname.split("/");
    let feedback_id = url_params_array.pop();

    React.useEffect(() => {

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}feedback/get_feedback_by_id?feedback_id=${feedback_id}`,
            headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setEmbedData(response.data.data.feedback)
        }).catch(function (error) {
            window.location = `/`
        });

    }, [])

    if(embedData == null){
        return <Loader />
    }

    const playPauseVideo = () => {
        isPlaying? vidRef.current.pause() : vidRef.current.play()
        setIsPlaying(!isPlaying)
    }

    return (
        <div className="main-layout__container">
            <div className="team">

                <div className="video-container">
                    <div>
                        {!isPlaying && (
                            <button onClick={() => playPauseVideo()} className="video-play-pause-btn" title="Play or Pause">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-play" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M7 4v16l13 -8z" />
                                </svg>
                            </button>
                        )}
                    </div>
                    <video onPause={() => setIsPlaying(false)} onPlaying={() => setIsPlaying(true)} ref={vidRef} playsInline controls src={embedData.url} className="embedded_video"/>
                    <div style={{display: "none"}} className="video-controls video-controls-visibility--visible">
                        {isPlaying? (
                            <button onClick={() => playPauseVideo()} style={{marginLeft: "-15px"}} className="control-item play-and-pause-video" title="Play or Pause">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-play" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <rect x="6" y="5" width="4" height="14" rx="1" />
                                    <rect x="14" y="5" width="4" height="14" rx="1" />
                                </svg>
                            </button>
                        ) : (
                            <button onClick={() => playPauseVideo()} style={{marginLeft: "-15px"}} className="control-item play-and-pause-video" title="Play or Pause">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-play" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M7 4v16l13 -8z" />
                                </svg>
                            </button>
                        )}
                        <div className="progress-video-container">
                            <input type="range" className="control-item progress-video" min="0.0" value="0.00" step="any" max="100.00"/>
                            <span className="progress-time time-video">00:00</span>
                        </div>
                        <div className="audio-video-container">
                            <button className="control-item volume-video fa fa-volume-up" title="Volume"></button>
                            <input type="range" className="control-item slide-volume-video" min="0" value="1" step="any" max="1"/>
                        </div>
                        <button className="control-item mute-and-unmute-video">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-play" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M15 8a5 5 0 0 1 0 8" />
                                <path d="M17.7 5a9 9 0 0 1 0 14" />
                                <path d="M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a0.8 .8 0 0 1 1.5 .5v14a0.8 .8 0 0 1 -1.5 .5l-3.5 -4.5" />
                            </svg>
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};