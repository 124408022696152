import React, { useState, useEffect } from "react";
import { resetPassword } from "../../../redux/auth/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import {
  Container,
  Title,
  TextWrapper,
  LoginForm,
  GoogleIcon,
  Button,
  LoginInput,
  // SmallText,
  FirstFormDiv,
  InputDiv,
  IconDiv,
  Subtitle,
  SmallTextDiv,
  // LinkText,
  MainContainer,
  Div
} from "./ResetPasswordStyle";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {bounce} from "../../../toast"
toast.configure();


const ResetPassword = () => {
  
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const reset = useSelector((state) => state.reset);
  const { loading, error, message, resetData } = reset;

  useEffect(() => {
   if(resetData){
    toast.success(message, { transition: bounce });
    setTimeout(() => {
      window.location.href = "/success";
    }, 3000);
   }else if(error){
    toast.error(error, { transition: bounce });
    }
  }, [resetData, message, error]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email));
  };

  return (
    <>
    <Div>
      <div>
      <MainContainer>
      <Container>
        <TextWrapper>
          <Title>Reset password</Title>
        </TextWrapper>
          {loading && <Loader />}
        <LoginForm onSubmit={submitHandler}>
          <FirstFormDiv>
            <IconDiv>
              <GoogleIcon src="/images/email2.svg" />
            </IconDiv>
            <InputDiv>
              <LoginInput
                placeholder="Email address"
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputDiv>
          </FirstFormDiv>

          <Button>Reset now</Button>

          <Subtitle>
            Please enter your email address to confirm your account
          </Subtitle>
          
        </LoginForm>
      </Container>
    </MainContainer>

      <SmallTextDiv>
        {/* <SmallText>
          Don’t have an account? <LinkText to="/signup">Sign up</LinkText>
        </SmallText> */}
      </SmallTextDiv>
      </div>
    </Div>
   

      {/* <Bottom>
        <SelectText>Help</SelectText>
        <Select name="help" id="help" className="help">
          <option>English</option>
          <option>French</option>
          <option>German</option>
          <option>Spanish</option>
        </Select>
      </Bottom> */}
    </>
  );
};

export default ResetPassword;
