import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Div = styled.div`
        display: flex;
        flex-direction: row;
        padding: 0px;
        margin-bottom: 2rem;

`;

export const ImageDiv = styled.div`
            width: 186.33px;
            height: 128.68px;
            background: #F5FAFF;
            border-radius: 6.17679px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 2rem;
            flex-direction: column;
            cursor: pointer;

            svg{
                stroke: #0c55f4;
                margin-bottom: 5px
            }

            span{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #0c54f4;
            }

            :hover {
                background: #0c55f4
            }
            :hover span{
                color: #ffffff
            }
            :hover svg{
                stroke : #ffffff
            }

`;

export const TabDiv = styled.div`
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            width: 701.02px;
`;

export const Tab1 = styled.div`
        width: 394.29px;
        height: 89.56px;
        background: none;
        border-radius: 6.17679px;
        border: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: row;
        margin-right: 2rem;

        img{
        width: 56.62px;
        height: 56.62px;
        border-radius: 6.17679px;
        margin-right: 20px;
        }

        span{
        font-style: normal;
        font-weight: 500;
        font-size: 16.4714px;
        color: #323B43;
        width: 274.87px;
        }
`;

export const Tab2 = styled.div`
        width: 277.01px;
        height: 89.91px;
        background: none;
        border-radius: 6.17679px;
        border: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: row;

        span{
        color: #323B43;
        width: 234.98px;
        font-style: normal;
        font-weight: 500;
        font-size: 16.4714px;
        }

`;


export const LinkWrap = styled(Link)`
        color: #0c54f4;
        width: 234.98px;
        font-style: normal;
        font-weight: 500;
        font-size: 16.4714px;
        text-decoration: none;
        margin-left: 3px;

`;

export const Footerdiv = styled.div`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0rem;   

            p{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #999999;
            }

            img{
                width: 130px;
                height: auto;
            }

            span{
                width: 51px;
                height: 19px;
                font-style: normal;
                font-weight: 900;
                font-size: 12.4008px;
                color: #323B43;
            }

`;

export const NewVideoDiv = styled.div`
        width: 100%;
        height: 100%;
        // background-color: #fafafa;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 0rem;
        margin-top: 0rem;
        

        p{
        font-style: normal;
        font-weight: 600;
        font-size: 20.5893px;
        color: #323B43;
        /* position: absolute;
        left: 420px;
        top: 190px; */
        }
`;

export const Screen = styled.div`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        position: relative;
        max-width: 550px;
        background: rgb(206 224 240 / 30%);
        max-height: 350px;
        align-items: center;
        border-radius: 8px;
        justify-content: center;

        @media (max-width: 768px) {
                max-width: 95%;
        }
`;

export const Circle = styled.div`
        width: 166.74px;
        height: 165.28px;
        background: #C4C4C4;
        border-radius: 40%;
        position: absolute;
        top: 16rem;
        right: 13rem;
`;

export const CloseScreen = styled.div`
        width: 30px;
        height: 30px;
        background: rgb(0 0 0 / 50%);
        border-radius: 100%;
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        :hover{
                background: #0c55f4;
                transition: 0.9s;
        }
`;

export const Header = styled.h1`
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
        color: #323B43;
        margin-left: 2rem;
        margin-top: 1rem;
        margin-bottom: -0.8rem;

`;

export const Para = styled.p`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #323B43;
    text-align: center;
`;

export const Cameras = styled.div`
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        z-index: 999;
        width: 90%;
        position: absolute;
        padding-bottom: 30px;
        align-items: flex-end;

        img{
            width: 66px;
            height: 66px;
            cursor: pointer;
        }
`;

export const UploadFileButton = styled.div`
        position: relative;
        overflow: hidden;
        display: inline-block;
        width: 277.01px;
        height: 89.91px;
        background: none;
        border-radius: 6.17679px;
        border: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: row;
        cursor: pointer;

        button {
                border: 0px solid #E6E6E6;
                border-radius: 5px;
                cursor: pointer;
                padding: 8px 20px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 500        ;
                color: #323B43;
                margin: 0px;
                background: transparent;
                margin-bottom: 0rem;
        }

        buttonLink {
                color: #0c54f4;
                margin-left: 7px;
                text-decoration: none;
                font-style: normal;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
        }

        input {
                font-size: 100px;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                cursor: pointer;
                margin: 0px;
                margin-bottom: 0rem;
                cursor: pointer;
        }
`;