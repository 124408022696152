import {
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    VERIFY_REQUEST,
    VERIFY_SUCCESS,
    VERIFY_FAIL,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    RESEND_VERIFICATION_CODE_REQUEST,
    RESEND_VERIFICATION_CODE_SUCCESS,
    RESEND_VERIFICATION_CODE_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    USER_LOGOUT,
  } from "./AuthTypes";


export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_REGISTER_REQUEST:
        return { loading: true };
      case USER_REGISTER_SUCCESS:
        return { loading: false, registerData: action.payload, success: true, message: action.payload.message };
      case USER_REGISTER_FAIL:
        return { loading: false, error: action.payload };
        case USER_LOGOUT:
          return {};
      default:
        return state;
    }
  };

export const verifyReducer = (state = {}, action) => {
    switch (action.type) {
      case VERIFY_REQUEST:
        return { loading: true };
      case VERIFY_SUCCESS:
        return { loading: false, verifyData: action.payload, success: true, message: action.payload.message };
      case VERIFY_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
        return { loading: true };
        case USER_LOGIN_SUCCESS:
        return { loading: false, userData: action.payload, success: true, message: action.payload.message };
        case USER_LOGIN_FAIL:
        return { loading: false, error: action.payload };
        case USER_LOGOUT:
          return {};
        default:
        return state;
    }
    }

export const resendCodeReducer = (state = {}, action) => {
      switch (action.type) {
        case RESEND_VERIFICATION_CODE_REQUEST:
          return { loading: true };
        case RESEND_VERIFICATION_CODE_SUCCESS:
          return { loading: false, code: action.payload, success: true, message: action.payload.message };
        case RESEND_VERIFICATION_CODE_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    };

export const resetPassword = (state = {}, action) => {
      switch (action.type) {
        case RESET_PASSWORD_REQUEST:
          return { loading: true };
        case RESET_PASSWORD_SUCCESS:
          return { loading: false, resetData: action.payload, success: true, message: action.payload.message };
        case RESET_PASSWORD_FAIL:
          return { loading: false, error: action.payload };
        default:
          return state;
      }
    };