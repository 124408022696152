import React from "react";
import "./index.css";
import SetBrand from "./SetBrand";

export default function Index() {

    // const [createdOnIsVisible, setCreatedOnIsVisible] = React.useState(false)
    // const [createdByIsVisible, setCreatedByIsVisible] = React.useState(false)
    // const [dropdownActiveState, setDropdownActiveState] = React.useState(false)
    
    return (
        <div className="main-layout__container">
            <div className="team">
                <div className="documents__empty-table_create_page">
                    <div className="empty-table empty-table_create_page">
                        <div className="empty-table__wrapper_create_page">
                            <SetBrand className="edit_video_page_design" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};