import React from 'react'
import "./index.css"
import HeaderLanding from './HeaderLanding'
import FooterLanding from './FooterLanding'

const Pricing = () => {
    return (
        <>
            <div className="main_landing_page_div">
                <div className="landing_page_top">
                    <HeaderLanding />

                    <div className='pricing_div'>
                        <div className='pricing_div_top'>
                            <h3>Not just choosing a plan, Stand out connecting to with your customers </h3>
                            <p>You can’t be left out, join the premium users of Testimonio</p>
                        </div>
                        <div className='pricing_div_body'>
                            <div className="billing__info-table">
                                <div className="table">
                                    <div className="table__container2">
                                        <div className="table__innerContainer">
                                            <div className="table__row billing__table-row">
                                                <div className="billing__table-column billing__table-column--header billing__table-column--name"></div>
                                                <div className="billing__table-column billing__table-column--header">
                                                    <div className="billing__table-title">Free</div>
                                                    <div className="billing__table--description">Get up to 3+ videos access per month for free.</div>
                                                    <div className="billing__current-plan">Current Plan</div>
                                                </div>
                                                <div className="billing__table-column billing__table-column--header">
                                                    <div className="billing__table-title">Personal</div>
                                                    <div className="billing__table--description">Best for individuals and social influencers.</div>
                                                    <div className="billing__table-button">
                                                        <button type="button" className="button button--primary"><p className="button__text">Upgrade</p></button>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column--header">
                                                    <div className="billing__table-title">Business</div>
                                                    <div className="billing__table--description">Best for small businesses and large enterprises.</div>
                                                    <div className="billing__table-button">
                                                        <button type="button" className="button button--primary"><p className="button__text">Upgrade</p></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='the_main_table'>
                                            <div className="billing__plan-info table__row billing__table-row--borderless settings__text settings__text--bold settings__text--default-size">Plan Information</div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Plan Cost</div>
                                                <div className="billing__table-column billing__table-column">Free</div>
                                                <div className="billing__table-column billing__table-column">$20 / month</div>
                                                <div className="billing__table-column billing__table-column">$30 / month</div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Videos per month</div>
                                                <div className="billing__table-column billing__table-column">3</div>
                                                <div className="billing__table-column billing__table-column">Unlimited</div>
                                                <div className="billing__table-column billing__table-column">Unlimited</div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Feedbacks per month</div>
                                                <div className="billing__table-column billing__table-column">0</div>
                                                <div className="billing__table-column billing__table-column">10</div>
                                                <div className="billing__table-column billing__table-column">Unlimited</div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Workspaces</div>
                                                <div className="billing__table-column billing__table-column">1</div>
                                                <div className="billing__table-column billing__table-column">5</div>
                                                <div className="billing__table-column billing__table-column">Unlimited</div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Google Drive Integration</div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Dropbox Integration</div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">One Drive Integration</div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Box Integration</div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="billing__table-row table__row">
                                                <div className="billing__table-column billing__table-column--name">Team Management</div>
                                                <div className="billing__table-column billing__table-column"></div>
                                                <div className="billing__table-column billing__table-column"></div>
                                                <div className="billing__table-column billing__table-column">
                                                    <div className="billing__table-icon">
                                                        <div>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="#0b53f4" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <circle cx="12" cy="12" r="9" />
                                                                <path d="M9 12l2 2l4 -4" stroke="#ffffff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='landing_page_body'>
                    <div className='landing_page_body_top'>
                        <div className='second_vector_icon'>
                            <svg width="100" height="60" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.1015 57.0805C81.0298 57.2976 90.1538 56.8633 98.6907 59.9427C99.1996 60.1243 99.7593 59.8598 99.9432 59.3466C100.123 58.8334 99.861 58.2688 99.3522 58.0833C90.6157 54.9328 81.2842 55.3276 72.1485 55.1065C71.6083 55.0907 71.1582 55.525 71.1465 56.0698C71.1308 56.6146 71.5614 57.0647 72.1015 57.0805Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M71.2991 44.006C74.0821 41.4233 77.1195 39.4884 80.7911 38.5295C81.3156 38.3929 81.6287 37.854 81.4956 37.3265C81.3586 36.7991 80.8224 36.4821 80.3018 36.6187C76.3093 37.6609 73.0018 39.7462 69.9761 42.5528C69.5769 42.9216 69.5494 43.5465 69.9174 43.9476C70.2814 44.3484 70.9038 44.3748 71.2991 44.006Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.557 15.8086C64.833 11.3968 67.3459 7.94546 71.1975 5.45312C71.6515 5.159 71.7846 4.54787 71.4911 4.08951C71.2014 3.63115 70.5947 3.4977 70.1406 3.79182C65.8781 6.55144 63.0912 10.3707 61.6781 15.2555C61.5255 15.7782 61.8269 16.327 62.3436 16.4794C62.8642 16.6321 63.4082 16.3313 63.557 15.8086Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5808 9.82115C18.8214 10.7225 18.1403 11.6937 17.561 12.7276C17.3027 13.188 16.8486 14.1663 16.7782 15.0052C16.6999 15.899 17.0013 16.6531 17.7763 17.0503C18.8057 17.5769 19.6277 17.522 20.2618 17.2034C20.8724 16.8975 21.35 16.2962 21.6513 15.49C22.2267 13.9491 22.1406 11.5942 21.8392 10.2503C22.6495 9.40702 23.538 8.64229 24.4891 7.96245C31.5739 2.8992 41.9661 4.76619 49.9628 12.3368C52.7615 14.9874 54.108 20.554 54.8086 26.5601C55.9007 35.9116 55.2783 46.3563 55.1922 49.4163C55.1726 50.172 55.2431 50.6205 55.2822 50.7101C55.4662 51.1507 55.7911 51.2652 56.0181 51.3086C56.3469 51.3678 56.6091 51.3046 56.8205 51.1941C57.1062 51.0425 57.3411 50.7756 57.4507 50.3638C57.5094 50.1364 57.525 49.8198 57.5172 49.4823C57.5094 49.2482 57.4624 48.9923 57.5094 48.8245C57.8147 47.7499 58.1826 46.7005 58.5427 45.6437C59.7444 42.0909 61.2984 38.7616 63.0245 35.4363C68.5083 24.87 73.812 13.7055 82.803 5.65527C83.2061 5.29403 83.2453 4.66947 82.8852 4.26204C82.529 3.85461 81.9105 3.81749 81.5035 4.17913C72.3325 12.3885 66.8839 23.7452 61.2905 34.5207C59.8227 37.3494 58.4762 40.1821 57.3567 43.145C57.4702 37.9037 57.4272 30.6486 56.476 24.2292C55.6305 18.5201 54.0218 13.4746 51.3014 10.8978C42.5415 2.60429 31.1159 0.805217 23.3579 6.35209C22.5281 6.94586 21.7375 7.59886 21.0016 8.30712C16.9426 0.914586 8.25304 -2.04837 0.573367 1.48741C0.080177 1.71363 -0.1351 2.29951 0.0880092 2.79498C0.315033 3.29045 0.894314 3.50917 1.3875 3.28296C8.30782 0.0969601 16.1558 2.94026 19.5808 9.82115ZM20.1483 12.2961C19.8313 12.7478 19.5377 13.216 19.2637 13.7C19.1189 13.9598 18.8683 14.4592 18.7627 14.948C18.7392 15.0625 18.7353 15.2417 18.7353 15.3246C19.0054 15.4588 19.2167 15.5236 19.3889 15.4363C19.6042 15.3289 19.7138 15.0763 19.8195 14.794C20.0857 14.0857 20.1718 13.1469 20.1483 12.2961Z" fill="black" />
                            </svg>
                        </div>
                        <h4>Record your screen and cam simultaneously </h4>
                        <p>Seamlessly record your screen, cam, or both to engage leads, add a human experience to your brand messages</p>
                        <div className='third_vector_icon'>
                            <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.4355 18.5316C35.1932 23.5712 35.2745 28.4244 35.1257 33.5362C35.0967 34.5345 35.8827 35.3645 36.8803 35.3945C37.8785 35.4246 38.7116 34.6367 38.7411 33.6384C38.8822 28.7913 38.7682 24.1786 39.9472 19.3916C40.1859 18.4233 39.5922 17.4431 38.6229 17.2085C37.6543 16.968 36.6736 17.5633 36.4355 18.5316Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.881 8.76497C17.453 16.4507 9.51211 26.3375 3.96179 36.3206C3.47777 37.1926 3.79303 38.2932 4.66585 38.7743C5.53866 39.2554 6.64051 38.9426 7.12454 38.0706C12.4916 28.4183 20.1715 18.8562 28.321 11.4291C29.0576 10.7555 29.1088 9.61289 28.4355 8.8792C27.7622 8.14551 26.6176 8.09142 25.881 8.76497Z" fill="black" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.746 0.369595C16.0377 -0.899335 8.09262 1.26564 1.0836 4.32671C0.169197 4.72964 -0.247933 5.79406 0.151704 6.70216C0.551944 7.61627 1.61947 8.03126 2.53387 7.63434C8.91722 4.8439 16.1366 2.7751 23.1571 3.92977C24.142 4.09214 25.0733 3.42462 25.2354 2.44436C25.3982 1.46409 24.7303 0.53197 23.746 0.369595Z" fill="black" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='getStartedBox'>
                    <div className='getStaretdBoxPricing'>
                        <h3>Record and share personalized videos to anywhere. Share across 100+ social media platforms with just one click. </h3>
                        <div className="get_started_btn">
                            <button>Get Started for Free</button>
                        </div>
                    </div>
                </div>
                <FooterLanding />
            </div>
        </>
    )
}

export default Pricing