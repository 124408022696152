import React from "react";
import "./index.css";
import DashboardNavigation from "../components/DashboardNavigation";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardBody from "../components/DashboardBody";
import DashboardFooter from "../components/DashboardFooter";

const Dashboard = () => {
  return (
    <div className="main-layout">
          <DashboardNavigation />
          <div className="main-layout__content">
            <DashboardSidebar />
            <DashboardBody />
          </div>
          <DashboardFooter />
    </div>
  );
};

export default Dashboard;
