/* eslint-disable no-unused-vars */
import React from 'react';
import { Div, Circle, MainContainer, Container, Wrapper, InputWrapper } from "./MessageRequestStyle";
import { titleCase, getInitials } from "../../functions";

const MessageRequest = (props) => {

    const submitMessage = (event) => {
        event.preventDefault();

        if (event.target.message.value) {
            props.setFeedbackMessageData(event.target.message.value);
            props.setActiveScreen("done");
        }
    };

    const feedbackOwnerData = JSON.parse(props.feedbackOwnerData);

    return (
        <>
            <Div>
                <div className="margin-top-containers">
                    <MainContainer>

                        <Container>
                            <Wrapper>

                                {/* <Circle>{getInitials(titleCase(feedbackOwnerData.name))}</Circle> */}
                                    <img src={feedbackOwnerData.photo} alt="" />
                               
                                <p>Write to us what's on your mind</p>
                                <span>We need to know who this feedback message is coming from, Please fill out your info below.</span>

                                <form onSubmit={(event) => submitMessage(event)}>
                                    <InputWrapper>
                                        <textarea name="message" placeholder="Write your message here" />
                                    </InputWrapper>


                                    <div style={{
                                        display: 'flex',
                                        position: 'relative',
                                    }}>
                                        <button type="submit">
                                            Continue
                                            <div style={{
                                                display: "flex",
                                                position: 'absolute',
                                                right: '20px',
                                            }}>
                                                <img src="/images/arrow-white.svg" alt="arrow" />
                                            </div>

                                        </button>
                                    </div>
                                </form>
                            </Wrapper>
                        </Container>

                    </MainContainer>
                </div>
            </Div>
            <br />
        </>
    );
};

export default MessageRequest;
