/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";
import { getUserDetail, updateUser, changeUserPassword, uploadPhotos } from "../../redux/account/AccountAction";
import { useSelector, useDispatch } from "react-redux";
import { titleCase, getInitials } from "../../functions";
import Loader from "../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";
toast.configure();

export default function Index() {
    const dispatch = useDispatch();

    const [name, setName] = React.useState("");
    const [gender, setGender] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [currency, setCurrency] = React.useState("");
    const [job_title, setJobTitle] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [new_password, setNewPassword] = React.useState("");
    const [confirm_password, setConfirmPassword] = React.useState("");
    const [file_buffer, setFileBuffer] = React.useState(null);
    const [file_base64, setFileBase64] = React.useState(null);
    const [photo, setPhoto] = React.useState('');
    const [newUploadPhoto, setNewUploadPhoto] = React.useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const getUser = useSelector((state) => state.getUser);
    const { loading, error, message, user } = getUser;

    const edit = useSelector((state) => state.edit);
    const { loading: loadingEdt, error: errorEdit, message: messageEdit, editUserDetails, success: successEdit } = edit;

    const changePassword = useSelector((state) => state.changePassword);
    const { loading: loadingChange, error: errorChange, message: messageChange, success: successChange, resetPasswordData } = changePassword;

    const uploadPhoto = useSelector((state) => state.uploadPhoto);
    const { loading: loadingUpload, error: errorUpload, message: messageUpload, success: successUpload, uploadPhotoData } = uploadPhoto;

    React.useEffect(() => {
        if (!userData) {
            window.location.href = "/login";
        } else {
            dispatch(getUserDetail(userData.data.token));
        }
    }, [userData, dispatch]);

    React.useEffect(() => {
        if (Object.keys(user).length > 0) {
            let user_detail = user?.data?.profile;
            if (user_detail) {
                setName(titleCase(user_detail.name));
                setJobTitle(user_detail.job_title);
                setPhoto(user_detail.photo);
                setEmail(user_detail.email);
            }
        }
    }, [user]);


    React.useEffect(() => {
        if (successEdit) {
            toast.success(messageEdit, { transition: bounce });
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else if (errorEdit) {
            toast.error(errorEdit, { transition: bounce });
        }
    }, [successEdit, messageEdit, errorEdit]);


    React.useEffect(() => {
        if (resetPasswordData) {
            toast.success(messageChange, { transition: bounce });
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else if (errorChange) {
            toast.error(errorChange, { transition: bounce });
        }
    }, [resetPasswordData, messageChange, errorChange]);

    let token = userData.data.token;

    React.useEffect(() => {
        if (successUpload) {
            toast.success(messageUpload, { transition: bounce });
            setTimeout(() => {
                window.location.reload();
            }, 500);
        } else if (errorUpload) {
            toast.error(errorUpload, { transition: bounce });
        }
    }, [successUpload, messageUpload, errorUpload]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            updateUser({ token: userData.data.token, name, job_title, email })
        );
    };

    const handleChangePassword = (e) => {
        e.preventDefault();
        dispatch(
            changeUserPassword({ token: userData.data.token, new_password, confirm_password })
        );
    };

    const handleUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        const fileSize = file.size / 1024 / 1024;
        if (fileSize < 11) {
            const reader = new window.FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = () => {
                setFileBuffer(Buffer(reader.result));

                //convert to base64
                const base64 = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                setPhoto(`data:image/png;base64,${base64}`);
                setFileBase64(base64);
                setNewUploadPhoto(true);
            };
        } else {
            alert("File size uploaded is too big, maximum file size required is 10MB.");
        }
    };

    const submitUpload = () => {
        dispatch(uploadPhotos(
            token,
            file_base64
        ));
    };


    return (
        <>
            {loading || loadingEdt || loadingChange || loadingUpload ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="settings-layout">
                    <p className="settings-layout__title">Settings</p>
                    <p className="settings-layout__subTitle">
                        Browse all your  settings from here. <br />
                        If you need more in-depth help or support, please get in touch.
                    </p>
{/* 
                    <div className="settings-layout__nav">
                        <div className="settingsNav__list">
                            <a className="settingsNav__item settingsNav__item--stroke" href="/workspaces">
                                <div className="settingsNav__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="3" y1="21" x2="21" y2="21" />
                                            <path d="M5 21v-14l8 -4v18" />
                                            <path d="M19 21v-10l-6 -4" />
                                            <line x1="9" y1="9" x2="9" y2="9.01" />
                                            <line x1="9" y1="12" x2="9" y2="12.01" />
                                            <line x1="9" y1="15" x2="9" y2="15.01" />
                                            <line x1="9" y1="18" x2="9" y2="18.01" />
                                        </svg>
                                    </div>
                                </div>
                                Workspace
                            </a>
                            <a className="settingsNav__item settingsNav__item--stroke settingsNav__item--active" href="/profile">
                                <div className="settingsNav__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="7" r="4" />
                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        </svg>
                                    </div>
                                </div>
                                Profile
                            </a>
                            <a className="settingsNav__item settingsNav__item--stroke" href="/branding">
                                <div className="settingsNav__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />
                                            <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />
                                            <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
                                            <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                Branding
                            </a>
                            <a className="settingsNav__item settingsNav__item--stroke" href="/billing">
                            <div className="settingsNav__item-icon">
                                <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                                        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                                    </svg>
                                </div>
                            </div>
                            Billing
                        </a>
                        </div>
                    </div> */}

                    <div className="settings-layout__content">
                        <div className="profile">
                            <form className="settings__form">

                                <div className="settings__block">
                                    <h1 className="settings__title">Profile Photo</h1>
                                    <div className="settings__form-group">
                                        <div className="settings__field settings__form-group-item">
                                            <div className="avatar-field">
                                                <div className="avatar avatar--large">
                                                    {photo ? (
                                                        <div className="avatar-initials"><div className="avatar-initials__content">
                                                            <img src={photo} alt="" />
                                                        </div></div>
                                                    ) : (
                                                        <div className="avatar-initials"><div className="avatar-initials__content">{getInitials(name)}</div></div>
                                                    )}
                                                </div>
                                                <div className="avatar-field__content">
                                                    <div className="avatar-field__content-title">Update your profile picture</div>
                                                    <div className="avatar-field__content-buttons mobile">

                                                        {newUploadPhoto ? (
                                                            <div className="uploadButtonsGroup">
                                                                <button type="button" style={{ marginRight: "10px" }} className="button button--secondary"
                                                                    onClick={async (e) => { submitUpload(); }}
                                                                >
                                                                    <p className="button__text">Submit</p>
                                                                </button>

                                                                <button type="button" className="button button--secondary">
                                                                    <p className="button__text">Delete</p>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div className="upload-btn-wrapper">
                                                                <button className="button button--secondary">
                                                                    <p className="button__text">Upload photo</p>
                                                                </button>
                                                                <input type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUpload(event)} />
                                                            </div>
                                                        )}

                                                    </div>
                                                </div>
                                                <input accept=".jpg,.png,.bmp,.jpeg" type="file" className="avatar-field__upload" />
                                            </div>
                                            <div className="avatar-field__uploader-description"><p className="settings__text settings__text--grey">We recommend uploading an avatar image with a resolution of 128x128 px.</p></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="profile_cover">
                                    <div className="settings__block">
                                        <h1 className="settings__title">Personal Information</h1>
                                        <div className="settings__form-group settings__block--small">
                                            <div className="settings__field settings__form-group-item">
                                                <div className="form__field">
                                                    <label className="form__label">Full Name</label>
                                                    <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="settings__block--small">
                                            <div className="settings__field">
                                                <div className="form__field">
                                                    <label className="form__label">Email</label>
                                                    <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /></div>
                                                </div>
                                            </div>
                                            <div className="profile__email-button" >
                                                <button type="button" className="button button--secondary" onClick={handleSubmit}>
                                                    <p className="button__text">Update Profile</p>
                                                    </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ "margin-left": "50px" }} className="settings__block">
                                        <h1 className="settings__title">Change Password</h1>
                                        <div className="settings__form-group settings__block--small">
                                            <div className="settings__field settings__form-group-item">
                                                <div className="form__field">
                                                    <label className="form__label">New Password</label>
                                                    <div className="form__input-wrapper">
                                                        <input type="text" className="form__input" placeholder="Password"
                                                            value={new_password}
                                                            onChange={(e) => setNewPassword(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="settings__block--small">
                                            <div className="settings__field">
                                                <div className="form__field">
                                                    <label className="form__label">Repeat New Password</label>
                                                    <div className="form__input-wrapper">
                                                        <input type="text" className="form__input" placeholder="Repeat password"
                                                            value={confirm_password}
                                                            onChange={(e) => setConfirmPassword(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="profile__email-button">
                                                <button type="button" className="button button--secondary" onClick={handleChangePassword}><p className="button__text">Update Password</p></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="settings__block">
                                    <h1 className="settings__title">Delete Account</h1>
                                    <div className="profile__button profile__button--delete">
                                        <button type="button" className="button button--secondary"><p className="button__text">Delete my Account</p></button>
                                    </div>
                                    <p className="settings__text settings__text--grey">
                                        If you would like to remove all your data from the  servers,<br />
                                        please proceed with this action. This action is not reversible.
                                    </p>
                                </div> */}

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};