import styled from "styled-components";

export const RowDiv = styled.div`
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 30px;
`;

export const ColumnDiv1 = styled.div`
        flex: 20%;
        margin-left: 40px;
`;

export const ColumnDiv2 = styled.div`
        flex: 70%;
`;

export const FormColorDiv = styled.div`
            width: 100%;
            height: 100%;
            background: #F5F5F5;
            margin-left: 0px;
            margin-bottom: 0rem;
            padding: 20px;
            border-radius: 5px;
`

export const Form = styled.form`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        button{
        width: 324.53px;
        height: 47.45px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        margin-top: 0rem;
        font-style: normal;
        font-weight: 600;
        font-size: 15.2521px;
        color: #323B43;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;


        img {
                width: 40px;
                object-fit: contain;
                height: 25px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                outline: none;
        }

        }

        p{
        width: 306px;
        height: 20.34px;
        font-style: normal;
        font-weight: 500;
        font-size: 16.9468px;
        color: #323B43;
        margin-top: 1rem;
        
        }

        select{
        width: 324.53px;
        height: 47.45px;
        background: #FAFAFA;
        border: 0.847339px solid #E6E6E6;
        box-sizing: border-box;
        border-radius: 5.08403px;
        cursor: pointer;
        outline: none;
        font-style: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 13.5574px;
        line-height: 16px;
        color: #323B43;
        padding-left: 10px;
        padding-right: 10px;
        
        }
`

export const TitleWrapper = styled.div`
        box-sizing: border-box;
        margin-left: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #323B43;
        margin-bottom: 1rem;
        }

        img{
                width: 395px;
                height: auto;
                border-radius: 5px;
        }

        h3{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #323B43;
        margin-top: 1rem;

        }

        span{
        width: 429px;
        height: auto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        color: #999999;
        margin-top: 1rem;
        margin-bottom: 1rem;
        }

        button{
            background: #0c54f4;
        border-radius: 30px;
        width: 384px;
        height: 50px;
        border: none;
        font-size: 18px;
        text-align: center;
        color: #FFFFFF;
        margin-top: 1rem;
        cursor: pointer;

        :hover{
        background: #0b43bf;
        transition: 0.8s;
        }
        }


`;

