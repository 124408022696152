/* eslint-disable no-unused-vars */
import React from "react";
import {
  Div,
  MainContainer,
  Container,
  Wrapper,
  Circle,
  Tabs,
  Tab,
} from "./RequestTypeStyle";
import {titleCase, getInitials} from "../../functions";

const WhatToSend = (props) => {

  const feedbackOwnerData = JSON.parse(props.feedbackOwnerData)

  return (
    <>
      <Div>
        <div className="margin-top-containers">
          <MainContainer>
            <Container>
              <Wrapper>
                {/* <Circle>{getInitials(titleCase(feedbackOwnerData.name))}</Circle> */}
                <img src={feedbackOwnerData.photo} alt="" />
                <p>{titleCase(feedbackOwnerData.name.split(/(\s+)/).filter( e => e.trim().length > 0)[0])} wants a feedback from you!</p>
                <span>
                  Don’t worry, you’ll be able to review and approve your feedback message
                  before submitting!
                </span>

                <Tabs>
                    <div>
                        <Tab onClick= { () => {props.setFeadbackType("video"); props.setActiveScreen("record")} }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                <rect x="3" y="6" width="12" height="12" rx="2" />
                            </svg>
                            <div><p>Video</p></div>
                        </Tab>
                    </div>
                  <div>
                      <Tab onClick= { () => {props.setFeadbackType("audio"); props.setActiveScreen("record")} }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <rect x="9" y="2" width="6" height="11" rx="3" />
                            <path d="M5 10a7 7 0 0 0 14 0" />
                            <line x1="8" y1="21" x2="16" y2="21" />
                            <line x1="12" y1="17" x2="12" y2="21" />
                        </svg>
                        <div><p>Audio</p></div>
                    </Tab>
                  </div>
                  <div>
                      <Tab onClick= { () => {props.setFeadbackType("text"); props.setActiveScreen("message")} }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="6" x2="20" y2="6" />
                            <line x1="4" y1="12" x2="20" y2="12" />
                            <line x1="4" y1="18" x2="16" y2="18" />
                        </svg>
                        <div><p>Text</p></div>
                      </Tab>
                  </div>
                </Tabs>
                
              </Wrapper>
            </Container>

          </MainContainer>
        </div>
      </Div>
      <br />
      <br />
      <br />
    </>
  );
};

export default WhatToSend;
