import styled from "styled-components";

export const Div = styled.div`
         /* height: 100vh; */
         display: flex;
         justify-content: center;
          align-items: center;

`;

export const MainContainer = styled.div`
          height: auto;

`;

export const Circle = styled.div`
    width: 120px;
    height: 120px;
    background: #0c54f4;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 67px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;


`;


export const Container = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        width: 480px;
        background-color: ${({ theme }) => theme.colors.white};
        margin: 0rem auto;
        border: 1px solid #E6E6E6;
        border-radius: 5px;
        padding: 30px;
        zoom: 0.95;

        @media (max-width: ${({ theme }) => theme.mobile}) {
          width: 100%;
            margin: 0 auto;
            border: none;
        }
`;

export const Wrapper = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        form {
            display: flex;
        flex-direction: column;
        width: 100%;
        align-items: initial;
        }

        img{
            width: 127px;
            height: 127px;
            border-radius: 50%;
        }

        p{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: #323B43;
        margin-bottom: 20px;
        margin-top: 20px;
        }

        span{
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #999999;
        margin-bottom: 20px;

        }

        form textarea{
            width: 384px;
            height: 200px;
            margin-bottom: 20px;
            background: #FAFAFA;
            border: 0.847339px solid #E6E6E6;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 15px;
            resize: none;
            
            &:focus{
                outline: none;
                border: 0.847339px solid #2196F3;
            }

            &::placeholder{
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                margin-top: 40px;
                color: #323B43;
            }

            &:focus::placeholder{
                color: #323B43;
            }

            @media (max-width: ${({ theme }) => theme.mobile}) {
                width: 100%;
            }
        }

        form button{
            width: 384px;
            height: 53px;
            background: #0c54f4;
            border-radius: 8px;
            border: none;
            color: #FFFFFF;
            outline: none;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            text-align: center;
            letter-spacing: -0.5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;


            :hover{
                background: #1C72D6;
                transition: 0.9s;
            }

            @media (max-width: ${({ theme }) => theme.mobile}) {
                width: 100%;
            }
        }

        button img{
            width: 20px;
            height: 20px;
        }
`;


export const FooterLogo = styled.div`
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 0rem;   

            p{
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 17px;
                color: #999999;
            }

            img{
                width: 130px;
                height: auto;
            }

            span{
                width: 51px;
                height: 19px;
                font-style: normal;
                font-weight: 900;
                font-size: 12.4008px;
                color: #323B43;
            }

`;

export const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;