/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";

export default function Index() {

    const [embedData, setEmbedData] = React.useState(null);  

    let url_params_array = window.location.pathname.split("/");
    let app = url_params_array.pop();

    if(embedData == null){
        // return <Loader />
    }

    const goBack = () => {
        window.top.close();
    }

    return (
        <div className="thank_you_body">
            <div className="container">
                <div className="thank-you">
                    
                    <img style={{marginRight: "100px"}} src="https://media.giphy.com/media/j1hlRjTRs0SUEHgHAW/giphy.gif" alt="" />
                    
                    <br></br>
                    <br></br>
                    <h1>Oooooh Ohhhhh you did it!</h1>
                    
                    <p style={{margin: '20px 0px'}}>You should be getting your confirmation emailed to you very soon!</p>
                    
                    <p>You have been cerified a geek, officially signed today;</p>
                    
                    <img className="signature" src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/7022/alow_sig.png" alt="A-Low Signature" />
                    
                    <div onClick={() => goBack()} className="integrations__item-button">
                        <button type="button" className="thank_you_btn button button--primary">
                            <p className="button__text">Go back to app</p>
                        </button>
                    </div>    

                </div>
            </div>
        </div>
    );
};