/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";
import { getWorkspace, createWorkspace, editWorkspace, deleteWorkspace } from "../../redux/workSpace/WorkSpaceAction";
import { useSelector, useDispatch } from "react-redux";
import { createDateFromMysql } from "../../functions";
import Loader from "../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";
toast.configure();

export default function Index() {
    const [createWorkspaceModalIsVisible, setCreateWorkspaceModalIsVisible] = React.useState(false);
    const [editWorkspaceModalIsVisible, seteditWorkspaceModalIsVisible] = React.useState(false);

    const dispatch = useDispatch();

    const [userToken, setUserToken] = React.useState(null);

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const getWork = useSelector((state) => state.getWork);
    const { loading, getWorkspaceData } = getWork;

    const createWork = useSelector((state) => state.createWork);
    const { loading: loadingCreate, error: errorCreate, message: messageCreate, workspaceData, success: successCreate } = createWork;

    const deleteWork = useSelector((state) => state.deleteWork);
    const { loading: loadingDelete, error: errorDelete, message: messageDelete, success: successDelete } = deleteWork;

    const editWork = useSelector((state) => state.editWork);
    const { loading: loadingEdit, error: errorEdit, message: messageEdit, success: successEdit, editWorkspaceData } = editWork;

    const [name, setName] = React.useState('');
    const [category, setCategory] = React.useState("Business");
    const [token, setToken] = React.useState(userData?.data?.token);
    const [workspace_id, setWorkspace_id] = React.useState('');
    const [editWorkSpaceId, setEditWorkspaceId] = React.useState('');
    const [editWorkSpaceName, setEditWorkspaceName] = React.useState('');


    // check if user is logged in
    React.useEffect(() => {
        if (!userData) {
            window.location.href = "/login";
        } else {
            setUserToken(userData?.data?.token);
            dispatch(getWorkspace({ token: userToken }));
        }
    }, [userData, dispatch, userToken]);

    //   create workspace
    React.useEffect(() => {
        if (workspaceData || successCreate === true) {
            toast.success(messageCreate, { transition: bounce });
            setCreateWorkspaceModalIsVisible(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (errorCreate) {
            toast.error(errorCreate, { transition: bounce });
        }
    }, [dispatch, workspaceData, successCreate, messageCreate, loadingCreate, errorCreate]);

    // delete workspace
    React.useEffect(() => {
        if (successDelete) {
            toast.success(messageDelete, { transition: bounce });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (errorDelete) {
            toast.error(errorDelete, { transition: bounce });
        }
    }, [dispatch, successDelete, messageDelete, loadingDelete, errorDelete]);

    // edit workspace
    React.useEffect(() => {
        if (editWorkspaceData && successEdit === true) {
            toast.success(messageEdit, { transition: bounce });
            seteditWorkspaceModalIsVisible(false);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (errorEdit) {
            toast.error(errorEdit, { transition: bounce });
        }
    }, [dispatch, editWorkspaceData, successEdit, messageEdit, loadingEdit, errorEdit]);

    // submit create workspace
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createWorkspace(name, token, category));
    };


    return (
        <>
            {loading || loadingCreate || loadingDelete || loadingEdit ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="settings-layout">

                    <div className="team__header-container">
                        <div className="team__header-title-group">
                            <div className="company__billet-container">
                                <p className="team__header-title">Workspaces</p>
                            </div>
                            <div className="team__header-description">Browse all your  settings from here. <br />
                                If you need more in-depth help or support, please get in touch.</div>
                        </div>
                        <div className="team__button">
                            <button onClick={() => setCreateWorkspaceModalIsVisible(true)} type="button" className="button_with_add_icon button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <p className="button__text">Create Workspace</p>
                            </button>
                        </div>
                    </div>

                    {/* <div className="settings-layout__nav">
                        <div className="settingsNav__list">
                            <a className="settingsNav__item settingsNav__item--stroke settingsNav__item--active" href="/workspaces">
                                <div className="settingsNav__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="3" y1="21" x2="21" y2="21" />
                                            <path d="M5 21v-14l8 -4v18" />
                                            <path d="M19 21v-10l-6 -4" />
                                            <line x1="9" y1="9" x2="9" y2="9.01" />
                                            <line x1="9" y1="12" x2="9" y2="12.01" />
                                            <line x1="9" y1="15" x2="9" y2="15.01" />
                                            <line x1="9" y1="18" x2="9" y2="18.01" />
                                        </svg>
                                    </div>
                                </div>
                                Workspace
                            </a>
                            <a className="settingsNav__item settingsNav__item--stroke" href="/profile">
                                <div className="settingsNav__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <circle cx="12" cy="7" r="4" />
                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                        </svg>
                                    </div>
                                </div>
                                Profile
                            </a>
                            <a className="settingsNav__item settingsNav__item--stroke" href="/branding">
                                <div className="settingsNav__item-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />
                                            <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />
                                            <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
                                            <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                                Branding
                            </a>
                            <a className="settingsNav__item settingsNav__item--stroke" href="/billing">
                            <div className="settingsNav__item-icon">
                                <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                                        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                                    </svg>
                                </div>
                            </div>
                            Billing
                        </a>
                        </div>
                    </div> */}

                    <div className="settings-layout__content">
                        <div className="documents__wrapper">
                            <div className="app_table__table">
                                <div className="table documents__table">
                                    <div className="table__container">
                                        <div className="table__innerContainer">
                                            <div className="table__row table__header">
                                                <div className="table__column table__column--date app_table__column--medium">Workspace name</div>
                                                <div className="table__column table__column--date app_table__column--medium">Date created</div>
                                                <div className="table__column table__column--action">Action</div>
                                            </div>


                                            {getWorkspaceData?.map((workspace) => (

                                                <div className="table__row table__dataRow" key={workspace._id}>
                                                    <div className="table__column table__column--text app_table__column--medium">{workspace.name}</div>
                                                    <div className="table__column table__column--date app_table__column--medium">{createDateFromMysql(workspace.createdAt)}</div>
                                                    <div className="table__column table__column--action">
                                                        <div className="documents__optionsDropdown table_action_list">

                                                            <button title="Preview" className="documents__optionsDropdownTrigger"
                                                                onClick={async (e) => {
                                                                    seteditWorkspaceModalIsVisible(true);
                                                                    setEditWorkspaceId(workspace.workspace_id);
                                                                    setEditWorkspaceName(workspace.name);
                                                                }}
                                                            >
                                                                <div className="table_action_options">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                        <line x1="16" y1="5" x2="19" y2="8" />
                                                                    </svg>
                                                                </div>
                                                            </button>

                                                            <button title="Delete" className="documents__optionsDropdownTrigger"
                                                                onClick={async (e) => {
                                                                    if (window.confirm('Are you sure you want to delete this workspace?')) {
                                                                        dispatch(
                                                                            await deleteWorkspace(
                                                                                token,
                                                                                workspace.workspace_id
                                                                            ));
                                                                    }
                                                                }}>
                                                                <div className="table_action_options">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ef2f2f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="4" y1="7" x2="20" y2="7" />
                                                                        <line x1="10" y1="11" x2="10" y2="17" />
                                                                        <line x1="14" y1="11" x2="14" y2="17" />
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                </div>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CREATE WORKSPACE MODAL */}
                {createWorkspaceModalIsVisible && (
                    <div className="app_modal">
                        <div className="app_modal__dialog" tabIndex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div className="requisiteModal__header">
                                        <p className="requisiteModal__title">Create New Workspace</p>
                                        <div onClick={() => setCreateWorkspaceModalIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <div className="form__field">

                                                            <label className="form__label">Workspace Name</label>
                                                            <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="E.g; My Business"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)} /></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer className="requisiteModal__footer">
                                    <button type="button" className="button button--primary"><p className="button__text" onClick={handleSubmit}>Save Workspace</p></button>
                                </footer>
                            </div>
                        </div>
                    </div>
                )}

                {/* EDIT WORKSPACE MODAL */}
                {editWorkspaceModalIsVisible && (
                    <div className="app_modal">
                        <div className="app_modal__dialog" tabIndex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div className="requisiteModal__header">
                                        <p className="requisiteModal__title">Edit Workspace</p>
                                        <div onClick={() => seteditWorkspaceModalIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <div className="form__field">

                                                            <label className="form__label">Workspace Name</label>
                                                            <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="E.g; My Business"
                                                                value={editWorkSpaceName}
                                                                onChange={(e) => setEditWorkspaceName(e.target.value)} /></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer className="requisiteModal__footer">
                                    <button type="button" className="button button--primary"><p className="button__text"
                                        onClick={async (e) => {
                                            dispatch(
                                                await editWorkspace(
                                                    {
                                                        token,
                                                        workspace_id: editWorkSpaceId,
                                                        name: editWorkSpaceName,
                                                        category,
                                                    }
                                                ));
                                        }}
                                    >Save Changes</p></button>
                                </footer>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
};