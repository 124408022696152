/* eslint-disable no-unused-vars */
import React from 'react';
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import {
  Screen,
  Cameras,
  NewVideoDiv,
  ImageDiv,
  Div,
  TabDiv,
  Tab1,
  LinkWrap,
  CloseScreen,
  UploadFileButton
} from "./RecorderStyle";
import { uploadVideos } from "../../redux/video/VideoAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";
import axios from "axios";
import $ from 'jquery';
let toBuffer = require('blob-to-buffer');

toast.configure();

/**
 * Checks whether the argument is an object
 * @param {any} o
 */
function isObject(o) {
  return o && !Array.isArray(o) && Object(o) === o;
}

/**
 * Checks whether constraints are valid
 * @param {MediaStreamConstraints} mediaType
 */
function validateMediaTrackConstraints(mediaType) {
  let supportedMediaConstraints = null;

  if (navigator.mediaDevices) {
    supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
  }

  if (supportedMediaConstraints === null) {
    return;
  }

  let unSupportedMediaConstraints = Object.keys(mediaType).filter(
    (constraint) => !supportedMediaConstraints[constraint]
  );

  if (unSupportedMediaConstraints.length !== 0) {
    let toText = unSupportedMediaConstraints.join(',');
    console.error(
      `The following constraints ${toText} are not supported on this browser.`
    );
  }
}

const noop = () => { };

/**
 *
 * @callback Callback
 * @param {Blob} blob
 *
 * @callback ErrorCallback
 * @param {Error} error
 *
 * @typedef MediaRecorderProps
 * @type {object}
 * @property {BlobPropertyBag} blobOptions
 * @property {boolean} recordScreen
 * @property {function} onStart
 * @property {Callback} onStop
 * @property {Callback} onDataAvailable
 * @property {ErrorCallback} onError
 * @property {object} mediaRecorderOptions
 * @property {MediaStreamConstraints} mediaStreamConstraints
 *
 * @typedef MediaRecorderHookOptions
 * @type {object}
 * @property {Error} error
 * @property {string} status
 * @property {Blob} mediaBlob
 * @property {boolean} isAudioMuted
 * @property {function} stopRecording,
 * @property {function} getMediaStream,
 * @property {function} clearMediaStream,
 * @property {function} startRecording,
 * @property {function} pauseRecording,
 * @property {function} resumeRecording,
 * @property {function} muteAudio
 * @property {function} unMuteAudio
 * @property {MediaStream} liveStream
 *
 * @param {MediaRecorderProps}
 * @returns {MediaRecorderHookOptions}
 */
function useMediaRecorder({
  blobOptions,
  recordScreen,
  onStop = noop,
  onStart = noop,
  onError = noop,
  onDataAvailable = noop,
  mediaRecorderOptions,
  mediaStreamConstraints = {}
}) {
  let mediaChunks = React.useRef([]);
  let mediaStream = React.useRef(null);
  let mediaRecorder = React.useRef(null);
  let [error, setError] = React.useState(null);
  let [status, setStatus] = React.useState('idle');
  let [mediaBlob, setMediaBlob] = React.useState(null);
  let [isAudioMuted, setIsAudioMuted] = React.useState(false);

  async function getMediaStream() {
    if (error) {
      setError(null);
    }

    setStatus('acquiring_media');

    try {
      let stream;

      if (recordScreen) {
        stream = await window.navigator.mediaDevices.getDisplayMedia(
          mediaStreamConstraints
        );
      } else {
        stream = await window.navigator.mediaDevices.getUserMedia(
          mediaStreamConstraints
        );
      }

      if (recordScreen && mediaStreamConstraints.audio) {
        let audioStream = await window.navigator.mediaDevices.getUserMedia({
          audio: mediaStreamConstraints.audio
        });

        audioStream
          .getAudioTracks()
          .forEach((audioTrack) => stream.addTrack(audioTrack));
      }

      mediaStream.current = stream;
      setStatus('ready');
    } catch (err) {
      setError(err);
      setStatus('failed');
    }
  }

  function clearMediaStream() {
    if (mediaRecorder.current) {
      mediaRecorder.current.removeEventListener(
        'dataavailable',
        handleDataAvailable
      );
      mediaRecorder.current.removeEventListener('stop', handleStop);
      mediaRecorder.current.removeEventListener('error', handleError);
      mediaRecorder.current = null;
    }

    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
      mediaStream.current = null;
      mediaChunks.current = [];
    }
  }

  async function startRecording() {
    if (error) {
      setError(null);
    }

    if (!mediaStream.current) {
      await getMediaStream();
    }

    mediaChunks.current = [];

    if (mediaStream.current) {
      mediaRecorder.current = new MediaRecorder(
        mediaStream.current,
        mediaRecorderOptions
      );
      mediaRecorder.current.addEventListener(
        'dataavailable',
        handleDataAvailable
      );
      mediaRecorder.current.addEventListener('stop', handleStop);
      mediaRecorder.current.addEventListener('error', handleError);
      mediaRecorder.current.start();
      setStatus('recording');
      onStart();
    }
  }

  function handleDataAvailable(e) {
    if (e.data.size) {
      mediaChunks.current.push(e.data);
    }
    onDataAvailable(e.data);
  }

  function handleStop() {
    let [sampleChunk] = mediaChunks.current;
    let blobPropertyBag = Object.assign(
      { type: sampleChunk.type },
      blobOptions
    );
    let blob = new Blob(mediaChunks.current, blobPropertyBag);

    setStatus('stopped');
    setMediaBlob(blob);
    onStop(blob);
  }

  function handleError(e) {
    setError(e.error);
    setStatus('idle');
    onError(e.error);
  }

  function muteAudio(mute) {
    setIsAudioMuted(mute);

    if (mediaStream.current) {
      mediaStream.current.getAudioTracks().forEach((audioTrack) => {
        audioTrack.enabled = !mute;
      });
    }
  }

  function pauseRecording() {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      setStatus('paused');
      mediaRecorder.current.pause();
    }
  }

  function resumeRecording() {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
      setStatus('recording');
    }
  }

  function stopRecording() {
    if (mediaRecorder.current) {
      setStatus('stopping');
      mediaRecorder.current.stop();
      // not sure whether to place clean up in useEffect?
      // If placed in useEffect the handler functions become dependencies of useEffect
      mediaRecorder.current.removeEventListener(
        'dataavailable',
        handleDataAvailable
      );
      mediaRecorder.current.removeEventListener('stop', handleStop);
      mediaRecorder.current.removeEventListener('error', handleError);
      mediaRecorder.current = null;
      clearMediaStream();
    }
  }

  React.useEffect(() => {
    if (!window.MediaRecorder) {
      throw new ReferenceError(
        'MediaRecorder is not supported in this browser. Please ensure that you are running the latest version of chrome/firefox/edge.'
      );
    }

    if (recordScreen && !window.navigator.mediaDevices.getDisplayMedia) {
      throw new ReferenceError(
        'This browser does not support screen capturing'
      );
    }

    if (isObject(mediaStreamConstraints.video)) {
      validateMediaTrackConstraints(mediaStreamConstraints.video);
    }

    if (isObject(mediaStreamConstraints.audio)) {
      validateMediaTrackConstraints(mediaStreamConstraints.audio);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type supplied to MediaRecorder is not supported by this browser.`
        );
      }
    }
  }, [mediaStreamConstraints, mediaRecorderOptions, recordScreen]);

  return {
    error,
    status,
    mediaBlob,
    setMediaBlob,
    isAudioMuted,
    stopRecording,
    getMediaStream,
    startRecording,
    pauseRecording,
    resumeRecording,
    clearMediaStream,
    muteAudio: () => muteAudio(true),
    unMuteAudio: () => muteAudio(false),
    get liveStream() {
      if (mediaStream.current) {
        return new MediaStream(mediaStream.current.getVideoTracks());
      }
      return null;
    }
  };
}

/**
 * @typedef LiveStreamPreviewProps
 * @type {object}
 * @property {MediaStream} stream
 *
 * @param {LiveStreamPreviewProps}
 */
function LiveStreamPreview({ stream }) {
  let videoPreviewRef = React.useRef();

  React.useEffect(() => {
    if (videoPreviewRef.current && stream) {
      videoPreviewRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <video ref={videoPreviewRef} autoPlay className="recorded_video_tag" />;
}

/**
 * @typedef PlayerProps
 * @type {object}
 * @property {Blob} srcBlob
 *
 * @param {PlayerProps}
 */
function Player({ srcBlob }) {
  if (!srcBlob) {
    return null;
  }
  return (
    <video
      src={URL.createObjectURL(srcBlob)}
      className="recorded_video_tag"
      autoPlay
      controls
    />
  );
}



export default function App() {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userData } = userLogin;

  const getWorkById = useSelector((state) => state.getWorkById);
  const { loading: loadingId, error: errorId, message: messageId, workSpaceInfo, success: successId } = getWorkById;

  const uploadVideo = useSelector((state) => state.uploadVideo);
  const { loading: loadingUpload, error: errorUpload, message: messageUpload, uploadVideoData, success: successUpload } = uploadVideo;

  let token = userData.data.token;

  let [recordScreen, setRecordScreen] = React.useState(false);
  const [recordingState, setRecordingState] = React.useState(false);
  const [microphoneOn, setMicrophoneOn] = React.useState(true);
  const [recordingDone, setRecordingDone] = React.useState(false);
  const [isCountdownDown, setIsCountdownDown] = React.useState(false);
  const [fileBase64, setFileBase64] = React.useState(null);
  const [workspaceId, setWorkspaceId] = React.useState(workSpaceInfo[0]?.workspace_id);
  const [isCountdownDownIntercepted, setIsCountdownDownIntercepted] = React.useState(false);
  const [isActionLoading, setIsActionLoading] = React.useState(false);

  // React.useEffect(() => {
  //   if(successUpload){
  //     // toast.success(messageUpload, { transition: bounce });
  //     setTimeout(() => {
  //       window.location = `/create-page?video_id=${uploadVideoData.data.videos.video_id}`
  //     }, 500);
  //   }else if(errorUpload){
  //     toast.error(errorUpload, { transition: bounce });
  //   }
  // }, [successUpload, errorUpload, messageUpload]);

  let {
    status,
    liveStream,
    mediaBlob,
    setMediaBlob,
    pauseRecording,
    resumeRecording,
    stopRecording,
    getMediaStream,
    startRecording,
    clearMediaStream,
    muteAudio,
  } = useMediaRecorder({
    recordScreen,
    mediaStreamConstraints: { audio: true, video: true }
  });

  //eslint-disable-next-line
  React.useEffect(() => clearMediaStream, []);

  const fmtMSS = (s) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  };

  const startTimer = () => {
    let seconds = localStorage.getItem("lastRecordTimer") == null ? 0 : Number(localStorage.getItem("lastRecordTimer"));
    window.video_seconds = setInterval(() => {
      seconds += 1;
      console.log(seconds);
      localStorage.setItem("lastRecordTimer", Number(seconds));
      if (document.querySelector('#currentCount_selector')) {
        document.querySelector('#currentCount_selector').innerHTML = fmtMSS(seconds);
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(window.video_seconds);
  };

  const startRecordingCountdown = () => {
    setIsCountdownDown(true);
    window.CountdownSeconds = 3;
    window.countdownTimeTicker = setInterval(() => {
      window.CountdownSeconds -= 1;
      if (document.querySelector('#countdownTimeTicker')) {
        document.querySelector('#countdownTimeTicker').innerHTML = window.CountdownSeconds;
      }
    }, 1000);
    setTimeout(() => {
      startMediaRecord();
    }, 1500);
    setTimeout(() => {
      clearInterval(window.countdownTimeTicker);
      setIsCountdownDown(false);
    }, 3000);
  };

  const startMediaRecord = async () => {
    localStorage.removeItem("lastRecordTimer");
    setMediaBlob(null);
    muteAudio(false);
    await getMediaStream();
    startRecording();
    setTimeout(() => {
      startTimer();
    }, 1000);
  };

  const stopMediaRecord = () => {
    stopRecording();
    setRecordingDone(true);
    stopTimer();
    localStorage.removeItem("lastRecordTimer");
  };

  const pauseMediaRecord = () => {
    stopTimer();
    pauseRecording();
  };

  const resumeMediaRecord = () => {
    startTimer();
    resumeRecording();
  };

  const stopRecordingAndClose = () => {
    setMediaBlob(null);
    stopRecording();
    setRecordingState(false);
    setRecordingDone(false);
    stopTimer();
    localStorage.removeItem("lastRecordTimer");
  };

  const muteRecording = () => {
    setMicrophoneOn(false);
    muteAudio(true);
  };

  const unmuteRecording = () => {
    setMicrophoneOn(true);
    muteAudio(false);
  };

  const rejectRecording = () => {
    setMediaBlob(null);
    stopRecording();
    setRecordingDone(false);
  };

  const stopCountdownRecording = () => {
    // rejectRecording()
    // clearInterval(window.countdownTimeTicker)
    // setIsCountdownDown(false)
    // setIsCountdownDownIntercepted(true)
  };

  if (isCountdownDownIntercepted) {
    setTimeout(() => {
      clearInterval(window.countdownTimeTicker);
      setIsCountdownDown(false);
      rejectRecording();
    }, 4000);
  }

  // React.useEffect(() => {
  //     if (fileBase64) {
  // dispatch(uploadVideos(token, workspaceId, fileBase64))
  //     }
  // }, [fileBase64]);

  const acceptRecording = () => {

    setIsActionLoading(true);

    toBuffer(mediaBlob, function (err, buffer) {
      if (err) throw err;
      const base64 = buffer.toString('base64');
      // check the size of the video is greater than 50mb
      if (buffer.byteLength > 50000000) {
        toast.error('File size uploaded is too big, maximum file size required is 50MB', { transition: bounce });
        setIsActionLoading(false);
        return;
      }

      let payload = {
        "token": token,
        "workspace_id": workspaceId,
        "file_base64": base64
      };

      var config = {
        method: 'post',
        url: `${BACKEND_BASE_URL}video/upload_video`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: payload
      };

      axios(config).then(function (response) {
        window.location = `/create-page?video_id=${response.data.data.videos.video_id}`;
      }).catch(function (error) {
        toast.error(error.message, { transition: bounce });
        setIsActionLoading(false);
      });

    });
  };

  const handleUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    const fileSize = file.size / 1024 / 1024;
    if (fileSize < 51) {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(file);

      reader.onloadend = () => {
        let video_blob = new Blob([reader.result]);
        setRecordingState(true);
        setRecordScreen(false);
        setRecordingDone(true);
        setMediaBlob(video_blob);
      };
    } else {
      toast.error('File size uploaded is too big, maximum file size required is 50MB', { transition: bounce });
    }
  };

  if (loadingUpload) {
    return <Loader />;
  }

  if (isActionLoading) {
    return <Loader />;
  }

  return (

    (() => {
      if (recordingState) {
        return (<Screen>

          {isCountdownDown && (
            <div className="countdownCover">
              <div>
                <h1 id="countdownTimeTicker">3</h1>
                <p>Get ready, recording starts soon 🤔</p>
                <button onClick={() => stopCountdownRecording()}>Stop Recording</button>
              </div>
            </div>
          )}

          <Cameras>

            {status === 'paused' && (
              <div className="pausedCover">
                <h4>Paused</h4>
              </div>
            )}

            {(status !== 'recording' && status !== 'paused') && (
              <div className="recordingActiveAndDoneState">

                {recordingDone === false && (
                  <div className="recordingCover">

                    <a target="_blank" href="https://testimonio.io/guides" rel='noreferrer'>
                      <div title="Watch guides" className="countdownButton">
                        <svg style={{ marginLeft: "-1px", width: "30px" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <circle cx="12" cy="12" r="9" />
                          <line x1="12" y1="17" x2="12" y2="17.01" />
                          <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                        </svg>
                      </div>
                    </a>

                    <div title="Start recording" className="recordingStartButton" onClick={() => { startRecordingCountdown(); }}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                        <rect x="3" y="6" width="12" height="12" rx="2" />
                      </svg>
                    </div>

                    {microphoneOn && (
                      <div title="Off microphone" className="muteButton" onClick={() => muteRecording()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-microphone" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="9" y="2" width="6" height="11" rx="3" />
                          <path d="M5 10a7 7 0 0 0 14 0" />
                          <line x1="8" y1="21" x2="16" y2="21" />
                          <line x1="12" y1="17" x2="12" y2="21" />
                        </svg>
                      </div>
                    )}
                    {!microphoneOn && (
                      <div title="On microphone" className="muteButton" onClick={() => unmuteRecording()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-microphone-off" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1="3" y1="3" x2="21" y2="21" />
                          <path d="M9 5a3 3 0 0 1 6 0v5a3 3 0 0 1 -.13 .874m-2 2a3 3 0 0 1 -3.87 -2.872v-1" />
                          <path d="M5 10a7 7 0 0 0 10.846 5.85m2.002 -2a6.967 6.967 0 0 0 1.152 -3.85" />
                          <line x1="8" y1="21" x2="16" y2="21" />
                          <line x1="12" y1="17" x2="12" y2="21" />
                        </svg>
                      </div>
                    )}

                  </div>
                )}

                {recordingDone && (
                  <div className="recordedVideoConfirmationCover">
                    <div className="recordedVideoConfirmationTitle">Do you want to use this video? 🥳</div>
                    <div className="recordedVideoConfirmationButtons">
                      <div onClick={() => rejectRecording()} className="recordedVideoConfirmationButton_cancel">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-thumb-down" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3" />
                        </svg>
                        No
                      </div>
                      <div onClick={() => acceptRecording()} className="recordedVideoConfirmationButton_accept">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-thumb-up" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3" />
                        </svg>
                        Yes
                      </div>
                    </div>
                  </div>
                )}

              </div>
            )}

            {(status === 'recording' || status === 'paused') && (
              <div className="recordingStopCover">

                <div>

                  {status === 'paused' ? (

                    <div onClick={() => resumeMediaRecord()} title="Resume" className="countdownButton">
                      <svg style={{ marginLeft: "-1px", width: "30px" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 4v16l13 -8z" />
                      </svg>
                    </div>

                  ) : (

                    <div onClick={() => pauseMediaRecord()} title="Pause" className="countdownButton">
                      <svg style={{ marginLeft: "-1px", width: "30px" }} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-help" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="6" y="5" width="4" height="14" rx="1" />
                        <rect x="14" y="5" width="4" height="14" rx="1" />
                      </svg>
                    </div>

                  )}

                </div>

                <div>
                  <div className="recordingTimer">
                    <p id="currentCount_selector"></p>
                  </div>
                  <div title="Stop recording" className="recordingStopButton" onClick={() => { stopMediaRecord(); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-stop" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="5" y="5" width="14" height="14" rx="2" />
                    </svg>
                  </div>
                </div>

                {/* MUTE WHILE RECORDING */}
                {microphoneOn && (
                  <div title="Off microphone" className="muteButton" onClick={() => muteRecording()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-microphone" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <rect x="9" y="2" width="6" height="11" rx="3" />
                      <path d="M5 10a7 7 0 0 0 14 0" />
                      <line x1="8" y1="21" x2="16" y2="21" />
                      <line x1="12" y1="17" x2="12" y2="21" />
                    </svg>
                  </div>
                )}
                {!microphoneOn && (
                  <div title="On microphone" className="muteButton" onClick={() => unmuteRecording()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-microphone-off" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="3" y1="3" x2="21" y2="21" />
                      <path d="M9 5a3 3 0 0 1 6 0v5a3 3 0 0 1 -.13 .874m-2 2a3 3 0 0 1 -3.87 -2.872v-1" />
                      <path d="M5 10a7 7 0 0 0 10.846 5.85m2.002 -2a6.967 6.967 0 0 0 1.152 -3.85" />
                      <line x1="8" y1="21" x2="16" y2="21" />
                      <line x1="12" y1="17" x2="12" y2="21" />
                    </svg>
                  </div>
                )}
                {/* END */}

              </div>
            )}

          </Cameras>

          <div style={{ width: "100%", height: "100%", position: "relative", borderRadius: "inherit" }}>
            <LiveStreamPreview stream={liveStream} style={{ minWidth: "100%", minHeight: "100%", width: "100%", height: "100%", objectFit: "fill" }} />
            <Player srcBlob={mediaBlob} style={{ minWidth: "100%", minHeight: "100%", width: "100%", height: "100%", objectFit: "fill" }} />
          </div>

          {status !== 'recording' && (
            <div style={{ width: "auto", height: "auto", cursor: "pointer", zIndex: 99999 }} onClick={() => { stopRecordingAndClose(); }}>
              <CloseScreen>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </CloseScreen>
            </div>
          )}

        </Screen>);
      } else {
        return (
          <NewVideoDiv>
            <Div>
              <ImageDiv onClick={() => { setRecordingState(true); setRecordScreen(false); }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                  <rect x="3" y="6" width="12" height="12" rx="2" />
                </svg>
                <span>Camera</span>
              </ImageDiv>
              <ImageDiv onClick={() => { setRecordingState(true); setRecordScreen(true); }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-screen-share" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M21 12v3a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h9" />
                  <line x1="7" y1="20" x2="17" y2="20" />
                  <line x1="9" y1="16" x2="9" y2="20" />
                  <line x1="15" y1="16" x2="15" y2="20" />
                  <path d="M17 4h4v4" />
                  <path d="M16 9l5 -5" />
                </svg>
                <span>Screen</span>
              </ImageDiv>
              <ImageDiv style={{ marginRight: "0px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cast" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="3" y1="19" x2="3.01" y2="19" />
                  <path d="M7 19a4 4 0 0 0 -4 -4" />
                  <path d="M11 19a8 8 0 0 0 -8 -8" />
                  <path d="M15 19h3a3 3 0 0 0 3 -3v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -2.8 2" />
                </svg>
                <span>Screen + camera</span>
                <span style={{ fontWeight: "400", fontSize: "15px", fontStyle: "italic", color: "#2d3c4a", paddingTop: "3px" }}>Coming soon</span>
              </ImageDiv>
            </Div>

            <TabDiv>
              <Tab1>
                <img src="/images/chrome.svg" alt="" />
                <span>
                  Record your camera & screen with the
                  <LinkWrap to="/">Chrome Extension</LinkWrap>
                  <span style={{ fontWeight: "400", fontSize: "15px", fontStyle: "italic", color: "#2d3c4a", paddingTop: "3px" }}> - Coming soon</span>
                </span>
              </Tab1>

              <UploadFileButton>
                <button type="button" >
                  Click here to
                  <buttonLink>upload video</buttonLink>
                </button>
                <input type='file' accept=".mp4" onChange={(event) => handleUpload(event)} />
              </UploadFileButton>

            </TabDiv>
          </NewVideoDiv>
        );
      }
    })()
  );

}