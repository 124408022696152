import styled from "styled-components";
import { Link } from "react-router-dom";


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;


       h1{
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        color: #323B43;
        margin-bottom: 1rem;
       }

`;

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 896px;
    height: 127px;

    div{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 24px;
    width: 212px;
    height: 120px;
    border: 1px solid #999999;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    span{
    font-size: 24px;
    text-align: right;
    color: #323B43;
    margin: 0px;
    font-weight: 900;
    }

    p{
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #999999;
    width: 151px;
    }

    }

`;

export const LinksDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 896px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    p{
        margin: 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #323B43;
        cursor: pointer;
    }

`;

export const Links = styled(Link)`
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 27px;
    color: #6140FF;
    text-decoration: none;
`;

export const TableDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 896px;

    table{
            width: 100%;
            /* border-collapse: collapse; */
            border-spacing: 0;
            box-sizing: border-box;
            cursor: pointer;

        thead{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 48px;
            /* border-bottom: 1px solid #E6E6E6; */
            box-sizing: border-box;
        }

        tbody{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: auto;
            box-sizing: border-box;
        }

        tr{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 1px solid #E6E6E6;
        }

        th{
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #999999;
            margin-bottom: 1rem;

        }

        td{
            height: 100px;
            
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
            

            img{
            width: 56px;
            height: 54px;
            border-radius: 4px;
            margin: 0px;
            margin-right: 10px;
            }

            p{
           
            font-size: 18px;
            line-height: 21px;
            color: #323B43;
            font-weight: 600;
            width: 100%;

            }

            span{
            font-size: 12px;
            color: #999999;
            }
        }       
    }
`;

export const Div1 = styled.div`
font-size: 18px;
color: #323B43;
font-weight: 700;
`;

export const Div2 = styled.div`
font-size: 12px;
color: #999999;
`;


export const VideoModalDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;  
    align-items: center;
    width: 100%;
    height: auto
`;


export const VideoModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 490px;
    height: 48px;
    padding: 1rem;

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img{
            width: 32px;
            height: 32px;
        }
    }
`;


export const Box = styled.div`
            border: 2px solid #000000;
            box-sizing: border-box;
            border-radius: 4px;
            width: 32px;
            height: 32px;
`;

export const ImageDiv2 = styled.div`
    width: 100%;
    height: auto;

    img{
        object-fit: cover;
        width: 100%;
        height: auto;
        max-height: 300px;
        border-radius: 5px;
    }
`;


export const VideoModalFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1rem;
    width: 100%;


    h1{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    margin-bottom: 1rem;
    }

    p{
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
    }

`;

export const IconDiv2 = styled.div`
    width: 100%;
    height: auto;
    border-radius: 5px;
    background: #FAFAFA;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    

   
`;

export const TextDiv2 = styled.div`
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #323B43;
        }

`;

export const Icon = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px

    img{
        width: 19px;
        height: 24px;
        margin-right: 10px;
    }
    p{
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        align-items: center;
        color: #323B43;
    }

`;


export const Notifications = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 48px 23px; */
    width: 272.33px;
    height: 361px;
    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

div{
    /* width: 226.33px; */
    border: 1px solid #E6E6E6;
    margin-bottom: 0.4rem;
    width: 250px;
    height: 56px;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


}

`;

export const NotificationLink = styled(Link)`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #323B43;
    /* margin-bottom: 50px; */
    text-decoration: none;
`;