import React from 'react';
import LandingPageBody from '../components/landingPageBody';

const LandingPage = () => {
  return (
    <div>

      <LandingPageBody />

    </div>
  );
};

export default LandingPage;