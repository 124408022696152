/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { TwitterPicker } from 'react-color';
import $ from 'jquery';
import Loader from "../loader/Loader";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import { getUserDetail } from "../../redux/account/AccountAction";

import {
  RowDiv,
  ColumnDiv1,
  ColumnDiv2,
  FormColorDiv,
  Form,
  TitleWrapper,
} from "./SetBrandStyle";
const SetBrand = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userData } = userLogin;

  const getWorkById = useSelector((state) => state.getWorkById);
  const { workSpaceInfo } = getWorkById;

  const [workspace_id, setWorkspace_id] = React.useState(workSpaceInfo[0]?.workspace_id);
  const [token, setToken] = React.useState(userData.data.token);

  const [buttonColorPicker, setButtonColorPicker] = React.useState("#000000");
  const [buttonColorPickerVisible, setButtonColorPickerVisible] = React.useState(false);

  const [descriptionColorPicker, setDescriptionColorPicker] = React.useState("#000000");
  const [descriptionColorPickerVisible, setDescriptionColorPickerVisible] = React.useState(false);

  const [titleColorPicker, setTitleColorPicker] = React.useState("#000000");
  const [labelColorPicker, setLabelColorPicker] = React.useState("#000000");
  const [titleColorPickerVisible, setTitleColorPickerVisible] = React.useState(false);
  const [labelColorPickerVisible, setLabelColorPickerVisible] = React.useState(false);

  const [brandLogo, setBrandLogo] = React.useState(null);
  const [whiteLabel, setWhiteLabel] = React.useState(null);
  const [whiteLabelText, setWhiteLabelText] = React.useState("");

  const [brandVideoLink, setBrandVideoLink] = React.useState("");
  const [brandTitleText, setBrandTitleText] = React.useState("");
  const [brandDescriptionText, setBrandDescriptionText] = React.useState("");
  const [brandButtonLink, setBrandButtonLink] = React.useState("");
  const [brandButtonRadius, setBrandButtonRadius] = React.useState("");
  const [brandButtonText, setBrandButtonText] = React.useState("");

  const [isActionLoading, setIsActionLoading] = React.useState(false);


  const [userToken, setUserToken] = React.useState(null);

  const getUser = useSelector((state) => state.getUser);
  const { loading, user } = getUser;

  const [acessLevel, setAcesslevel] = React.useState([]);

  React.useEffect(() => {
    if (!userData) {
      window.location.href = "/login";
    } else {
      setUserToken(userData?.data?.token);
      dispatch(getUserDetail(userData.data.token));
    }
  }, [userData, dispatch]);


  React.useEffect(() => {
    if (user) {
      setAcesslevel(user?.data?.profile?.acls);
    }
  }, [user]);

  let feedback_id = window.location.search.split("=")[1];
  //check if user is logged in 
  React.useEffect(() => {

    if (!userData) {
      window.location.href = "/login";
    } else {

      var config = {
        method: 'get',
        url: `${BACKEND_BASE_URL}feedback/get_feedback_by_id?feedback_id=${feedback_id}`,
        headers: {}
      };

      axios(config).then(function (response) {
        let activeVideo = response.data.data.feedback;

        setBrandVideoLink(activeVideo?.url);
        setBrandLogo(activeVideo?.page?.logo?.url);
        setWhiteLabel(activeVideo?.page?.white_label?.url);
        setBrandTitleText(activeVideo?.page?.title?.text);
        setWhiteLabelText(activeVideo?.page?.white_label_text?.text);
        setBrandDescriptionText(activeVideo?.page?.description?.text);
        setBrandButtonLink(activeVideo?.page?.call_to_action?.url);
        setBrandButtonRadius(activeVideo?.page?.call_to_action?.radius);
        setBrandButtonText(activeVideo?.page?.call_to_action?.text);
        setButtonColorPicker(activeVideo?.page?.call_to_action?.color);
        setTitleColorPicker(activeVideo?.page?.title?.color);
        setLabelColorPicker(activeVideo?.page?.white_label_text?.color);
        setDescriptionColorPicker(activeVideo?.page?.description?.color);

      }).catch(function (error) {
        toast.error(error.message, { transition: bounce });
      });

    }

  }, []);

  // if(isActionLoading){
  //   return <Loader/>
  // }

  const handleUpload = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize < 11) {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(file);

      reader.onloadend = () => {
        //convert to base64
        const base64 = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      };

      const reader2 = new window.FileReader();
      reader2.readAsDataURL(file);
      reader2.onloadend = () => {
        setBrandLogo(reader2.result);
      };
    } else {
      alert("File size uploaded is too big, maximum file size required is 10MB.");
    }

  };

  const handleUploadWhiteLabel = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    if (fileSize < 11) {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(file);

      reader.onloadend = () => {
        //convert to base64
        const base64 = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
      };

      const reader2 = new window.FileReader();
      reader2.readAsDataURL(file);
      reader2.onloadend = () => {
        setWhiteLabel(reader2.result);
      };
    } else {
      alert("File size uploaded is too big, maximum file size required is 10MB.");
    }

  };

  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  const savePageSettings = (event) => {
    event.preventDefault();

    setIsActionLoading(true);

    let payload = {
      "feedback_id": feedback_id,
      "token": token,
      "page": {
        "logo": {
          "url": isValidHttpUrl(brandLogo) ? "" : brandLogo,
        },
        "white_label": {
          "url": isValidHttpUrl(whiteLabel) ? "" : whiteLabel,
        },
        "white_label_text": {
          "text": event.target.whiteLabelText.value,
          "color": labelColorPicker
        },
        "title": {
          "text": event.target.brandTitleText.value,
          "color": titleColorPicker
        },
        "description": {
          "text": event.target.brandDescriptionText.value,
          "color": descriptionColorPicker
        },
        "call_to_action": {
          "text": event.target.brandButtonText.value,
          "color": buttonColorPicker,
          "font": "",
          "url": event.target.brandButtonLink.value,
          "radius": brandButtonRadius
        }
      }
    };

    var config = {
      method: 'put',
      url: `${BACKEND_BASE_URL}feedback/edit_feedback`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: payload
    };

    axios(config).then(function (response) {
      toast.success(response.data.message, { transition: bounce });
      window.location.href = "/videos";
    }).catch(function (error) {
      toast.error(error.message, { transition: bounce });
      setIsActionLoading(false);
    });

  };

  return (
    <>
      {isActionLoading ? <Loader /> : null}
      <RowDiv>
        <ColumnDiv1>
          <FormColorDiv>
            <Form className="page_customize_form" onSubmit={(event) => savePageSettings(event)}>

              <div className="upload-btn-wrapper">
                <button className='brand_logo_upload_btn'>
                  <img src="/images/emails.svg" alt="avatar" />
                  Import logo
                </button>
                <input name="brandLogo" defaultValue={brandLogo} type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUpload(event)} />
              </div>

              <p>Page</p>

              <div className="customization_input_cover">
                <input name="brandTitleText" defaultValue={brandTitleText} onBlur={() => setTitleColorPickerVisible(false)} onChange={(event) => customizePageTitle(event.target.value)} type="text" placeholder="Title Sample" />
                <span onClick={(event) => setTitleColorPickerVisible((picker_state) => !picker_state)} className="color_indicator" style={{ background: titleColorPicker }}></span>
                {titleColorPickerVisible && (
                  <TwitterPicker
                    color={titleColorPicker}
                    colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                    triangle={"top-right"}
                    onChangeComplete={(color) => { setTitleColorPicker(color.hex); }}
                  />
                )}
              </div>

              <div className="customization_input_cover">
                <input name="brandDescriptionText" defaultValue={brandDescriptionText} onBlur={() => setDescriptionColorPickerVisible(false)} onChange={(event) => customizePageDescription(event.target.value)} type="text" placeholder="Video description or message here." />
                <span onClick={(event) => setDescriptionColorPickerVisible((picker_state) => !picker_state)} className="color_indicator" style={{ background: descriptionColorPicker }}></span>
                {descriptionColorPickerVisible && (
                  <TwitterPicker
                    color={descriptionColorPicker}
                    colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                    triangle={"top-right"}
                    onChangeComplete={(color) => { setDescriptionColorPicker(color.hex); }}
                  />
                )}
              </div>

              <p>Call-to-Action</p>

              <div className="customization_input_cover">
                <input name="brandButtonLink" defaultValue={brandButtonLink} onChange={(event) => customizePageButtonLink(event.target.value)} type="text" style={{ width: '95%' }} placeholder="https://your-website.com" />
              </div>

              <div className="customization_input_cover">
                <input name="brandButtonText" defaultValue={brandButtonText} onBlur={() => setButtonColorPickerVisible(false)} onChange={(event) => customizePageButton(event.target.value)} type="text" placeholder="I’m a button" />
                <span onClick={(event) => setButtonColorPickerVisible((picker_state) => !picker_state)} className="color_indicator" style={{ background: buttonColorPicker }}></span>
                {buttonColorPickerVisible && (
                  <TwitterPicker
                    color={buttonColorPicker}
                    colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                    triangle={"top-right"}
                    onChangeComplete={(color) => { setButtonColorPicker(color.hex); }}
                  />
                )}
              </div>

              <select name="brandButtonRadius" onChange={(event) => customizePageButtonShape(event.target.value)}>
                <option value="100px">Rounded</option>
                <option value="5px">Sqaure</option>
              </select>
              
                  
                    <div className="customization_input_cover">
                      <input name="whiteLabelText" defaultValue={whiteLabelText} onBlur={() => setLabelColorPickerVisible(false)} onChange={(event) => customizeWhiteLabel(event.target.value)} type="text" placeholder="Video message by" />
                      <span onClick={(event) => setLabelColorPickerVisible((picker_state) => !picker_state)} className="color_indicator" style={{ background: labelColorPicker }}></span>
                      {labelColorPickerVisible && (
                        <TwitterPicker
                          color={labelColorPicker}
                          colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF']}
                          triangle={"top-right"}
                          onChangeComplete={(color) => { setLabelColorPicker(color.hex); }}
                        />
                      )}
                    </div>

                    <br />

                    <div className="upload-btn-wrapper">
                      <button className='brand_logo_upload_btn'>
                        <img src="/images/emails.svg" alt="avatar" />
                        Import white label
                      </button>
                      <input name="brandLogo" defaultValue={brandLogo} type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUploadWhiteLabel(event)} />
                    </div>
                

              <button type="submit" className="save_page_styles_btn">Save Page Settings</button>

            </Form>
          </FormColorDiv>
        </ColumnDiv1>
        <ColumnDiv2>
          <TitleWrapper>
            <div className="video_page_brand_logo">
              {brandLogo == null ? (
                <img src="https://placeholderlogo.com/img/placeholder-logo-1.png" alt='' />
              ) : (
                <img src={brandLogo} alt='' />
              )}
            </div>
            <p style={{ color: titleColorPicker }} className="video_page_title">
              {brandTitleText}
            </p>
            <div className="video_page_prev">
              <video src={brandVideoLink} controls playsInline></video>
            </div>
            <span style={{ color: descriptionColorPicker }} className="video_page_description">
              {brandDescriptionText}
            </span>
            <a target="_blank" className="video_page_button_link" href={brandButtonLink} rel='noreferrer'>
              <button style={{ background: buttonColorPicker, borderRadius: brandButtonRadius, display: brandButtonLink.length > 0 ? "block" : "none" }} type="button" className="video_page_button">
                {brandButtonText}
              </button>
            </a>
            
                <div className="brand_logo">
                  <p style={{ color: labelColorPicker }} className="video_page_label">
                    {whiteLabelText}
                  </p>
                  {whiteLabel === null ? (
                    <img src="../../images/A.png" alt="" />
                  ) : (
                    <img src={whiteLabel} alt="" />
                  )}
                </div>
              


            <br />
            <br />
            <br />
          </TitleWrapper>
        </ColumnDiv2>
      </RowDiv>
    </>
  );
};

// JAVASCRIPTING

const customizePageTitle = (text) => {
  $(".video_page_title").html(text);
};

const customizeWhiteLabel = (text) => {
  $(".video_page_label").html(text);
};

const customizePageTitleColor = (value) => {
  $(".video_page_title").css("color", value);
};

const customizePageDescription = (text) => {
  $(".video_page_description").html(text);
};

const customizePageDescriptionColor = (value) => {
  $(".video_page_description").css("color", value);
};

const customizePageButton = (text) => {
  $(".video_page_button").html(text);
};

const customizePageButtonLink = (text) => {
  if (text.length > 0) {
    $(".video_page_button").show();
    let url = "https://" + text.replace(/(^\w+:|^)\/\//, "");
    $(".video_page_button_link").attr('href', url);
  } else {
    $(".video_page_button").hide();
    $(".video_page_button_link").attr('href', "#");
  }
};

const customizePageButtonShape = (value) => {
  console.log(value);
  $(".video_page_button").css("border-radius", value);
};

const customizePageButtonColor = (value) => {
  $(".video_page_button").css("background", value);
};

export default SetBrand;
