/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  LoginContainer,
  LoginTitle,
  TextWrapper,
  LoginForm,
  LoginInput,
  LoginButton,
  FirstFormDiv,
  InputDiv,
  IconDiv,
} from "./CreateWorkSpaceStyle";
import { createWorkspace } from "../../../redux/workSpace/WorkSpaceAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {bounce} from "../../../toast"
import DashboardNavigation from "../../DashboardNavigation";
toast.configure();
const CreateWorkSpace = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userData } = userLogin;

  const createWork = useSelector((state) => state.createWork);
  const { loading:loadingCreate, error:errorCreate, message:messageCreate, workspaceData, success:successCreate } = createWork;

  const [name, setName] = useState("");
  const [category, setCategory] = useState("Business");
  const [token, setToken] = useState(userData?.data?.token);


  useEffect(() => {
    if(!userData){
      window.location.href = "/login";
    }
  }, [userData]);

  React.useEffect(() => {
    if(workspaceData || successCreate === true){
        toast.success(messageCreate, { transition: bounce });
        localStorage.setItem("workSpaceInfo", JSON.stringify(workspaceData.data.workspaces));
        setTimeout(() => {
            window.location.href = "/dashboard";
        }, 300);
    }
    if(errorCreate){
        toast.error(errorCreate, { transition: bounce });
    }
}, [dispatch, workspaceData, successCreate, messageCreate, errorCreate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createWorkspace(name, token, category));
  };

  const createInitialWorkspace = (e) => {
    e.preventDefault();
    dispatch(createWorkspace("Get started", token, category));
  };

  return (
    <>
      <div style={{pointerEvents: "none"}}>
        <DashboardNavigation/>
      </div>
      <LoginContainer>
        <TextWrapper>
          <LoginTitle>Create Your First Workspace</LoginTitle>
        </TextWrapper>
        {loadingCreate && <Loader />}
        <LoginForm onSubmit={handleSubmit}>
          <FirstFormDiv>
            <IconDiv>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <line x1="3" y1="21" x2="21" y2="21" />
                  <path d="M5 21v-14l8 -4v18" />
                  <path d="M19 21v-10l-6 -4" />
                  <line x1="9" y1="9" x2="9" y2="9.01" />
                  <line x1="9" y1="12" x2="9" y2="12.01" />
                  <line x1="9" y1="15" x2="9" y2="15.01" />
                  <line x1="9" y1="18" x2="9" y2="18.01" />
              </svg>
            </IconDiv>

            <InputDiv>
              <LoginInput
                placeholder="Business Name"
                name="businessName"
                id="businessName"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputDiv>
          </FirstFormDiv>

          <LoginButton onClick={handleSubmit}>Create Now</LoginButton>

          <div onClick={createInitialWorkspace} style={{marginTop: "30px"}}>
            <p style={{fontWeight: "500", color: "#0c55f4", cursor: "pointer"}}>Skip this step</p>
          </div>

        </LoginForm>
      </LoginContainer>
    </>
  );
};

export default CreateWorkSpace;
