/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";
import {saveBrands, getWorkspaceById} from "../../redux/workSpace/WorkSpaceAction";
import {useSelector, useDispatch} from "react-redux";
import Loader from "../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {bounce} from "../../toast";
toast.configure();

export default function Index() {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const [name, setName] = React.useState('');
    const [background_color, setBackground_color] = React.useState("");
    const [button_color, setButton_color] = React.useState('');
    const [description_color, setDescription_color] = React.useState('');
    const [logo, setLogo] = React.useState('');
    const [rounded_button, setRounded_button] = React.useState(true);
    const [text_color, setText_color] = React.useState('');  
    const [title_color, setTitle_color] = React.useState('');
    const [workspace_id, setWorkspace_id] = React.useState('');
    const [token, setToken] = React.useState(userData?.data?.token);

  
    const getWorkById = useSelector((state) => state.getWorkById);
    const { loading:loadingId, error:errorId, message:messageId, workSpaceInfo, success:successId } = getWorkById;

    const saveBrand = useSelector((state) => state.saveBrand);
    const { loading:loadingSave, error:errorSave, message:messageSave, success:successSave, saveBrandsData } = saveBrand;


    React.useEffect(() => {
        if(!userData) {
            window.location.href = "/login";
        }
    }, [userData, workSpaceInfo]);
    

    React.useEffect(() => {
        let data = workSpaceInfo;
        if(data){
            setName(data[0]?.name);
            setBackground_color(data[0]?.brand_styles?.background_color);
            setButton_color(data[0]?.brand_styles?.button_color);
            setDescription_color(data[0]?.brand_styles?.description_color);
            setLogo(data[0]?.brand_styles?.logo);
            setRounded_button(data[0]?.brand_styles?.rounded_button);
            setText_color(data[0]?.brand_styles?.text_color);
            setTitle_color(data[0]?.brand_styles?.title_color);
            setWorkspace_id(data[0]?.brand_styles?.workspace_id);
            setWorkspace_id(data[0]?.workspace_id);
            console.log(data[0]?.workspace_id);
        }
    }, [workSpaceInfo]);
  

    React.useEffect(() => { 
        if(successSave){
          toast.success(messageSave, { transition: bounce });
          setTimeout(() => {
            // window.location.reload();
            dispatch(getWorkspaceById(token, workspace_id));
            }, 2000);
        }else{
          toast.error(errorSave, { transition: bounce });
        }
      }, [successSave, messageSave, errorSave, dispatch, token, workspace_id]);
      
      if(loadingSave){
        return <Loader />
        }

      const handleSave = (e) => {
        e.preventDefault();
        dispatch(saveBrands({token: userData.data.token, workspace_id, title_color, description_color, text_color, button_color, rounded_button, background_color}));
      };
    
  return (
        <div className="main-layout__container">
            <div className="settings-layout">
                <p className="settings-layout__title">Branding</p>
                <p className="settings-layout__subTitle">
                    Browse all your  settings from here. <br />
                    If you need more in-depth help or support, please get in touch.
                </p>

                <div className="settings-layout__nav">
                    <div className="settingsNav__list">
                        <a className="settingsNav__item settingsNav__item--stroke" href="/workspaces">
                            <div className="settingsNav__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <line x1="3" y1="21" x2="21" y2="21" />
                                      <path d="M5 21v-14l8 -4v18" />
                                      <path d="M19 21v-10l-6 -4" />
                                      <line x1="9" y1="9" x2="9" y2="9.01" />
                                      <line x1="9" y1="12" x2="9" y2="12.01" />
                                      <line x1="9" y1="15" x2="9" y2="15.01" />
                                      <line x1="9" y1="18" x2="9" y2="18.01" />
                                    </svg>
                                </div>
                            </div>
                            Workspace
                        </a>
                        <a className="settingsNav__item settingsNav__item--stroke" href="/profile">
                            <div className="settingsNav__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <circle cx="12" cy="7" r="4" />
                                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                    </svg>
                                </div>
                            </div>
                            Profile
                        </a>
                        <a className="settingsNav__item settingsNav__item--stroke settingsNav__item--active" href="/branding">
                            <div className="settingsNav__item-icon">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />
                                        <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />
                                        <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
                                        <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
                                    </svg>
                                </div>
                            </div>
                            Branding
                        </a>
                        {/* <a className="settingsNav__item settingsNav__item--stroke" href="/billing">
                            <div className="settingsNav__item-icon">
                                <div> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                                        <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                                    </svg>
                                </div>
                            </div>
                            Billing
                        </a> */}
                    </div>
                </div>

                <div className="settings-layout__content">
                    <div className="profile">
                        <form className="settings__form">

                            <div className="profile_cover">
                                <div className="settings__block">
                                    <h1 className="settings__title">Video Page</h1>
                                    <div className="settings__block--small">
                                        <div className="settings__field">
                                            <div className="form__field">
                                                <label className="form__label">Title Color</label>
                                                <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="#000000" 
                                                value={title_color}
                                                onChange={(e) => setTitle_color(e.target.value)}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="settings__field">
                                            <div className="form__field">
                                                <label className="form__label">Description Color</label>
                                                <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="#000000" 
                                                value={description_color}
                                                onChange={(e) => setDescription_color(e.target.value)}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="settings__field">
                                            <div className="form__field">
                                                <label className="form__label">Text Color</label>
                                                <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="#000000"
                                                value={text_color}
                                                onChange={(e) => setText_color(e.target.value)}
                                                /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{"margin-left": "50px"}}  className="settings__block">
                                    <h1 className="settings__title">Call To Action</h1>
                                    <div className="settings__block--small">
                                        <div className="settings__field">
                                            <div className="form__field">
                                                <label className="form__label">Button Color</label>
                                                <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="#000000" 
                                                 value={button_color}
                                                    onChange={(e) => setButton_color(e.target.value)}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="settings__field">
                                            <div className="form__field">
                                                <label className="form__label">Background Color</label>
                                                <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="#000000" 
                                                value={background_color}
                                                onChange={(e) => setBackground_color(e.target.value)}
                                                /></div>
                                            </div>
                                        </div>
                                        <div className="settings__field">
                                            <div className="form__field">
                                                <label className="form__label">Rounded Button</label>
                                                <div className="form__input-wrapper">
                                                    <select  className="form__input"
                                                    value={rounded_button}
                                                    onChange={(e) => setRounded_button(e.target.value)}
                                                    >
                                                        <option value="true">True</option>
                                                        <option value="false">False</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile__email-button">
                                            <button style={{float: "right"}} type="button" className="button button--secondary" onClick={handleSave}>
                                                <p className="button__text">Update Brand Styles</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
};