import React from 'react';

const InfoModal = ({ onClose }) => {
    return (
        <>
            <div className="app_modal">
                <div style={{ width: "auto" }} className="app_modal__dialog" >
                    <div className="requisiteModal">
                        <div className="requisiteModal__inner">
                            <div style={{ marginBottom: "10px" }} className="requisiteModal__header">
                                <p className="requisiteModal__title"></p>
                                <div onClick={onClose} className="requisiteModal__header-icon">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="18" y1="6" x2="6" y2="18" />
                                            <line x1="6" y1="6" x2="18" y2="18" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="requisiteModal__content">
                                <div>
                                    <div className="settings__block">
                                        <div style={{ width: "500px", display: 'flex', justifyContent: 'center', flexDirection: "column", alignItems: 'center' }} className="settings__form-group settings__block--small">
                                            <img src="/images/info.svg" alt="" style={{ width: "160px" }} />
                                            <h2>No Access</h2>
                                            <br />
                                            <p style={{textAlign: "center"}}>You do not have access to this feature please upgrade</p>
                                            <p style={{marginTop: "10px"}}> <a href='https://bluecodetechnologies.freshdesk.com/support/tickets/new' target="_blank" rel="noopener noreferrer" style={{ color: 'blue'}} onClick={onClose}>Click here to upgrade</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InfoModal;