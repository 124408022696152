import React from "react";
import "./index.css";
import DashboardNavigation from "../components/DashboardNavigation";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardFooter from "../components/DashboardFooter";
import BillingBody from "../components/BillingBody";

const Billing = () => {
  return (
    <div className="main-layout">
          <DashboardNavigation />
          <div className="main-layout__content">
            <DashboardSidebar />
            <BillingBody />
          </div>
          <DashboardFooter />
    </div>
  );
};

export default Billing;
