/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import Loader from "../loader/Loader";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import axios from "axios";
import "./index.css";

export default function Index() {

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;
    const [token, setToken] = React.useState(userData.data.token);

    const getWorkById = useSelector((state) => state.getWorkById);
    const { workSpaceInfo } = getWorkById;

    const [workspace_id, setWorkspace_id] = React.useState(workSpaceInfo[0]?.workspace_id);

    const [integrationData, setIntegrationData] = React.useState(null);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [isRefreshSet, setIsRefreshSet] = React.useState(false);

    React.useEffect(() => {
        setIsLoadingState(true);
        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}integrations/get_integrations?token=${token}&workspace_id=${workspace_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            setIntegrationData(response.data.data.integrations.apps);
        }).catch(function (error) {
            setIntegrationData([]);
        });

    }, []);

    const disconnectApp = (app) => {

        setIsLoadingState(true);

        let payload = {
            "workspace_id": workspace_id,
            "token": token,
            "app_name": app
        };

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}integrations/disconnect`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: payload
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            window.location.reload();
        }).catch(function (error) {
            setIsLoadingState(false);
            alert(error.message);
        });
    };

    const connectDropBox = () => {
        setIsLoadingState(true);
        setIsRefreshSet(true);

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}integrations/dropbox/authentication?token=${token}&workspace_id=${workspace_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            window.open(response.data.data, "_blank");
            setIsLoadingState(false);
        }).catch(function (error) {
            setIsLoadingState(false);
        });

    };

    const connectGoogleDrive = () => {
        setIsLoadingState(true);
        setIsRefreshSet(true);

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}integrations/google_drive/authentication?token=${token}&workspace_id=${workspace_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            window.open(response.data.data, "_blank");
            setIsLoadingState(false);
        }).catch(function (error) {
            setIsLoadingState(false);
        });
    };

    const connectOneDrive = () => {
        setIsLoadingState(true);
        setIsRefreshSet(true);

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}integrations/one_drive/authentication?token=${token}&workspace_id=${workspace_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            window.open(response.data.data, "_blank");
            setIsLoadingState(false);
        }).catch(function (error) {
            setIsLoadingState(false);
        });
    };

    const connectBox = () => {
        setIsLoadingState(true);
        setIsRefreshSet(true);

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}integrations/box/authentication?token=${token}&workspace_id=${workspace_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            window.open(response.data.data, "_blank");
            setIsLoadingState(false);
        }).catch(function (error) {
            setIsLoadingState(false);
        });
    };

    return (
        <>
            {isLoadingState ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="integrations__wrapper">
                    <p className="integrations__title">Integrations</p>
                    <p className="integrations__subTitle">Connect and import/export files with the tools you already know and love.</p>
                    <ul className="integrations__list">
                        <li className="integrations__item">
                            <div className="integrations__item-icon-wrapper"><img src="./images/integration_google_drive.svg" className="integrations__item-icon" alt="title" /></div>
                            <p className="integrations__item-title">Google Drive</p>
                            <p className="integrations__item-desc">Easily import videofiles from Google Drive.</p>
                            <div style={{ marginTop: "50px" }} className="integrations__item-button">
                                {integrationData?.filter((app) => { return app.name === "google_drive"; })[0]?.is_connected ? (
                                    <button onClick={() => disconnectApp("google_drive")} type="button" className="button button--secondary"><p className="button__text">Disconnect</p></button>
                                ) : (
                                    <button onClick={() => connectGoogleDrive()} type="button" className="button button--primary"><p className="button__text">Connect</p></button>
                                )}
                            </div>
                        </li>
                        <li className="integrations__item">
                            <div className="integrations__item-icon-wrapper"><img src="./images/integration_drop_box.svg" className="integrations__item-icon" alt="title" /></div>
                            <p className="integrations__item-title">Dropbox</p>
                            <p className="integrations__item-desc">Easily import videos files from Dropbox.</p>
                            <div style={{ marginTop: "50px" }} className="integrations__item-button">
                                {integrationData?.filter((app) => { return app.name === "dropbox"; })[0]?.is_connected ? (
                                    <button onClick={() => disconnectApp("dropbox")} type="button" className="button button--secondary"><p className="button__text">Disconnect</p></button>
                                ) : (
                                    <button onClick={() => connectDropBox()} type="button" className="button button--primary"><p className="button__text">Connect</p></button>
                                )}
                            </div>
                        </li>
                        <li className="integrations__item">
                            <div className="integrations__item-icon-wrapper"><img src="./images/integration_one_drive.svg" className="integrations__item-icon" alt="title" /></div>
                            <p className="integrations__item-title">One Drive</p>
                            <p className="integrations__item-desc">Easily import video files from One Drive.</p>
                            <div style={{ marginTop: "50px" }} className="integrations__item-button">
                                {integrationData?.filter((app) => { return app.name === "one_drive"; })[0]?.is_connected ? (
                                    <button onClick={() => disconnectApp("one_drive")} type="button" className="button button--secondary"><p className="button__text">Disconnect</p></button>
                                ) : (
                                    <button onClick={() => connectOneDrive()} type="button" className="button button--primary"><p className="button__text">Connect</p></button>
                                )}
                            </div>
                        </li>
                        <li className="integrations__item">
                            <div className="integrations__item-icon-wrapper"><img src="./images/integration_box.svg" className="integrations__item-icon" alt="title" /></div>
                            <p className="integrations__item-title">Box</p>
                            <p className="integrations__item-desc">Easily import video files from Box.</p>
                            <div style={{ marginTop: "50px" }} className="integrations__item-button">
                                {integrationData?.filter((app) => { return app.name === "box"; })[0]?.is_connected ? (
                                    <button onClick={() => disconnectApp("box")} type="button" className="button button--secondary"><p className="button__text">Disconnect</p></button>
                                ) : (
                                    <button onClick={() => connectBox()} type="button" className="button button--primary"><p className="button__text">Connect</p></button>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};