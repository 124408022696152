import React from 'react';
import {
    MainContainer,
    Div,
    Container,
    LoginTitle,
    TextWrapper,
    Button,
    Contain,
    Circle,
} from "./SuccessRequestStyle";
const RecordRequest = (props) => {
    const testimonyData = JSON.parse(props.testimonyData);
    console.log(testimonyData);
    return (
        <>
            <Div>
                <div className="margin-top-containers">
                    <MainContainer>
                        <Container>
                            <Contain>
                                <Circle>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M5 12l5 5l10 -10" />
                                    </svg>
                                </Circle>
                            </Contain>

                            <TextWrapper>
                                <LoginTitle>
                                    Thanks so much for your time access your reward now!
                                </LoginTitle>
                                <span>
                                    {testimonyData.reward_dec}
                                </span>
                                <br />
                                <span>
                                   <p>Reward Access information:</p> {testimonyData.reward_access_info}
                                </span>
                            </TextWrapper>

                            <Button to="#">Thank you</Button>
                        </Container>

                    </MainContainer>
                </div>
            </Div>
        </>
    );
};

export default RecordRequest;
