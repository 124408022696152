/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
    SharelinkModal,
    SharelinkModalBody,
    InputDiv
} from "./ModalStyle";
import { useSelector } from "react-redux";
import { titleCase, arraySearch } from "../../functions";
import Loader from "../loader/Loader";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import axios from "axios";
import $ from 'jquery';
import "./index.css";
import { useParams } from "react-router-dom";
import ReactStars from "react-rating-stars-component";


// http://localhost:3000/feedback/uMR5EP8Po1nPjtAxADKKqw5PDkRH0T/ee2REg7a82/NaWUCanXiM
export default function Index() {
    const { testimonyId } = useParams();

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const getWorkById = useSelector((state) => state.getWorkById);
    const { workSpaceInfo } = getWorkById;

    const [workspace_id, setWorkspace_id] = React.useState(workSpaceInfo[0]?.workspace_id);
    const [token, setToken] = React.useState(userData.data.token);
    const [feedbackData, setFeedbackData] = React.useState([]);
    const [feedbackPreviewIsVisible, setFeedbackPreviewIsVisible] = React.useState(false);
    const [feedbackPreviewData, setFeedbackPreviewData] = React.useState(false);
    const [feedbackPreviewType, setFeedbackPreviewType] = React.useState(false);
    const [copyShareFeedbackLinkIsVisible, setCopyShareFeedbackLinkIsVisible] = React.useState(false);
    const [linkHasBeenCopied, setLinkHasBeenCopied] = React.useState(false);
    const [embedHasBeenCopied, setEmbedHasBeenCopied] = React.useState(false);
    const [copyEmbedFeedbackLinkIsVisible, setCopyEmbedFeedbackLinkIsVisible] = React.useState(false);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [filteredFeedbackData, setFilteredFeedbackData] = React.useState([]);
    const [count, setCount] = React.useState(null);

    React.useEffect(() => {
        setIsLoadingState(true);
        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}feedback/get_feedbacks?token=${token}&workspace_id=${workspace_id}&testimony_id=${testimonyId}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            setFeedbackData(response.data.data.feedbacks.reverse());
        }).catch(function (error) {
            setFeedbackData([]);
        });

    }, []);

    React.useEffect(() => {
        if (feedbackData) {
            setFilteredFeedbackData(feedbackData);
        }
    }, [feedbackData]);


    const handleOnChange = async (e) => {
        let value = e.target.value;
        if (value.length > 2) {
            let search = await arraySearch(feedbackData, value);
            setFilteredFeedbackData(search);
            setCount(search.length);
        } else {
            setFilteredFeedbackData(feedbackData);
            setCount(feedbackData?.length);
        }
    };


    // COPY TO CLIPBOARD
    // const fallbackCopyTextToClipboard = (text) => {
    //     var textArea = document.createElement("textarea");
    //     textArea.value = text;

    //     // Avoid scrolling to bottom
    //     textArea.style.top = "0";
    //     textArea.style.left = "0";
    //     textArea.style.position = "fixed";

    //     document.body.appendChild(textArea);
    //     textArea.focus();
    //     textArea.select();

    //     try {
    //         var successful = document.execCommand('copy');
    //         var msg = successful ? 'successful' : 'unsuccessful';
    //         console.log('Fallback: Copying text command was ' + msg);
    //     } catch (err) {
    //         console.error('Fallback: Oops, unable to copy', err);
    //     }

    //     document.body.removeChild(textArea);
    // };

    // const copyTextToClipboard = (text, type) => {
    //     if (!navigator.clipboard) {
    //         fallbackCopyTextToClipboard(text);
    //         return;
    //     }
    //     navigator.clipboard.writeText(text).then(function () {
    //         if (type === "link") {
    //             setLinkHasBeenCopied(true);
    //         } else {
    //             setEmbedHasBeenCopied(true);
    //         }
    //         console.log('Async: Copying to clipboard was successful!');
    //     }, function (err) {
    //         console.error('Async: Could not copy text: ', err);
    //     });
    // };

    const deleteFeedback = (feedback_id) => {
        if (window.confirm('Are you sure you want to delete this feeback?')) {

            var config = {
                method: 'delete',
                url: `${BACKEND_BASE_URL}feedback/delete_feedback`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: {
                    "token": token,
                    "feedback_id": feedback_id
                }
            };

            axios(config).then(function (response) {
                window.location.reload();
            }).catch(function (error) {
                window.location.reload();
            });

        }
    };

    // const copySharableLink = (url, type) => {
    //     let feedback_intro = $("#introduction_text").val();
    //     if (feedback_intro.length < 1) {
    //         alert("Feedback introduction can't be left blank.");
    //     } else {
    //         copyTextToClipboard(url, type);

    //         var config = {
    //             method: 'put',
    //             url: `${BACKEND_BASE_URL}workspace/set_feedback_intro`,
    //             headers: {
    //                 Accept: 'application/json',
    //                 'Content-Type': 'application/json',
    //             },
    //             data: {
    //                 "introduction": feedback_intro,
    //                 "workspace_id": workspace_id,
    //                 "token": token
    //             }
    //         };

    //         axios(config).then(function (response) { }).catch(function (error) {
    //             alert(error.message);
    //         });
    //     }
    // };

    const previewFeedback = (feedback_type, feedback_data) => {
        setFeedbackPreviewType(feedback_type);
        setFeedbackPreviewData(feedback_data);
        setFeedbackPreviewIsVisible(true);
    };

    return (
        <>
            {isLoadingState ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="team">
                    <div className="team__header-container">
                        <div className="team__header-title-group">
                            <div className="company__billet-container">
                                <p className="team__header-title">My Feedbacks</p>
                            </div>
                            <div className="team__header-description">Collect video, audio or text feedback from your loyal customers <br></br> for your testimonial and reviews or more purposes.</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* <div className="team__button" style={{marginRight: "15px"}}>
                            <button onClick={() => setCopyEmbedFeedbackLinkIsVisible(true)} type="button" className="button_with_add_icon button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <polyline points="7 8 3 12 7 16" />
                                    <polyline points="17 8 21 12 17 16" />
                                    <line x1="14" y1="4" x2="10" y2="20" />
                                </svg>
                                <p className="button__text">Embed Feedback Form</p>
                            </button>
                        </div> */}
                            {/* <div className="team__button">
                                <button onClick={() => setCopyShareFeedbackLinkIsVisible(true)} type="button" className="button_with_add_icon button button--primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                                        <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                                    </svg>
                                    <p className="button__text">Share Feedback Form</p>
                                </button>
                            </div> */}
                        </div>
                    </div>

                    <div className="tableFilters__wrapper tableFilters__itemGroup">
                        <div className="tableFilters__item tableFilters__search">
                            <div className="form__input-wrapper">
                                <input type="text" className="form__input form__input--withIcon" placeholder="Search feedback by name..." onChange={handleOnChange} />
                                <div className="form__input-icon">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="10" cy="10" r="7" />
                                                <line x1="21" y1="21" x2="15" y2="15" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tableFilters__itemGroup">
                            <div className="tableFilters__item tableFilters__item--medium created_by_dropdown">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setTypeIsVisible((typeIsVisible) => !typeIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="3" y="4" width="18" height="4" rx="2" />
                                                        <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                        <line x1="10" y1="12" x2="14" y2="12" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Type: <span>Video</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {typeIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Video</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Audio</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Text</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tableFilters__item tableFilters__item--medium">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedOnIsVisible((createdOnIsVisible) => !createdOnIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="4" y="5" width="16" height="16" rx="2" />
                                                        <line x1="16" y1="3" x2="16" y2="7" />
                                                        <line x1="8" y1="3" x2="8" y2="7" />
                                                        <line x1="4" y1="11" x2="20" y2="11" />
                                                        <rect x="8" y="15" width="2" height="2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Created on: <span>Today</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdOnIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Today</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Yesterday</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last week</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last month</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last quater</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last year</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {filteredFeedbackData?.length > 0 ? (
                        // DATA LIST
                        <div className="settings-layout">
                            <div className="settings-layout__content">
                                <div className="documents__wrapper">
                                    <div className="app_table__table">
                                        <div className="table documents__table">
                                            <div className="table__container">
                                                <div className="table__innerContainer">
                                                    <div className="table__row table__header">
                                                        <div className="table__column table__column--date app_table__column--medium">Name</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Email</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Type</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Rating</div>
                                                        <div className="table__column table__column--action">Action</div>
                                                    </div>

                                                    {filteredFeedbackData?.map((feedback, index) => (
                                                        <div className="table__row table__dataRow" key={index}>
                                                            <div className="table__column table__column--text app_table__column--medium">{titleCase(feedback.name)}</div>
                                                            <div className="table__column table__column--date app_table__column--medium">{feedback.email}</div>
                                                            <div className="table__column table__column--date app_table__column--medium">{titleCase(feedback.type)}</div>

                                                            <div className="table__column table__column--date app_table__column--medium">
                                                                <ReactStars
                                                                    count={5}
                                                                    size={25}
                                                                    activeColor="#ffd700"
                                                                    value={feedback.rating}
                                                                    edit={false}
                                                                />
                                                            </div>

                                                            <div className="table__column table__column--action">
                                                                <div className="documents__optionsDropdown table_action_list">

                                                                    {feedback.type === "video" && (
                                                                        <button onClick={() => previewFeedback(feedback.type, feedback.url)} title="Preview" className="documents__optionsDropdownTrigger">
                                                                            <div className="table_action_options">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <circle cx="12" cy="12" r="2" />
                                                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    )}

                                                                    {feedback.type === "audio" && (
                                                                        <button onClick={() => previewFeedback(feedback.type, feedback.url)} title="Preview" className="documents__optionsDropdownTrigger">
                                                                            <div className="table_action_options">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <circle cx="12" cy="12" r="2" />
                                                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    )}

                                                                    {feedback.type === "text" && (
                                                                        <button onClick={() => previewFeedback(feedback.type, feedback.text)} title="Preview" className="documents__optionsDropdownTrigger">
                                                                            <div className="table_action_options">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                    <circle cx="12" cy="12" r="2" />
                                                                                    <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    )}

                                                                    <button onClick={() => deleteFeedback(feedback.feedback_id)} title="Delete" className="documents__optionsDropdownTrigger">
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ef2f2f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <line x1="4" y1="7" x2="20" y2="7" />
                                                                                <line x1="10" y1="11" x2="10" y2="17" />
                                                                                <line x1="14" y1="11" x2="14" y2="17" />
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // EMPTY STATE
                        <div className="documents__empty-table">
                            <div className="empty-table">
                                <div className="empty-table__wrapper">
                                    <div className="empty-table__icon empty-table__icon--team">
                                        <div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                                    <rect x="3" y="6" width="12" height="12" rx="2" />
                                                    <line x1="7" y1="12" x2="11" y2="12" />
                                                    <line x1="9" y1="10" x2="9" y2="14" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="empty-table__header">
                                        No feedbacks yet!
                                    </div>
                                    <div className="empty-table__description"></div>
                                    {/* <div className="empty-table__button">
                                        <div className="team__button">
                                            <button onClick={() => setCopyShareFeedbackLinkIsVisible(true)} type="button" className="button_with_add_icon button button--primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="12" y1="5" x2="12" y2="19" />
                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                </svg>
                                                <p className="button__text">Collect Feedback</p>
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    )}

                </div>

                {/* COPY SHARE FEEDBACK LINK */}
                {copyShareFeedbackLinkIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "auto" }} className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "10px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">Feedback Sharing Link</p>
                                        <div onClick={() => setCopyShareFeedbackLinkIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "500px" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <SharelinkModal>

                                                            <SharelinkModalBody>
                                                                <div>
                                                                    <p>
                                                                        Collect feedback from your customers by sharing the link below.
                                                                    </p>
                                                                </div>
                                                            </SharelinkModalBody>

                                                            <textarea style={{ resize: "none", marginTop: "20px", fontSize: "18px", minHeight: "200px", background: "#d7e3e747" }} id="introduction_text" placeholder="Write your feedback introduction here." className="form__input"></textarea>
                                                            {/* defaultValue={workSpaceInfo[0].feedback_introduction} */}
                                                            <InputDiv>
                                                                {/* <input value={`http://localhost:3000/feedback/${btoa(`${token}@${workspace_id}`)}`} />
                                                                <button onClick={() => copySharableLink(`http://localhost:3000/feedback/${btoa(`${token}@${workspace_id}`)}`, "link")}>{linkHasBeenCopied ? "Copied" : "Copy"}</button> */}
                                                            </InputDiv>

                                                            <SharelinkModalBody>
                                                                <div>
                                                                    <a href={`http://localhost:3000/feedback/${btoa(`${token}@${workspace_id}`)}`} target="_blank" rel="noreferrer">
                                                                        <span>Preview Link in new Tab</span>
                                                                    </a>
                                                                </div>
                                                            </SharelinkModalBody>
                                                        </SharelinkModal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* COPY EMBED FEEDBACK LINK */}
                {copyEmbedFeedbackLinkIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "auto" }} className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "20px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">Feedback Embed Code</p>
                                        <div onClick={() => setCopyEmbedFeedbackLinkIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "500px" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item" style={{ position: "relative", width: "100%" }}>
                                                        <SharelinkModal>

                                                            <SharelinkModalBody>
                                                                <div>
                                                                    <p>
                                                                        Collect feedback from your customers by embedding the code below.
                                                                    </p>
                                                                </div>
                                                            </SharelinkModalBody>

                                                            {/* <InputDiv style={{ display: "block" }}>
                                                                <div style={{ resize: "none", fontSize: "20px", minHeight: "200px", background: "#d7e3e747", wordBreak: "break-all" }} className="form__input">
                                                                    {`<div style="position: relative; height: 0; width: 100%; padding-bottom: 56.25%;">
                                                                    <iframe src="http://localhost:3000/feedback/${btoa(`${token}@${workspace_id}`)}" frameborder="0" style="position: absolute; width: 100%; height: 100%; border-radius: 6px; left: 0; top: 0;" allowfullscreen=""></iframe>
                                                                </div>`}
                                                                </div>
                                                                <button style={{ width: "100%", marginTop: "10px" }} onClick={() => copyTextToClipboard(`http://localhost:3000/feedback/${btoa(`${token}@${workspace_id}`)}`, "embed")}>{embedHasBeenCopied ? "Copied" : "Copy Embed Code"}</button>
                                                            </InputDiv> */}

                                                        </SharelinkModal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* PREVIEW */}
                {feedbackPreviewIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "500px" }} className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "20px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">Feedback Preview</p>
                                        <div onClick={() => setFeedbackPreviewIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "inherit" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        {feedbackPreviewType === "video" && (
                                                            <video style={{ borderRadius: "5px", width: "100%", height: "auto" }} src={feedbackPreviewData} controls playsInline></video>
                                                        )}

                                                        {feedbackPreviewType === "audio" && (
                                                            <audio style={{ borderRadius: "5px", width: "100%" }} src={feedbackPreviewData} controls playsInline></audio>
                                                        )}

                                                        {feedbackPreviewType === "text" && (
                                                            <div className="form__field" style={{ marginBottom: "0px" }}>
                                                                <div className="form__input-wrapper">
                                                                    <textarea style={{ resize: "none", fontSize: "18px", minHeight: "200px", background: "#d7e3e747" }} className="form__input">{feedbackPreviewData}</textarea>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    );
};