/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { titleCase, arraySearchs, shortenText, uniqueId } from "../../functions";
import Loader from "../loader/Loader";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import axios from "axios";
import { Link } from "react-router-dom";
import AWS from "aws-sdk";
import "./index.css";
import {
    SharelinkModal,
    SharelinkModalBody,
    InputDiv
} from "./ModalStyle";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { bounce } from "../../toast";
toast.configure();


export default function Index() {

    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const getWorkById = useSelector((state) => state.getWorkById);
    const { workSpaceInfo } = getWorkById;

    const [workspace_id, setWorkspace_id] = React.useState(workSpaceInfo[0]?.workspace_id);
    const [token, setToken] = React.useState(userData.data.token);


    const [testimonyData, setTestimonyData] = React.useState([]);
    const [isLoadingState, setIsLoadingState] = React.useState(false);
    const [filteredTestimonyData, setFilteredTestimonyData] = React.useState([]);
    const [count, setCount] = React.useState(null);
    const [copyShareFeedbackLinkIsVisible, setCopyShareFeedbackLinkIsVisible] = React.useState(false);
    const [editFeedbackModalIsVisible, setEditFeedbackModalIsVisible] = React.useState(false);
    const [testimony_name, setTestimony_name] = React.useState("");
    const [product_title, setProduct_title] = React.useState("");
    const [product_description, setProduct_description] = React.useState("");
    const [file_buffer, setFileBuffer] = React.useState(null);
    const [file_buff, setFileBuff] = React.useState(null);
    const [file_base64, setFileBase64] = React.useState(null);
    const [file_base, setFileBase] = React.useState(null);
    const [share_url, setShare_url] = React.useState("");
    const [embed_url, setEmbed_url] = React.useState("");
    const [product_video_url, setProduct_video_url] = React.useState("");
    const [product_link, setProduct_link] = React.useState("");
    const [loadingRewardImg, setLoadingRewardImg] = React.useState(false);

    const [reward_title, setReward_title] = React.useState("");
    const [reward_dec, setReward_dec] = React.useState("");
    const [reward_access_info, setReward_access_info] = React.useState("");


    const [editTestimonyName, setEditTestimonyName] = React.useState("");
    const [editProductTitle, setEditProductTitle] = React.useState("");
    const [editProductDescription, setEditProductDescription] = React.useState("");
    const [editProductVideoUrl, setEditProductVideoUrl] = React.useState("");
    const [editProductLink, setEditProductLink] = React.useState("");
    const [editTestimonyId, setEditTestimonyId] = React.useState("");
    const [editRewardTitle, setEditRewardTitle] = React.useState("");
    const [editRewardDec, setEditRewardDec] = React.useState("");
    const [editRewardAccessInfo, setEditRewardAccessInfo] = React.useState("");
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        setIsLoadingState(true);
        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}testimony/get_testimony?token=${token}&workspace_id=${workspace_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setIsLoadingState(false);
            setTestimonyData(response.data.data.testimonys.reverse());
        }).catch(function (error) {
            setTestimonyData([]);
        });

    }, []);

    React.useEffect(() => {
        if (testimonyData) {
            setFilteredTestimonyData(testimonyData);
        }
    }, [testimonyData]);

    const handleOnChange = async (e) => {
        let value = e.target.value;
        if (value.length > 2) {
            let search = await arraySearchs(testimonyData, value);
            setFilteredTestimonyData(search);
            setCount(search.length);
        } else {
            setFilteredTestimonyData(testimonyData);
            setCount(testimonyData?.length);
        }
    };

    const handleUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        const fileSize = file.size / 1024 / 1024;
        if (fileSize < 11) {
            const reader = new window.FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = () => {
                setFileBuffer(Buffer(reader.result));
                //convert to base64
                const base64 = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                setFileBase64(base64);

            };
        } else {
            toast.error(bounce("File size uploaded is too big, maximum file size required is 10MB."));
        }
    };

    const handleUpload2 = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        const fileSize = file.size / 1024 / 1024;
        if (fileSize < 11) {
            const reader = new window.FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = () => {
                setFileBuff(Buffer(reader.result));
                //convert to base64
                const base64 = btoa(new Uint8Array(reader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
                setFileBase(base64);

            };
        } else {
            toast.error(bounce("File size uploaded is too big, maximum file size required is 10MB."));
        }
    };

    const creatTestimony = async (e) => {

        e.preventDefault();
        if (testimony_name === "" || product_title === "" || product_description === "" || file_base64 === null) {
            setError("Please fill all the fields");
            // toast.error("Please fill all the fields", { transition: bounce });
            return;
        }
        setIsLoadingState(true);

        var config = {
            method: 'post',
            url: `${BACKEND_BASE_URL}testimony/create_testimony`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                "workspace_id": workspace_id,
                "token": token,
                "testimony_name": testimony_name,
                "product_title": product_title,
                "product_description": product_description,
                "product_video_url": product_video_url === "" ? null : product_video_url,
                "product_link": product_link === "" ? null : product_link,
                "reward_title": reward_title === "" ? null : reward_title,
                "reward_dec": reward_dec === "" ? null : reward_dec,
                "reward_access_info": reward_access_info === "" ? null : reward_access_info,
                "file_base64": file_base64,
                "file_base": file_base === null ? null : file_base,
            }
        };

        await axios(config).then(function (response) {
            toast.success("Testimony created successfully.", { transition: bounce });
            setCopyShareFeedbackLinkIsVisible(false);
            setIsLoadingState(false);
            setTestimony_name("");
            setProduct_title("");
            setProduct_description("");
            setFileBase64("");
            setFileBuffer(null);
            setShare_url("");
            setEmbed_url("");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch(function (error) {
            setIsLoadingState(false);
            setError(error.response.data.message);
            // toast.error("Testimony creation failed.", { transition: bounce });
        });

    };

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url);
        toast.success("Link copied to clipboard.", { transition: bounce });
    };

    const deleteTestimony = (testimony_id) => {
        if (window.confirm('Are you sure you want to delete this testimony?')) {

            var config = {
                method: 'delete',
                url: `${BACKEND_BASE_URL}testimony/delete_testimony`,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                data: {
                    "token": token,
                    "testimony_id": testimony_id
                }
            };

            axios(config).then(function (response) {
                toast.success("Testimony deleted successfully.", { transition: bounce });
                setTimeout(() => {
                    window.location.reload(false);
                }, 200);
            }).catch(function (error) {
                toast.error("Testimony deletion failed.", { transition: bounce });
                setTimeout(() => {
                    window.location.reload(false);
                }, 200);
            });

        }
    };

    const editTestimony = () => {
        setIsLoadingState(true);
        let config = {
            method: 'put',
            url: `${BACKEND_BASE_URL}testimony/edit_testimony`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                "token": token,
                "testimony_id": editTestimonyId,
                "testimony_name": editTestimonyName,
                "product_title": editProductTitle,
                "product_description": editProductDescription,
                "product_video_url": editProductVideoUrl,
                "product_link": editProductLink,
                "file_base64": file_base64,
                "file_base": file_base,
                "reward_title": editRewardTitle,
                "reward_dec": editRewardDec,
                "reward_access_info": editRewardAccessInfo,
            }

        };

        axios(config).then(function (response) {
            toast.success("Testimony edited successfully.", { transition: bounce });
            setIsLoadingState(false);
            setEditFeedbackModalIsVisible(false);
            setTimeout(() => {
                window.location.reload(false);
            }, 500);
        }).catch(function (error) {
            setError(error.response.data.message);
            // toast.error("Testimony editing failed.", { transition: bounce });
            setIsLoadingState(false);
        });
    };


    return (
        <>
            {isLoadingState ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="team">
                    <div className="team__header-container">
                        <div className="team__header-title-group">
                            <div className="company__billet-container">
                                <p className="team__header-title">My Testimonial Campaigns</p>
                            </div>
                            <div className="team__header-description">
                                Create A Testimonial Campaign to share with your customers
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* <div className="team__button" style={{marginRight: "15px"}}>
                            <button onClick={() => setCopyEmbedFeedbackLinkIsVisible(true)} type="button" className="button_with_add_icon button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <polyline points="7 8 3 12 7 16" />
                                    <polyline points="17 8 21 12 17 16" />
                                    <line x1="14" y1="4" x2="10" y2="20" />
                                </svg>
                                <p className="button__text">Embed Feedback Form</p>
                            </button>
                        </div> */}
                            <div className="team__button" onClick={() => setCopyShareFeedbackLinkIsVisible(true)}>
                                <button type="button" className="button_with_add_icon button button--primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                    <p className="button__text" >Create Campaign</p>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="tableFilters__wrapper tableFilters__itemGroup">
                        <div className="tableFilters__item tableFilters__search">
                            <div className="form__input-wrapper">
                                <input type="text" className="form__input form__input--withIcon" placeholder="Search campaign by name..." onChange={handleOnChange} />
                                <div className="form__input-icon">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="10" cy="10" r="7" />
                                                <line x1="21" y1="21" x2="15" y2="15" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tableFilters__itemGroup">
                            <div className="tableFilters__item tableFilters__item--medium created_by_dropdown">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setTypeIsVisible((typeIsVisible) => !typeIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="3" y="4" width="18" height="4" rx="2" />
                                                        <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10" />
                                                        <line x1="10" y1="12" x2="14" y2="12" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Type: <span>Video</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {typeIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Video</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Audio</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Text</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tableFilters__item tableFilters__item--medium">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedOnIsVisible((createdOnIsVisible) => !createdOnIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="4" y="5" width="16" height="16" rx="2" />
                                                        <line x1="16" y1="3" x2="16" y2="7" />
                                                        <line x1="8" y1="3" x2="8" y2="7" />
                                                        <line x1="4" y1="11" x2="20" y2="11" />
                                                        <rect x="8" y="15" width="2" height="2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Created on: <span>Today</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdOnIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Today</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Yesterday</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last week</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last month</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last quater</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last year</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {filteredTestimonyData?.length > 0 ? (
                        // DATA LIST
                        <div className="settings-layout">
                            <div className="settings-layout__content">
                                <div className="documents__wrapper">
                                    <div className="app_table__table">
                                        <div className="table documents__table">
                                            <div className="table__container">
                                                <div className="table__innerContainer">
                                                    <div className="table__row table__header">
                                                        <div className="table__column table__column--date app_table__column--medium">Name</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Product</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Share Link</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Embed Code</div>
                                                        <div className="table__column table__column--action">Action</div>
                                                    </div>

                                                    {filteredTestimonyData?.map((testimony, index) => (
                                                        <div className="table__row table__dataRow" key={index}>


                                                            <div className="table__column table__column--text app_table__column--medium">{titleCase(testimony.testimony_name)}</div>

                                                            <div className="table__column table__column--date app_table__column--medium">{titleCase(testimony.product_title)}</div>

                                                            <div className="table__column table__column--date app_table__column--medium"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {shortenText(testimony.share_url, 15)}
                                                                <img src="/images/copy1.png" alt="" style={{ width: "13%", cursor: "pointer", }}
                                                                    onClick={() => { copyToClipboard(testimony.share_url); }}
                                                                />
                                                            </div>

                                                            <div className="table__column table__column--date app_table__column--medium"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {shortenText(testimony.embed_url, 15)}

                                                                <img src="/images/copy1.png" alt="" style={{ width: "13%", cursor: "pointer", }}
                                                                    onClick={() => { copyToClipboard(testimony.embed_url); }}
                                                                />
                                                            </div>


                                                            <div className="table__column table__column--action" style={{ width: "90px" }}>
                                                                <div className="documents__optionsDropdown table_action_list">


                                                                    <Link to={`/testimony/${testimony.testimony_id}`} title="Preview" className="documents__optionsDropdownTrigger">
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <circle cx="12" cy="12" r="2" />
                                                                                <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
                                                                            </svg>
                                                                        </div>
                                                                    </Link>


                                                                    <button title="Edit" className="documents__optionsDropdownTrigger"
                                                                        onClick={async (e) => {
                                                                            setEditFeedbackModalIsVisible(true);
                                                                            setEditTestimonyName(testimony.testimony_name);
                                                                            setEditProductTitle(testimony.product_title);
                                                                            setEditProductDescription(testimony.product_description);
                                                                            setEditProductVideoUrl(testimony.product_video_url);
                                                                            setEditProductLink(testimony.product_link);
                                                                            setEditTestimonyId(testimony.testimony_id);
                                                                            setEditRewardTitle(testimony.reward_title);
                                                                            setEditRewardDec(testimony.reward_dec);
                                                                            setEditRewardAccessInfo(testimony.reward_access_info);
                                                                        }}
                                                                    >
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                <line x1="16" y1="5" x2="19" y2="8" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>


                                                                    <button title="Delete" className="documents__optionsDropdownTrigger" onClick={() => deleteTestimony(testimony.testimony_id)}>
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ef2f2f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <line x1="4" y1="7" x2="20" y2="7" />
                                                                                <line x1="10" y1="11" x2="10" y2="17" />
                                                                                <line x1="14" y1="11" x2="14" y2="17" />
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // EMPTY STATE
                        <div className="documents__empty-table">
                            <div className="empty-table">
                                <div className="empty-table__wrapper">
                                    <div className="empty-table__icon empty-table__icon--team">
                                        <div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-video-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                                                    <rect x="3" y="6" width="12" height="12" rx="2" />
                                                    <line x1="7" y1="12" x2="11" y2="12" />
                                                    <line x1="9" y1="10" x2="9" y2="14" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="empty-table__header">No Testimonials Yet!</div>
                                    {/* <div className="empty-table__description">
                                        Create Your First Testimony Campaign
                                    </div> */}
                                    <div className="empty-table__button">
                                        <div className="team__button" onClick={() => setCopyShareFeedbackLinkIsVisible(true)}>
                                            <button type="button" className="button_with_add_icon button button--primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="12" y1="5" x2="12" y2="19" />
                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                </svg>
                                                <p className="button__text">Create Campaign</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>


                {/* CREATE FEEDBAK*/}
                {copyShareFeedbackLinkIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "auto", height: "95%" }} className="app_modal__dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "10px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">
                                            Create Testimonial Campaign
                                        </p>
                                        <div

                                            onClick={() => {
                                                setCopyShareFeedbackLinkIsVisible(false);
                                                setTestimony_name("");
                                                setProduct_description("");
                                                setProduct_title("");
                                                setProduct_link("");
                                                setProduct_video_url("");
                                                setError("");
                                            }}

                                            className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {error && (
                                        <div className="error">
                                            {error}
                                        </div>
                                    )}
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "500px" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <SharelinkModal>
                                                            {/* <label>Campaign name</label> */}
                                                            <input type="text" placeholder="Campaign name"
                                                                value={testimony_name}
                                                                onChange={(e) => setTestimony_name(e.target.value)}
                                                                required
                                                            />
                                                            {/* <label>Product name</label> */}
                                                            <input type="text" placeholder="Product title"
                                                                value={product_title}
                                                                onChange={(e) => setProduct_title(e.target.value)}
                                                                required
                                                            />
                                                            {/* <label>Product description</label> */}
                                                            <textarea name="" id="" cols="30" rows="4" placeholder="Product description"
                                                                value={product_description}
                                                                onChange={(e) => setProduct_description(e.target.value)}
                                                                required
                                                            ></textarea>

                                                            <label>Product Image</label>
                                                            <input type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUpload(event)} required />

                                                            <label>
                                                                Product website (link to product page)
                                                            </label>
                                                            <input type="text" placeholder="
                                                             https://www.example.com
                                                            "
                                                                value={product_link}
                                                                onChange={(e) => setProduct_link(e.target.value)}
                                                                required
                                                            />
                                                            <label>
                                                                Product video link (any video link will work)
                                                            </label>
                                                            <input type="text"
                                                                placeholder="
                                                            https://www.video.com/video.mp4 
                                                            "
                                                                value={product_video_url}
                                                                onChange={(e) => setProduct_video_url(e.target.value)}
                                                            />

                                                            <input type="text" placeholder="Reward title"
                                                                value={reward_title}
                                                                onChange={(e) => setReward_title(e.target.value)}
                                                                required
                                                            />

                                                            <label>Reward Image</label>
                                                            <input type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUpload2(event)} required />

                                                            <textarea name="" id="" cols="30" rows="4" placeholder="Reward description"
                                                                value={reward_dec}
                                                                onChange={(e) => setReward_dec(e.target.value)}
                                                                required
                                                            ></textarea>

                                                            <textarea name="" id="" cols="30" rows="4" placeholder="Reward access information"
                                                                value={reward_access_info}
                                                                onChange={(e) => setReward_access_info(e.target.value)}
                                                                required
                                                            ></textarea>

                                                            {isLoadingState ? (
                                                                <button>
                                                                    Loading...
                                                                </button>
                                                            ) : (
                                                                <button onClick={creatTestimony} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                                    </svg>
                                                                    Create Campaign
                                                                </button>
                                                            )}

                                                        </SharelinkModal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


                {/* EDIT FEEDBACK */}
                {editFeedbackModalIsVisible && (
                    <div className="app_modal">
                        <div style={{ width: "auto", height: "95%" }} className="app_modal__dialog" >
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div style={{ marginBottom: "10px" }} className="requisiteModal__header">
                                        <p className="requisiteModal__title">
                                            Edit Testimonial Campaign
                                        </p>
                                        <div
                                            onClick={() => {
                                                setEditFeedbackModalIsVisible(false);
                                                setTestimony_name("");
                                                setProduct_description("");
                                                setProduct_title("");
                                                setProduct_link("");
                                                setProduct_video_url("");
                                                setError("");
                                            }}
                                            className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {error && (
                                        <div className="error">
                                            {error}
                                        </div>
                                    )}
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div style={{ width: "500px" }} className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <SharelinkModal>

                                                            <input type="text" placeholder="Campaign name"
                                                                value={editTestimonyName}
                                                                onChange={(e) => setEditTestimonyName(e.target.value)}
                                                                required
                                                            />
                                                            <input type="text" placeholder="Product title"
                                                                value={editProductTitle}
                                                                onChange={(e) => setEditProductTitle(e.target.value)}
                                                                required
                                                            />

                                                            <textarea name="" id="" cols="30" rows="4" placeholder="Product description"
                                                                value={editProductDescription}
                                                                onChange={(e) => setEditProductDescription(e.target.value)}
                                                                required
                                                            ></textarea>

                                                            <label>Product Image</label>
                                                            <input type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUpload(event)} required />

                                                            <label>
                                                                Product website (link to product page)
                                                            </label>
                                                            <input type="text" placeholder="
                                                             https://www.example.com
                                                            "
                                                                value={editProductLink}
                                                                onChange={(e) => setEditProductLink(e.target.value)}
                                                                required
                                                            />
                                                            <label>
                                                                Product video link (any video link will work)
                                                            </label>
                                                            <input type="text" placeholder="
                                                            https://www.video.com/video.mp4
                                                            "
                                                                value={editProductVideoUrl}
                                                                onChange={(e) => setEditProductVideoUrl(e.target.value)}
                                                            />

                                                            <input type="text" placeholder="Reward title"
                                                                value={editRewardTitle}
                                                                onChange={(e) => setEditRewardTitle(e.target.value)}
                                                                required
                                                            />

                                                            <label>Reward Image</label>
                                                            <input type='file' accept=".png, .jpg, .jpeg" onChange={(event) => handleUpload2(event)} required />

                                                            <textarea name="" id="" cols="30" rows="4" placeholder="Reward description"
                                                                value={editRewardDec}
                                                                onChange={(e) => setEditRewardDec(e.target.value)}
                                                                required
                                                            ></textarea>

                                                            <textarea name="" id="" cols="30" rows="4" placeholder="Reward access information"
                                                                value={editRewardAccessInfo}
                                                                onChange={(e) => setEditRewardAccessInfo(e.target.value)}
                                                                required
                                                            ></textarea>
                                                            {isLoadingState ? (
                                                                <button>
                                                                    Loading...
                                                                </button>
                                                            ) : (
                                                                <button onClick={editTestimony}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <line x1="12" y1="5" x2="12" y2="19" />
                                                                        <line x1="5" y1="12" x2="19" y2="12" />
                                                                    </svg>
                                                                    Save Changes
                                                                </button>
                                                            )}

                                                        </SharelinkModal>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};