/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BACKEND_BASE_URL } from "../../redux/backendUrl";
import "./index.css";
import { titleCase } from "../../functions";
import Loader from "../loader/Loader";
import Metadata from '../Metadata';

import {
    RowDiv,
    ColumnDiv2,
    TitleWrapper,
} from "./SetBrandStyle";

import axios from "axios";

export default function Index() {

    const [pageData, setPageData] = React.useState(null);

    let url_params_array = window.location.pathname.split("/");
    let video_id = url_params_array.pop();

    React.useEffect(() => {

        var config = {
            method: 'get',
            url: `${BACKEND_BASE_URL}video/get_video_by_id?video_id=${video_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        axios(config).then(function (response) {
            setPageData(response.data.data.video);
        }).catch(function (error) {
            window.location = `/`;
        });

    }, []);


    const setPageView = () => {
        var config = {
            method: 'put',
            url: `${BACKEND_BASE_URL}analytic/set_visits`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                video_id: video_id
            }
        };

        axios(config).then(function (response) {
            console.log("Page visit set successfully");
        }).catch(function (error) {
        });
    };

    const setPagePlay = () => {
        var config = {
            method: 'put',
            url: `${BACKEND_BASE_URL}analytic/set_plays`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                video_id: video_id
            }
        };

        axios(config).then(function (response) {
            console.log("Page play set successfully");
        }).catch(function (error) {
        });
    };

    const setPageClick = () => {
        var config = {
            method: 'put',
            url: `${BACKEND_BASE_URL}analytic/set_clicks`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            data: {
                video_id: video_id
            }
        };

        axios(config).then(function (response) {
            console.log("Page click set successfully");
        }).catch(function (error) {
        });
    };

    React.useEffect(() => {
        if (pageData !== null) {
            console.log(pageData);
            setPageView();
        }
    }, [pageData]);

    if (pageData == null) {
        return <Loader />;
    }

    return (
        <>
            <Metadata
                ogTitle={pageData.page.title.text}
                ogDesc={pageData.page.description.text}
                image={pageData.still_image}
            />
            <div className="main-layout__container">
                <div className="team">

                    <RowDiv style={{ paddingTop: "50px" }}>
                        <ColumnDiv2>
                            <TitleWrapper>
                                <div className="video_page_brand_logo">
                                    <img src={pageData.page.logo.url} alt='' />
                                </div>
                                <p style={{ color: pageData.page.title.color }} className="video_page_title">
                                    {titleCase(pageData.page.title.text)}
                                </p>
                                <div className="video_page_prev">
                                    <video onPlay={() => setPagePlay()} poster={pageData.still_image} src={pageData.url} controls playsInline></video>
                                </div>
                                <span style={{ color: pageData.page.description.color }} className="video_page_description">
                                    {pageData.page.description.text}
                                </span>
                                <a onClick={() => setPageClick()} target="_blank" className="video_page_button_link" href={pageData.page.call_to_action.url} rel='noreferrer'>
                                    <button style={{ background: pageData.page.call_to_action.color, borderRadius: pageData.page.call_to_action.radius, display: pageData.page.call_to_action.url.length > 0 ? "block" : "none" }} type="button" className="video_page_button">
                                        {pageData.page.call_to_action.text}
                                    </button>
                                </a>
                            </TitleWrapper>
                        </ColumnDiv2>
                    </RowDiv>

                    <div className="footer_logo">
                        <p style={{ color: pageData.page.white_label_text.color }}>
                            {pageData.page.white_label_text.text}
                        </p>
                        <img src={pageData.page.white_label.url} alt="" />
                    </div>
                    <br />
                    <br />

                </div>
            </div>
        </>
    );
};