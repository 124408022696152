import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAIL,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  RESEND_VERIFICATION_CODE_REQUEST,
  RESEND_VERIFICATION_CODE_SUCCESS,
  RESEND_VERIFICATION_CODE_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  USER_LOGOUT,

} from "./AuthTypes";
import axios from "axios";
import { BACKEND_BASE_URL } from "../backendUrl";

export const register = (name, email, password) => async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axios.post(
        `${BACKEND_BASE_URL}authentication/signup`,
        { name, email, password },
        config
      );
  
      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: data,
      });
 
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message});
    }
  };

export const verification = ( email, verification_code) => async (dispatch) => {
    try {
      dispatch({
        type: VERIFY_REQUEST,
      });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axios.post(
        `${BACKEND_BASE_URL}authentication/verify_code`,
        { email, verification_code },
        config
      );
      
      dispatch({
        type: VERIFY_SUCCESS,
        payload: data,
      });
      localStorage.removeItem("registerData");
      
    } catch (error) {
      dispatch({
        type: VERIFY_FAIL,
        payload:
          error.response && error.response.data.message});
    }
  };

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
        type: USER_LOGIN_REQUEST,
        });
    
        const config = {
        headers: {
            "Content-Type": "application/json",
        },
        };
    
        const { data } = await axios.post(
        `${BACKEND_BASE_URL}authentication/login`,
        { email, password },
        config
        );
    
        dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
        });
    
        localStorage.setItem("userData", JSON.stringify(data));
        localStorage.setItem("acls", JSON.stringify(data.data.profile.acls));
    } catch (error) {
        dispatch({
        type: USER_LOGIN_FAIL,
        payload:
            error.response && error.response.data.message
        });
    }
    }

export const resendVerifyCode = (email) => async (dispatch) => {
    try {
        dispatch({
        type: RESEND_VERIFICATION_CODE_REQUEST,
        });
    
        const config = {
        headers: {
            "Content-Type": "application/json",
        },
        };
    
        const { data } = await axios.post(
        `${BACKEND_BASE_URL}authentication/resend_code`,
        { email },
        config
        );
    
        dispatch({
        type: RESEND_VERIFICATION_CODE_SUCCESS,
        payload: data,
        });
    
    } catch (error) {
        dispatch({
        type: RESEND_VERIFICATION_CODE_FAIL,
        payload:
            error.response && error.response.data.message
        });
    }
    }

export const resetPassword = (email) => async (dispatch) => {
    try {
        dispatch({
        type: RESET_PASSWORD_REQUEST,
        });
    
        const config = {
        headers: {
            "Content-Type": "application/json",
        },
        };
    
        const { data } = await axios.post(
        `${BACKEND_BASE_URL}authentication/reset_password`,
        { email },
        config
        );
    
        dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: data,
        });
    
    } catch (error) {
        dispatch({
        type: RESET_PASSWORD_FAIL,
        payload:
            error.response && error.response.data.message
        });
    }
    }

export const logout = () => (dispatch) => {
      localStorage.clear()
      dispatch({ type: USER_LOGOUT });
      document.location.href = "/login";
    };
