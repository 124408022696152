export const GET_VIDEO_REQUEST = 'GET_VIDEO_REQUEST';
export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_FAIL = 'GET_VIDEO_FAIL';

export const EDIT_VIDEO_REQUEST = 'EDIT_VIDEO_REQUEST';
export const EDIT_VIDEO_SUCCESS = 'EDIT_VIDEO_SUCCESS';
export const EDIT_VIDEO_FAIL = 'EDIT_VIDEO_FAIL';

export const UPLOAD_VIDEO_REQUEST = 'UPLOAD_VIDEO_REQUEST';
export const UPLOAD_VIDEO_SUCCESS = 'UPLOAD_VIDEO_SUCCESS';
export const UPLOAD_VIDEO_FAIL = 'UPLOAD_VIDEO_FAIL';