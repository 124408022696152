/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import "./index.css";
import { createTeams, getTeams, deleteTeams, editTeams } from "../../redux/team/TeamAction";
import { getWorkspace, createWorkspace, editWorkspace, deleteWorkspace } from "../../redux/workSpace/WorkSpaceAction";
import { useSelector, useDispatch } from "react-redux";
import { asteriskPassword } from "../../functions";
import Loader from "../loader/Loader";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { titleCase, arraySearch } from "../../functions";
import { bounce } from "../../toast";
import InfoModal from '../InfoModal';
import { getUserDetail } from "../../redux/account/AccountAction";
toast.configure();

export default function Index() {
    const dispatch = useDispatch();
    const [AddTeamMemberModalIsVisible, setAddTeamMemberModalIsVisible] = React.useState(false);
    const [EditTeamMemberModalIsVisible, seteditTeamModalIsVisible] = React.useState(false);
    const [createdOnIsVisible, setCreatedOnIsVisible] = React.useState(false);
    const [createdByIsVisible, setCreatedByIsVisible] = React.useState(false);



    const userLogin = useSelector((state) => state.userLogin);
    const { userData } = userLogin;

    const [acessLevel, setAcesslevel] = React.useState([]);
    const [infoModals, setInfoModals] = React.useState(false);

    const createTeam = useSelector((state) => state.createTeam);
    const { loading: loadingCreate, error: errorCreate, message: messageCreate, createTeamData, success: successCreate } = createTeam;

    const editTeam = useSelector((state) => state.editTeam);
    const { loading: loadingEdit, error: errorEdit, message: messageEdit, editTeamData, success: successEdit } = editTeam;

    const getTeam = useSelector((state) => state.getTeam);
    const { loading: loadingGetTeam, teamData } = getTeam;

    const getWorkById = useSelector((state) => state.getWorkById);
    const { workSpaceInfo } = getWorkById;

    const deleteTeam = useSelector((state) => state.deleteTeam);
    const { loading: loadingDelete, error: errorDelete, message: messageDelete, success: successDelete } = deleteTeam;

    const getWork = useSelector((state) => state.getWork);
    const { getWorkspaceData } = getWork;

    const getUser = useSelector((state) => state.getUser);
    const { loading, user } = getUser;

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [workspace_id, setWorkspace_id] = React.useState(workSpaceInfo[0]?.workspace_id);
    const [token, setToken] = React.useState(userData.data.token);
    const [editTeamId, setEditTeamId] = React.useState("");
    const [editTeamName, setEditTeamName] = React.useState("");
    const [editTeamEmail, setEditTeamEmail] = React.useState("");
    const [editTeamPassword, setEditTeamPassword] = React.useState("");
    const [editTeamWorkspace, setEditTeamWorkspace] = React.useState("");
    const [filteredTeam, setFilteredTeam] = React.useState([]);
    const [count, setCount] = React.useState(null);

    let userToken = userData?.data?.token;

    //check if user is logged in 
    React.useEffect(() => {
        <Loader />;
        if (!userData) {
            window.location.href = "/login";
        } else {
            dispatch(getTeams(token, workspace_id));
            dispatch(getWorkspace({ token: userToken }));
            dispatch(getUserDetail(token));
        }
    }, []);

    React.useEffect(() => {
        if (user) {
            setAcesslevel(user?.data?.profile?.acls)
        }
    }, [user]);


    React.useEffect(() => {
        if (teamData) {
            setFilteredTeam(teamData);
        }
    }, [teamData]);


    const handleOnChange = async (e) => {
        let value = e.target.value;
        if (value.length > 2) {
            let search = await arraySearch(teamData, value);
            setFilteredTeam(search);
            setCount(search.length);
        } else {
            setFilteredTeam(teamData);
            setCount(teamData?.length);
        }
    };


    // create team
    React.useEffect(() => {
        setAddTeamMemberModalIsVisible(false);
        if (successCreate === true) {
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        if (errorCreate) {
            toast.error(errorCreate, { transition: bounce });
        }
    }, [dispatch, createTeamData, successCreate, messageCreate, errorCreate]);


    // delete team
    React.useEffect(() => {
        setAddTeamMemberModalIsVisible(false);
        if (successDelete) {
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        if (errorDelete) {
            toast.error(errorDelete, { transition: bounce });
        }
    }, [dispatch, successDelete, messageDelete, errorDelete]);

    //edit team
    React.useEffect(() => {
        setAddTeamMemberModalIsVisible(false);
        if (successEdit) {
            setTimeout(() => {
                window.location.reload();
            }, 500);
        }
        if (errorEdit) {
            toast.error(errorEdit, { transition: bounce });
        }
    }, [dispatch, editTeamData, successEdit, messageEdit, errorEdit]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (acessLevel.length === 1 && acessLevel.includes('FE')) {
            setInfoModals(true);
            return;
        }
        dispatch(createTeams(token, workspace_id, name, email, password));
    };
    return (
        <>
            {loadingGetTeam || loadingDelete || loadingCreate || loadingEdit ? <Loader /> : null}
            <div className="main-layout__container">
                <div className="team">
                    <div className="team__header-container">
                        <div className="team__header-title-group">
                            <div className="company__billet-container">
                                <p className="team__header-title">Team members</p>
                            </div>
                            <div className="team__header-description">Bring your team members to your workspace or account to allow <br></br> them to request feedbacks, create videos, and much more.</div>
                        </div>
                        <div onClick={() => {
                            if (acessLevel?.length === 1 && acessLevel?.includes('FE')) {
                                setInfoModals(true);
                                return;
                            }
                            if (acessLevel?.length === 2 && acessLevel?.includes('FE', 'RE')) {
                                setInfoModals(true);
                                return;
                            }
                            setAddTeamMemberModalIsVisible(true);
                        }} className="team__button">
                            <button type="button" className="button_with_add_icon button button--primary">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="12" y1="5" x2="12" y2="19" />
                                    <line x1="5" y1="12" x2="19" y2="12" />
                                </svg>
                                <p className="button__text">Add Team Member</p>
                            </button>
                        </div>
                    </div>

                    {/* FILTERS */}
                    <div className="tableFilters__wrapper tableFilters__itemGroup">
                        <div className="tableFilters__item tableFilters__search">
                            <div className="form__input-wrapper">
                                <input type="text" className="form__input form__input--withIcon" placeholder="Search team by name..." onChange={handleOnChange} />
                                <div className="form__input-icon">
                                    <div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <circle cx="10" cy="10" r="7" />
                                                <line x1="21" y1="21" x2="15" y2="15" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tableFilters__itemGroup">
                            <div className="tableFilters__item tableFilters__item--medium created_by_dropdown">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedByIsVisible((createdByIsVisible) => !createdByIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <circle cx="12" cy="7" r="4" />
                                                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Status: <span>Active</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdByIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Active</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">InActive</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="tableFilters__item tableFilters__item--medium">
                                <div className="uiSelect__wrapper">
                                    <div className="uiSelect__select">
                                        <div onClick={() => setCreatedOnIsVisible((createdOnIsVisible) => !createdOnIsVisible)} className="dropdown_button_cover">
                                            <div className="DayPickerContainer__icon">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <rect x="4" y="5" width="16" height="16" rx="2" />
                                                        <line x1="16" y1="3" x2="16" y2="7" />
                                                        <line x1="8" y1="3" x2="8" y2="7" />
                                                        <line x1="4" y1="11" x2="20" y2="11" />
                                                        <rect x="8" y="15" width="2" height="2" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-inner">
                                                <div className="uiSelect__select-value-wrapper">
                                                    <p className="uiSelect__select-placeholder">Created on: <span>Today</span></p>
                                                </div>
                                            </div>
                                            <div className="uiSelect__select-arrow">
                                                <div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-down" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <polyline points="6 9 12 15 18 9" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        {createdOnIsVisible && (
                                            <div className="uiSelect__content-wrapper">
                                                <div className="uiSelect__search-list">
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">All</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Today</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Yesterday</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last week</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last month</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last quater</p></div>
                                                    <div className="uiSelect__search-item"><p className="uiSelect__select-row">Last year</p></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    {filteredTeam.length === 0 ? (
                        <div className="documents__empty-table">
                            {/* empty table */}
                            <div className="empty-table">
                                <div className="empty-table__wrapper">
                                    <div className="empty-table__icon empty-table__icon--team">
                                        <div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-plus" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#0b53f4" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <circle cx="9" cy="7" r="4" />
                                                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                    <path d="M16 11h6m-3 -3v6" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="empty-table__header">Start adding team members!</div>
                                    <div className="empty-table__description">Add your first team members to your account</div>
                                    <div className="empty-table__button">
                                        <div onClick={() => {
                                            if (acessLevel?.length === 1 && acessLevel?.includes('FE')) {
                                                setInfoModals(true);
                                                return;
                                            }
                                            if (acessLevel?.length === 2 && acessLevel?.includes('FE', 'RE')) {
                                                setInfoModals(true);
                                                return;
                                            }
                                            setAddTeamMemberModalIsVisible(true);
                                        }} className="team__button">
                                            <button type="button" className="button_with_add_icon button button--primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="23" height="23" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="12" y1="5" x2="12" y2="19" />
                                                    <line x1="5" y1="12" x2="19" y2="12" />
                                                </svg>
                                                <p className="button__text">Add Team Member</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>) : (

                        // team members table
                        <div className="settings-layout">
                            <div className="settings-layout__content">
                                <div className="documents__wrapper">
                                    <div className="app_table__table">
                                        <div className="table documents__table">
                                            <div className="table__container">
                                                <div className="table__innerContainer">
                                                    <div className="table__row table__header">
                                                        <div className="table__column table__column--date app_table__column--medium">Name</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Email</div>
                                                        <div className="table__column table__column--date app_table__column--medium">Password</div>
                                                        <div className="table__column table__column--action">Action</div>
                                                    </div>

                                                    {loadingGetTeam && <Loader />}
                                                    {filteredTeam?.map((team) => (

                                                        <div className="table__row table__dataRow" key={team.team_id}>
                                                            <div className="table__column table__column--text app_table__column--medium">{titleCase(team.name)}</div>
                                                            <div className="table__column table__column--date app_table__column--medium">{team.email}</div>
                                                            <div className="table__column table__column--date app_table__column--medium">{asteriskPassword(team.password)}</div>
                                                            <div className="table__column table__column--action">
                                                                <div className="documents__optionsDropdown table_action_list">
                                                                    <button title="Delete" className="documents__optionsDropdownTrigger"

                                                                        onClick={async (e) => {
                                                                            seteditTeamModalIsVisible(true);
                                                                            setEditTeamId(team.team_id);
                                                                            setEditTeamName(team.name);
                                                                            setEditTeamEmail(team.email);
                                                                            setEditTeamPassword(team.password);
                                                                            setEditTeamWorkspace((team.workspace));
                                                                        }}

                                                                    >
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                                                                                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                                                                                <line x1="16" y1="5" x2="19" y2="8" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                    <button title="Delete" className="documents__optionsDropdownTrigger"
                                                                        onClick={async (e) => {
                                                                            if (window.confirm('Are you sure you want to delete this team member?')) {
                                                                                dispatch(
                                                                                    await deleteTeams(
                                                                                        token,
                                                                                        team.team_id
                                                                                    ));
                                                                            }
                                                                        }}>
                                                                        <div className="table_action_options">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ef2f2f" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                <line x1="4" y1="7" x2="20" y2="7" />
                                                                                <line x1="10" y1="11" x2="10" y2="17" />
                                                                                <line x1="14" y1="11" x2="14" y2="17" />
                                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                            </svg>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>

                {/* ADD TEAM MEMBER MODAL */}
                {AddTeamMemberModalIsVisible && (
                    <div className="app_modal">
                        <div className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div className="requisiteModal__header">
                                        <p className="requisiteModal__title">New Team Member</p>
                                        <div onClick={() => {
                                            setAddTeamMemberModalIsVisible(false);
                                        }} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <div className="form__field">
                                                            <label className="form__label">Full Name</label>
                                                            <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="E.g; John Franklin"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)}
                                                            /></div>
                                                        </div>
                                                        <div className="form__field">
                                                            <label className="form__label">Email Address</label>
                                                            <div className="form__input-wrapper"><input type="email" className="form__input" placeholder="E.g; john@example.com"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            /></div>
                                                        </div>
                                                        <div className="form__field">
                                                            <label className="form__label">Password</label>
                                                            <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="E.g; 1234567890"
                                                                value={password}
                                                                onChange={(e) => setPassword(e.target.value)}
                                                            /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer className="requisiteModal__footer">
                                    <button type="button" className="button button--primary" onClick={handleSubmit}><p className="button__text">Save Team Member</p></button>
                                </footer>
                            </div>
                        </div>
                    </div>
                )}


                {/* EDIT TEAM MEMBER MODAL */}
                {EditTeamMemberModalIsVisible && (
                    <div className="app_modal">
                        <div className="app_modal__dialog" tabindex="-1" role="dialog">
                            <div className="requisiteModal">
                                <div className="requisiteModal__inner">
                                    <div className="requisiteModal__header">
                                        <p className="requisiteModal__title">Edit Team Member</p>
                                        <div onClick={() => seteditTeamModalIsVisible(false)} className="requisiteModal__header-icon">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#627790" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1="18" y1="6" x2="6" y2="18" />
                                                    <line x1="6" y1="6" x2="18" y2="18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="requisiteModal__content">
                                        <div>
                                            <div className="settings__block">
                                                <div className="settings__form-group settings__block--small">
                                                    <div className="settings__field settings__form-group-item">
                                                        <div className="form__field">
                                                            <label className="form__label">Full Name</label>
                                                            <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="E.g; John Franklin"
                                                                value={titleCase(editTeamName)}
                                                                onChange={(e) => setEditTeamName(e.target.value)}
                                                            /></div>
                                                        </div>
                                                        <div className="form__field">
                                                            <label className="form__label">Email Address</label>
                                                            <div className="form__input-wrapper"><input type="email" className="form__input" placeholder="E.g; john@example.com"
                                                                value={editTeamEmail}
                                                                onChange={(e) => setEditTeamEmail(e.target.value)}
                                                            /></div>
                                                        </div>
                                                        <div className="form__field">
                                                            <label className="form__label">Password</label>
                                                            <div className="form__input-wrapper"><input type="text" className="form__input" placeholder="E.g; 1234567890"
                                                                value={editTeamPassword}
                                                                onChange={(e) => setEditTeamPassword(e.target.value)}
                                                            /></div>
                                                        </div>
                                                        <div className="form__field">
                                                            <label className="form__label">Workspace</label>
                                                            <div className="form__input-wrapper">
                                                                <select className="form__input" onChange={(e) => setEditTeamWorkspace(e.target.value)}>
                                                                    {getWorkspaceData?.map((workspace) => (
                                                                        <option value={workspace.name}>{titleCase(workspace.name)}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer className="requisiteModal__footer">
                                    <button type="button" className="button button--primary"
                                        onClick={async (e) => {
                                            dispatch(
                                                await editTeams(
                                                    {
                                                        token,
                                                        team_id: editTeamId,
                                                        name: editTeamName,
                                                        email: editTeamEmail,
                                                        password: editTeamPassword,
                                                        workspace: editTeamWorkspace,
                                                    }
                                                ));
                                        }}
                                    ><p className="button__text">Save Team Member</p></button>
                                </footer>
                            </div>
                        </div>
                    </div>
                )}

                {infoModals && (
                    <InfoModal onClose={() => setInfoModals(false)} />
                )}
            </div>
        </>
    );
};