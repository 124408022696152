export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAIL = 'VERIFY_FAIL'

export const RESEND_VERIFICATION_CODE_REQUEST = 'RESEND_VERIFICATION_CODE_REQUEST'
export const RESEND_VERIFICATION_CODE_SUCCESS = 'RESEND_VERIFICATION_CODE_SUCCESS'
export const RESEND_VERIFICATION_CODE_FAIL = 'RESEND_VERIFICATION_CODE_FAIL'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'



