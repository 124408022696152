import React from "react";
import "./index.css";
import ShareVideoBody from "../components/ShareVideoBody";



const ShareVideo = () => {
  return (
    <div className="main-layout">
          <div className="main-layout__content" style={{maxWidth: "100%", minHeight: "100%", padding: "0px", border: "none"}}>
            <ShareVideoBody />
          </div>
    </div>
  );
};

export default ShareVideo;