import React from "react";
import "./index.css";
import EmbedBody from "../components/EmbedBody";



const Embed = () => {
  return (
    <div className="main-layout">
          <div className="main-layout__content" style={{overflow: "hidden", maxWidth: "100%", minHeight: "100%", padding: "0px", border: "none"}}>
            <EmbedBody />
          </div>
    </div>
  );
};

export default Embed;