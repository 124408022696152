import React from "react";
import "./index.css";
import DashboardNavigation from "../components/DashboardNavigation";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardFooter from "../components/DashboardFooter";
import CreatePageBody from "../components/CreatePageBody";

const CreatePage = () => {
  return (
    <div className="main-layout">
          <DashboardNavigation />
          <div className="main-layout__content">
            <DashboardSidebar />
            <CreatePageBody />
          </div>
          <DashboardFooter />
    </div>
  );
};

export default CreatePage;
