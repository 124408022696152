import{
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  USER_RESET,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_RESET,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  UPLOAD_PHOTO_REQUEST,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAIL,
  
} from "./AccountTypes"

export const getUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return { ...state, loading: true };
    case GET_USER_SUCCESS:
      return { loading: false, user: action.payload, message: action.payload.message, success: true };
    case GET_USER_FAIL:
      return { loading: false, error: action.payload };
      case USER_RESET:
        return { user: {} };
    default:
      return state;
  }
};

export const editUserReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_USER_REQUEST:
      return { loading: true };
    case EDIT_USER_SUCCESS:
      return { loading: false, success: true, editUserDetails: action.payload, message:action.payload.message };
    case EDIT_USER_FAIL:
      return { loading: false, error: action.payload };
      case EDIT_USER_RESET:
      return {};
    default:
      return state;
  }
};

export const changePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return { loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { loading: false, success: true, resetPasswordData:action.payload, message: action.payload.message };
    case CHANGE_PASSWORD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const uploadPhotoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_PHOTO_REQUEST:
      return { loading: true };
    case UPLOAD_PHOTO_SUCCESS:
      return { loading: false, success: true, uploadPhotoData:action.payload, message: action.payload.message };
    case UPLOAD_PHOTO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}