import React from 'react'
import "./index.css"
import HeaderLanding from "./HeaderLanding"
import FooterLanding from './FooterLanding'
import 'animate.css';
import { Link } from 'react-router-dom';

const LandingPageBody = () => {
  return (
    <>

      <div className="main_landing_page_div">
        <div className="landing_page_top">
          <HeaderLanding />

          <div className="landing_page_top_body">
            <div className="landing_page_top_body_left animate__backInLeft">
              <h1>Go <mark className="circle_mark">beyond</mark> words &amp; images</h1>
              <p>Attach a human angle to your mails; convey your passion, humanity, and offerings in videos. You’re only human after all, let them see it.</p>

              <div className="get_started_btn">
                <Link to='/signup'>
                  <button>Get Started for Free</button>
                </Link>
              </div>
            </div>

            <div className="landing_page_top_body_right">
              <div className='vector_icon'>
                <svg width="57" height="54" viewBox="0 0 57 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M51.6854 1.63879L51.6935 1.63626C52.2218 1.47095 52.7868 1.45571 53.3237 1.59242C53.8605 1.72911 54.3458 2.01173 54.7252 2.40605L54.7252 2.40607L54.7321 2.41309C55.0787 2.76862 55.3195 3.20926 55.4305 3.68706C55.5413 4.16352 55.5193 4.6607 55.3667 5.12607C55.3663 5.1273 55.3659 5.12852 55.3655 5.12974L40.0782 50.5616L40.0782 50.5616L40.0752 50.5706C39.8921 51.1263 39.5357 51.6134 39.0537 51.9608L39.9307 53.1777L39.0536 51.9608C38.572 52.3079 37.9902 52.4971 37.391 52.4994H37.2799H37.268L37.256 52.4996C36.662 52.5091 36.0806 52.3381 35.5901 52.0115C35.1005 51.6855 34.7259 51.2207 34.5139 50.6815C34.5136 50.6809 34.5134 50.6802 34.5131 50.6795L26.4908 30.0791L26.4773 30.0443L26.462 30.0102C26.346 29.7511 26.1796 29.5184 25.9734 29.3251L25.9734 29.3251C25.7673 29.1318 25.5253 28.9815 25.2618 28.8824L25.234 28.8719L25.2057 28.8626L3.40572 21.6325L3.40031 21.6307C2.82996 21.4439 2.3387 21.0809 1.99767 20.5986C1.65681 20.1165 1.4835 19.5404 1.50124 18.9557C1.51897 18.3711 1.72693 17.8059 2.09677 17.3444C2.46682 16.8827 2.97971 16.5492 3.5607 16.3966L3.59019 16.3888L3.61934 16.3799L51.6854 1.63879Z" fill="#E69D4C" stroke="black" stroke-width="3" />
                </svg>
              </div>
              <svg width="100%" height="auto" viewBox="0 0 795 610" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="100%" height="795" fill="#00123A" />
              </svg>
            </div>
          </div>
        </div>

        <div className='landing_page_body'>
          <div className='landing_page_body_top'>
            <div className='second_vector_icon'>
              <svg width="100" height="60" viewBox="0 0 100 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M72.1015 57.0805C81.0298 57.2976 90.1538 56.8633 98.6907 59.9427C99.1996 60.1243 99.7593 59.8598 99.9432 59.3466C100.123 58.8334 99.861 58.2688 99.3522 58.0833C90.6157 54.9328 81.2842 55.3276 72.1485 55.1065C71.6083 55.0907 71.1582 55.525 71.1465 56.0698C71.1308 56.6146 71.5614 57.0647 72.1015 57.0805Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M71.2991 44.006C74.0821 41.4233 77.1195 39.4884 80.7911 38.5295C81.3156 38.3929 81.6287 37.854 81.4956 37.3265C81.3586 36.7991 80.8224 36.4821 80.3018 36.6187C76.3093 37.6609 73.0018 39.7462 69.9761 42.5528C69.5769 42.9216 69.5494 43.5465 69.9174 43.9476C70.2814 44.3484 70.9038 44.3748 71.2991 44.006Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.557 15.8086C64.833 11.3968 67.3459 7.94546 71.1975 5.45312C71.6515 5.159 71.7846 4.54787 71.4911 4.08951C71.2014 3.63115 70.5947 3.4977 70.1406 3.79182C65.8781 6.55144 63.0912 10.3707 61.6781 15.2555C61.5255 15.7782 61.8269 16.327 62.3436 16.4794C62.8642 16.6321 63.4082 16.3313 63.557 15.8086Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5808 9.82115C18.8214 10.7225 18.1403 11.6937 17.561 12.7276C17.3027 13.188 16.8486 14.1663 16.7782 15.0052C16.6999 15.899 17.0013 16.6531 17.7763 17.0503C18.8057 17.5769 19.6277 17.522 20.2618 17.2034C20.8724 16.8975 21.35 16.2962 21.6513 15.49C22.2267 13.9491 22.1406 11.5942 21.8392 10.2503C22.6495 9.40702 23.538 8.64229 24.4891 7.96245C31.5739 2.8992 41.9661 4.76619 49.9628 12.3368C52.7615 14.9874 54.108 20.554 54.8086 26.5601C55.9007 35.9116 55.2783 46.3563 55.1922 49.4163C55.1726 50.172 55.2431 50.6205 55.2822 50.7101C55.4662 51.1507 55.7911 51.2652 56.0181 51.3086C56.3469 51.3678 56.6091 51.3046 56.8205 51.1941C57.1062 51.0425 57.3411 50.7756 57.4507 50.3638C57.5094 50.1364 57.525 49.8198 57.5172 49.4823C57.5094 49.2482 57.4624 48.9923 57.5094 48.8245C57.8147 47.7499 58.1826 46.7005 58.5427 45.6437C59.7444 42.0909 61.2984 38.7616 63.0245 35.4363C68.5083 24.87 73.812 13.7055 82.803 5.65527C83.2061 5.29403 83.2453 4.66947 82.8852 4.26204C82.529 3.85461 81.9105 3.81749 81.5035 4.17913C72.3325 12.3885 66.8839 23.7452 61.2905 34.5207C59.8227 37.3494 58.4762 40.1821 57.3567 43.145C57.4702 37.9037 57.4272 30.6486 56.476 24.2292C55.6305 18.5201 54.0218 13.4746 51.3014 10.8978C42.5415 2.60429 31.1159 0.805217 23.3579 6.35209C22.5281 6.94586 21.7375 7.59886 21.0016 8.30712C16.9426 0.914586 8.25304 -2.04837 0.573367 1.48741C0.080177 1.71363 -0.1351 2.29951 0.0880092 2.79498C0.315033 3.29045 0.894314 3.50917 1.3875 3.28296C8.30782 0.0969601 16.1558 2.94026 19.5808 9.82115ZM20.1483 12.2961C19.8313 12.7478 19.5377 13.216 19.2637 13.7C19.1189 13.9598 18.8683 14.4592 18.7627 14.948C18.7392 15.0625 18.7353 15.2417 18.7353 15.3246C19.0054 15.4588 19.2167 15.5236 19.3889 15.4363C19.6042 15.3289 19.7138 15.0763 19.8195 14.794C20.0857 14.0857 20.1718 13.1469 20.1483 12.2961Z" fill="black" />
              </svg>
            </div>
            <h4>How <mark className="circle_mark" style={{ color: "#0B53F4" }}>Testimonio</mark> works </h4>
            <p>Seamlessly record your screen, cam, or both to engage leads, add a human experience to your brand messages</p>
            <div className='third_vector_icon'>
              <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.4355 18.5316C35.1932 23.5712 35.2745 28.4244 35.1257 33.5362C35.0967 34.5345 35.8827 35.3645 36.8803 35.3945C37.8785 35.4246 38.7116 34.6367 38.7411 33.6384C38.8822 28.7913 38.7682 24.1786 39.9472 19.3916C40.1859 18.4233 39.5922 17.4431 38.6229 17.2085C37.6543 16.968 36.6736 17.5633 36.4355 18.5316Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.881 8.76497C17.453 16.4507 9.51211 26.3375 3.96179 36.3206C3.47777 37.1926 3.79303 38.2932 4.66585 38.7743C5.53866 39.2554 6.64051 38.9426 7.12454 38.0706C12.4916 28.4183 20.1715 18.8562 28.321 11.4291C29.0576 10.7555 29.1088 9.61289 28.4355 8.8792C27.7622 8.14551 26.6176 8.09142 25.881 8.76497Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.746 0.369595C16.0377 -0.899335 8.09262 1.26564 1.0836 4.32671C0.169197 4.72964 -0.247933 5.79406 0.151704 6.70216C0.551944 7.61627 1.61947 8.03126 2.53387 7.63434C8.91722 4.8439 16.1366 2.7751 23.1571 3.92977C24.142 4.09214 25.0733 3.42462 25.2354 2.44436C25.3982 1.46409 24.7303 0.53197 23.746 0.369595Z" fill="black" />
              </svg>
            </div>
          </div>
          <div className='landing_page_body_body'>
            <div className='landing_page_body_body_left'>
              <div className='forth_vector'>
                <svg width="93" height="88" viewBox="0 0 93 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M56.4253 14.2426C56.9269 13.6587 57.8726 13.8368 58.1274 14.5632L76.7626 67.6808C77.0174 68.4072 76.3904 69.1371 75.6339 68.9946L20.3151 58.5743C19.5586 58.4318 19.24 57.5238 19.7417 56.94L56.4253 14.2426Z" fill="#C1BADB" />
                  <path d="M12.318 50.9624L46.7441 10.8926L64.2325 60.7414L12.318 50.9624Z" stroke="#001648" stroke-width="4" />
                </svg>
              </div>
              <div className='smiling_girl_img'>
                <img src='/images/smiling_man.svg' alt="Send Better" />
              </div>
              <div className='fifth_vector'>
                <svg width="72" height="75" viewBox="0 0 72 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M65.0807 9.93895L7.2847 27.7039C6.21408 27.9858 5.26384 28.6031 4.57539 29.4639C3.88695 30.3248 3.49709 31.3832 3.46385 32.4815C3.43061 33.5799 3.75576 34.6595 4.39091 35.5598C5.02606 36.4601 5.93725 37.1328 6.98889 37.478L33.2019 46.1911C33.2974 46.2272 33.3848 46.2816 33.459 46.3514C33.5332 46.4211 33.5928 46.5047 33.6342 46.5974L43.282 71.4277C43.6724 72.4252 44.3615 73.2802 45.2565 73.8774C46.1515 74.4747 47.2095 74.7857 48.288 74.7685H48.4245C49.5212 74.7651 50.5888 74.4185 51.4753 73.7782C52.3619 73.1378 53.0222 72.2363 53.3622 71.202L71.7477 16.44C72.0359 15.5632 72.0781 14.6251 71.8696 13.7263C71.6612 12.8276 71.21 12.0021 70.5645 11.3385C69.8741 10.6194 68.9941 10.107 68.0246 9.85954C67.0552 9.61212 66.0351 9.63964 65.0807 9.93895Z" fill="#CDCDCD" />
                  <path d="M62.2067 2.15842L62.2068 2.15845L62.2176 2.15506C62.8174 1.96694 63.459 1.9496 64.0685 2.10517C64.678 2.26071 65.2291 2.58234 65.6602 3.03135L65.6693 3.04073C66.0615 3.44391 66.3341 3.94377 66.4598 4.48595C66.5852 5.02643 66.5603 5.59041 66.3878 6.1183C66.3872 6.11989 66.3867 6.12148 66.3862 6.12308L48.0047 60.8732L48.0046 60.8731L48.0007 60.8851C47.7931 61.5167 47.3889 62.0701 46.8427 62.4646C46.2972 62.8587 45.6384 63.0735 44.96 63.0762H44.8265H44.8105L44.7946 63.0765C44.121 63.0872 43.4615 62.8929 42.9052 62.5216C42.3497 62.1509 41.9244 61.6222 41.6837 61.0086C41.6835 61.0079 41.6832 61.0072 41.6829 61.0065L32.0369 36.1808L32.0189 36.1345L31.9986 36.0891C31.8487 35.7538 31.6339 35.4524 31.3672 35.2017L29.9975 36.6591L31.3672 35.2017C31.1005 34.9511 30.7873 34.7562 30.4461 34.6275L30.4089 34.6135L30.3712 34.601L4.15822 25.8878L4.15099 25.8854C3.50575 25.6737 2.94972 25.2619 2.5636 24.7146C2.17766 24.1676 1.98131 23.5136 2.0014 22.8498C2.02149 22.186 2.25707 21.5444 2.6758 21.0208C3.09474 20.497 3.67519 20.1188 4.33245 19.9457L4.37184 19.9353L4.41078 19.9233L62.2067 2.15842Z" stroke="#001648" stroke-width="4" />
                </svg>
              </div>
            </div>
            <div className='landing_page_body_body_right'>
              <div className='text_contents_right'>
                <div className='send_personalize'>
                  <h5>Send personalized video email to your customers.</h5>
                </div>
                <div className='copy_and_paste'>
                  <p>Copy and paste videos directly into gmail, outlook, or your automation platform.</p>
                </div>
                <div className='item_listings'>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Add/Create video through camera, screen, or both upload videos</p>
                  </div>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Get to know your number of viewer and likes</p>
                  </div>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Create Video through chromse extension</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='landing_page_body_body reverse_order'>
            <div className='landing_page_body_body_left2'>
              <div className='text_contents_left'>
                <div className='send_personalize'>
                  <h5>Collect Video Feedbacks from your customers</h5>
                </div>
                <div className='copy_and_paste'>
                  <p>Collect video, audio or text feedback from your loyal customers for your testimonial and reviews or more purposes.</p>
                </div>
                <div className='item_listings'>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Receive video, audio and text feedback from customers</p>
                  </div>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Preview video sent by customers</p>
                  </div>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Send link to customers through different platforms</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='landing_page_body_body_right2'>
              <div className='fort_vector'>
                <svg width="80" height="68" viewBox="0 0 80 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="46" cy="34" r="34" fill="#FFB9BB" />
                  <circle cx="34" cy="34" r="32" stroke="#001648" stroke-width="4" />
                </svg>
              </div>
              <div className='skeleton_image'>
                <img src='/images/smiling_girl.svg' alt="" />
              </div>
              <div className='fifth_vector'>
                <svg width="69" height="72" viewBox="0 0 69 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M62.2016 9.4992L6.96242 26.4782C5.93916 26.7477 5.03096 27.3377 4.37297 28.1604C3.71497 28.9832 3.34237 29.9947 3.31059 31.0445C3.27882 32.0943 3.58959 33.1262 4.19664 33.9866C4.80369 34.8471 5.67458 35.4901 6.6797 35.8199L31.733 44.1476C31.8244 44.1821 31.9079 44.2341 31.9788 44.3008C32.0497 44.3674 32.1067 44.4474 32.1462 44.536L41.3673 68.2678C41.7404 69.2212 42.399 70.0383 43.2544 70.6092C44.1098 71.18 45.121 71.4772 46.1518 71.4608H46.2823C47.3304 71.4575 48.3508 71.1263 49.1981 70.5143C50.0455 69.9022 50.6765 69.0406 51.0015 68.052L68.5736 15.7126C68.8491 14.8746 68.8894 13.978 68.6902 13.119C68.491 12.2601 68.0597 11.4711 67.4428 10.8368C66.7829 10.1495 65.9419 9.65978 65.0153 9.42331C64.0887 9.18684 63.1138 9.21313 62.2016 9.4992Z" fill="#93542D" />
                  <path d="M59.4808 2.1475L59.4808 2.14753L59.4916 2.14414C60.0493 1.96927 60.6457 1.95315 61.2123 2.09777C61.7789 2.24236 62.2911 2.54133 62.6917 2.9585L62.6916 2.95853L62.7007 2.96789C63.0644 3.34172 63.317 3.80507 63.4335 4.3075C63.5496 4.80811 63.5267 5.33047 63.367 5.81949C63.3664 5.82119 63.3659 5.82288 63.3653 5.82458L45.7971 58.1521L45.7971 58.152L45.7932 58.164C45.6006 58.7499 45.2256 59.2634 44.7187 59.6296C44.2123 59.9953 43.6008 60.1948 42.9708 60.1974H42.8434H42.8274L42.8115 60.1976C42.1856 60.2076 41.573 60.027 41.0562 59.6822C40.5403 59.3378 40.1455 58.847 39.9221 58.2775C39.9219 58.2768 39.9216 58.2761 39.9213 58.2754L30.7021 34.5482L30.6841 34.5019L30.6638 34.4565C30.5158 34.1253 30.3035 33.8275 30.0401 33.58C29.7768 33.3325 29.4674 33.14 29.1304 33.0129L29.0932 32.9988L29.0555 32.9863L4.00218 24.6586L3.99495 24.6562C3.39622 24.4597 2.88051 24.0777 2.52248 23.5703C2.16464 23.063 1.98268 22.4568 2.0013 21.8416C2.01992 21.2264 2.23825 20.6317 2.62653 20.1462C3.01501 19.6604 3.55342 19.3095 4.16332 19.1489L4.20271 19.1385L4.24165 19.1265L59.4808 2.1475Z" stroke="#001648" stroke-width="4" />
                </svg>
              </div>
            </div>
          </div>

          <div className='landing_page_body_body'>
            <div className='landing_page_body_body_left'>
              <div className='fort_vector'>
                <svg width="106" height="93" viewBox="0 0 106 93" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M59.5 0L86.1332 19.8668L106 46.5L86.1332 73.1332L59.5 93L32.8668 73.1332L13 46.5L32.8668 19.8668L59.5 0Z" fill="#FFCA8E" />
                  <path d="M21.2959 21.2959L46.5 2.49514L71.7041 21.2959L90.5049 46.5L71.7041 71.7041L46.5 90.5049L21.2959 71.7041L2.49514 46.5L21.2959 21.2959Z" stroke="#001648" stroke-width="4" />
                </svg>
              </div>
              <div className='smiling_girl_img'>
                <img src='/images/big_teeth.svg' alt="" />
              </div>
              <div className='fifth_vector'>
                <svg width="69" height="72" viewBox="0 0 69 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M62.2016 9.4992L6.96243 26.4782C5.93918 26.7477 5.03097 27.3377 4.37298 28.1604C3.71499 28.9832 3.34238 29.9947 3.31061 31.0445C3.27884 32.0943 3.5896 33.1262 4.19666 33.9866C4.80371 34.8471 5.6746 35.4901 6.67971 35.8199L31.7331 44.1476C31.8244 44.1821 31.9079 44.2341 31.9788 44.3008C32.0498 44.3674 32.1067 44.4474 32.1463 44.536L41.3673 68.2678C41.7404 69.2212 42.399 70.0383 43.2544 70.6092C44.1098 71.18 45.121 71.4772 46.1518 71.4608H46.2823C47.3304 71.4575 48.3508 71.1263 49.1981 70.5143C50.0455 69.9022 50.6766 69.0406 51.0015 68.052L68.5737 15.7126C68.8492 14.8746 68.8895 13.978 68.6902 13.119C68.491 12.2601 68.0597 11.4711 67.4428 10.8368C66.7829 10.1495 65.9419 9.65978 65.0153 9.42331C64.0887 9.18684 63.1138 9.21313 62.2016 9.4992Z" fill="#EECB29" />
                  <path d="M59.4808 2.1475L59.4808 2.14753L59.4916 2.14414C60.0493 1.96927 60.6457 1.95315 61.2123 2.09777C61.7789 2.24236 62.2911 2.54133 62.6917 2.9585L62.6916 2.95853L62.7007 2.96789C63.0644 3.34172 63.317 3.80507 63.4335 4.3075C63.5496 4.80811 63.5267 5.33047 63.367 5.81949C63.3664 5.82119 63.3659 5.82288 63.3653 5.82458L45.7971 58.1521L45.7971 58.152L45.7932 58.164C45.6006 58.7499 45.2256 59.2634 44.7187 59.6296C44.2123 59.9953 43.6008 60.1948 42.9708 60.1974H42.8434H42.8274L42.8115 60.1976C42.1856 60.2076 41.573 60.027 41.0562 59.6822C40.5403 59.3378 40.1455 58.847 39.9221 58.2775C39.9219 58.2768 39.9216 58.2761 39.9213 58.2754L30.7021 34.5482L30.6841 34.5019L30.6638 34.4565C30.5158 34.1253 30.3035 33.8275 30.0401 33.58C29.7768 33.3325 29.4674 33.14 29.1304 33.0129L29.0932 32.9988L29.0555 32.9863L4.00218 24.6586L3.99495 24.6562C3.39622 24.4597 2.88051 24.0777 2.52248 23.5703C2.16464 23.063 1.98268 22.4568 2.0013 21.8416C2.01992 21.2264 2.23825 20.6317 2.62653 20.1462C3.01501 19.6604 3.55342 19.3095 4.16332 19.1489L4.20271 19.1385L4.24165 19.1265L59.4808 2.1475Z" stroke="#001648" stroke-width="4" />
                </svg>
              </div>
            </div>
            <div className='landing_page_body_body_right'>
              <div className='text_contents_right'>
                <div className='send_personalize'>
                  <h5>Showcase your product to your customers.</h5>
                </div>
                <div className='copy_and_paste'>
                  <p>Let your customers see your products and interact.</p>
                </div>
                <div className='item_listings'>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Receive video, audio and text feedback from customers</p>
                  </div>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Preview video sent by customers</p>
                  </div>
                  <div className='single_item'>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.0162 0.0921429L1.42804 6.72775C1.02814 6.83306 0.673205 7.06364 0.416053 7.38518C0.158902 7.70673 0.0132819 8.10205 0.000865541 8.51232C-0.0115508 8.92258 0.1099 9.32586 0.347143 9.66213C0.584386 9.9984 0.92474 10.2497 1.31755 10.3786L11.1087 13.6332C11.1444 13.6466 11.177 13.667 11.2048 13.693C11.2325 13.7191 11.2547 13.7503 11.2702 13.7849L14.8739 23.0596C15.0197 23.4322 15.2771 23.7516 15.6114 23.9747C15.9457 24.1978 16.3409 24.3139 16.7437 24.3075H16.7947C17.2043 24.3062 17.6031 24.1768 17.9343 23.9376C18.2654 23.6984 18.5121 23.3616 18.6391 22.9753L25.5065 2.52042C25.6141 2.19292 25.6299 1.84253 25.552 1.50682C25.4742 1.17112 25.3056 0.862783 25.0645 0.614898C24.8066 0.346298 24.4779 0.1549 24.1158 0.0624833C23.7537 -0.0299332 23.3727 -0.0196569 23.0162 0.0921429Z" fill="black" />
                    </svg>
                    <p>Send link to customers through different platforms</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='why_sendBetter'>
          <div className='why_sendBetter_head'>
            <h3>Why <mark className='circle_mark'>Testimonio?</mark></h3>
            <p>We came with the magical elements/features to help you show rather than tell. </p>
          </div>
          <div className='why_sendBetter_body'>
            <div className='first_why_div'>
              <div className='first_why_div_item'>
                <img src='/images/video_emails.svg' alt='' />
                <div className='first_why_div_item_text'>
                  <h4>Video emails</h4>
                  <p>Send personalized video emails to customers and leads for a healthier relationship, and instill a sense of brand loyalty in them. </p>
                </div>

                <div className='sixth_vector'>
                  <svg width="78" height="58" viewBox="0 0 78 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="-3.9887" y="31.2027" width="79.28" height="33.8954" rx="16.9477" transform="rotate(-26.1703 -3.9887 31.2027)" fill="#0B53F4" stroke="black" stroke-width="3" />
                  </svg>
                </div>
              </div>
              <div className='first_why_div_item'>
                <img src='/images/feedbacks.svg' alt='' />
                <div className='first_why_div_item_text'>
                  <h4>Incorporate Feedbacks </h4>
                  <p>Get video, audio or text feedbacks from your loyal customers and use them in your marketing campaigns as testimonials. </p>
                </div>
              </div>
              <div className='first_why_div_item'>
                <img src='/images/tell.svg' alt='' />
                <div className='first_why_div_item_text'>
                  <h4>Show and Still Tell </h4>
                  <p>With videos, you can show your customers your awesome products and still talk about it, rather than just talking with words. It doesn’t get better than this.  </p>
                </div>
              </div>
              <div className='first_why_div_item'>
                <img src='/images/teammates.svg' alt='' />
                <div className='first_why_div_item_text'>
                  <h4>Invite Teammates</h4>
                  <p>Bring your team members to your workspace or account to allow them to request feedbacks, create videos, and much more.</p>
                </div>
              </div>
              <div className='first_why_div_item'>
                <img src='/images/ready.svg' alt='' />
                <div className='first_why_div_item_text'>
                  <h4>Import ready-made templates</h4>
                  <p>Use awesome videos templates pre-recorded by business owners like you, or designed videos by experienced designers and video producers.</p>
                </div>
              </div>
              <div className='first_why_div_item'>
                <img src='/images/camera.svg' alt='' />
                <div className='first_why_div_item_text'>
                  <h4>Screen + Camera Recording</h4>
                  <p>Effortlessly record your screen, camera, or both at once while creating demos, reviewing products, creating tutorials, checking in with customers, onboarding new customers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='getStartedBox'>
          <div className='getStartedFlex'>
            <div className='getStartedBoxLeft'>
              <p>Start connecting and engaging with your customers. It’s unlimited</p>

              <div className="get_started_btn">
                <Link to='/signup'>
                  <button>Get Started for Free</button>
                </Link>
              </div>
            </div>
            <div className='getStartedBoxRight'>
              <img src='/images/thumb.svg' alt='' />
            </div>
          </div>
        </div>

        <FooterLanding />
      </div>
    </>
  )
}

export default LandingPageBody