import {
    CREATE_FEEDBACK_REQUEST,
    CREATE_FEEDBACK_SUCCESS,
    CREATE_FEEDBACK_FAIL,
    GET_FEEDBACK_REQUEST,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_FAIL,
    DELETE_FEEDBACK_REQUEST,
    DELETE_FEEDBACK_SUCCESS,
    DELETE_FEEDBACK_FAIL,
} from './FeedBackType';

export const createFeedBackReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_FEEDBACK_REQUEST:
        return { loading: true };
      case CREATE_FEEDBACK_SUCCESS:
        return { loading: false, createFeedBackData: action.payload, success: true, message: action.payload.message };
      case CREATE_FEEDBACK_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const getFeedBackReducer = (state = { feedBackData: {} }, action) => {
    switch (action.type) {
      case GET_FEEDBACK_REQUEST:
        return { ...state, loading: true };
      case GET_FEEDBACK_SUCCESS:
        return { loading: false, feedBackData: action.payload, message: action.payload.message, success: true };
      case GET_FEEDBACK_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

export const deleteFeedBackReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_FEEDBACK_REQUEST:
        return { loading: true };
      case DELETE_FEEDBACK_SUCCESS:
        return { loading: false, deleteFeedData: action.payload, success: true, message: action.payload.message };
      case DELETE_FEEDBACK_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }