import React from "react";
import "./index.css";
import DashboardNavigation from "../components/DashboardNavigation";
import DashboardSidebar from "../components/DashboardSidebar";
import DashboardFooter from "../components/DashboardFooter";
import IntegrationBody from "../components/IntegrationBody";

const Integration = () => {
  return (
    <div className="main-layout">
          <DashboardNavigation />
          <div className="main-layout__content">
            <DashboardSidebar />
            <IntegrationBody />
          </div>
          <DashboardFooter />
    </div>
  );
};

export default Integration;
